import React, { useContext } from 'react';
import moment from 'moment';
import { Form, Field, Select, Checkbox } from 'formik-antd';
import Modal from '../../../components/Modal';
import SimpleInput from '../../../components/SimpleInput';
import InputMoney from '../../../components/InputMoney';
import TooltipAlterados from '../../../components/TooltipAlterados';
import DatePicker from '../../../components/DatePicker';
import SelectSearch from '../../../components/SearchSelect';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import TooltipCalculadora from './TooltipCalculadora';

import './styles.scss';
import { currencyFormat } from '../../../utils/numberFormatter';
import TooltipComponent from '../../../components/tooltip';
const { Option } = Select;

function OperacaoForm() {
  const {
    contexto,
    editableField,
    formik,
    validationField,
    planos,
    setPlanos,
    calcularPrestacao,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
    limiteCliente,
    valorAjusteParcelaProposta,
    validaCompanhiaCampoLimiteParcela,
  } = useContext(AnaliseMesaContext);

  const { values, setFieldValue, setFieldTouched } = formik;
  const handleSimularProposta = async (
    tabelaJurosId,
    tabelaJurosValoresId,
    valorContrato,
    vencimento,
  ) => {
    if (tabelaJurosId && tabelaJurosValoresId && valorContrato && vencimento) {
      const payload = {
        produtoId: values?.operacao.produtoId,
        convenioId: values?.operacao.convenioId,
        tabelaJurosId,
        valorContrato,
        vencimento,
        tabelaJurosValoresId,
      };
      const response = await calcularPrestacao(payload);
      if (response.data) {
        setFieldValue('operacao.operacao', response.data.valorOperacao);
        setFieldValue('operacao.prestacao', response.data.prestacao);
        setFieldValue('operacao.taxa', response.data.juros);
      }
      if (response.errors && response.errors.length > 0) {
        Modal(
          'Simulação de proposta',
          response.errors.join(' \n \n '),
          'error',
        );
      }
    }
  };
  const handleChangePlano = (prazo) => {
    setFieldValue('operacao.prazo', prazo);
    const planoSelecionado = planos.filter((plano) => plano.plano === prazo)[0]
      .id;
    setFieldValue('operacao.tabelaJurosValoresId', planoSelecionado);
    handleSimularProposta(
      values?.operacao.tabelaJurosId,
      planoSelecionado,
      values?.operacao.floatValorContratado,
      values?.operacao.vencimento,
    );
  };
  const handleChangeTabela = (tabelaId) => {
    const planos = contexto.tabelaJuros.filter(
      (tabela) => tabela.id === tabelaId,
    )[0].tabelaJurosValores;
    const planoSelecionado = planos.filter(
      (plano) => plano.plano === values?.operacao.prazo,
    )[0].id;
    setPlanos(planos);
    setFieldValue('operacao.tabelaJurosId', tabelaId);
    setFieldValue('operacao.tabelaJurosValoresId', planoSelecionado);
    if (planoSelecionado == null) {
      setFieldValue('operacao.prazo', null);
      setFieldValue('operacao.prestacao', null);
      setFieldValue('operacao.taxa', null);
    } else {
      handleSimularProposta(
        tabelaId,
        planoSelecionado,
        values?.operacao.floatValorContratado,
        values?.operacao.vencimento,
      );
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="operacao.produtoNome"
            label={
              <>
                <label>Produto</label>
                {campoChecar('operacao.produtoNome') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.produtoNome')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.produtoNome')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.produtoNome') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('operacao.produtoNome') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'operacao.produtoNome', 'Nome do Produto')
            }
            hasFeedback
          >
            <Field name="operacao.produtoNome">
              {({ field }) => (
                <SimpleInput
                  {...field}
                  disabled={!editableField('operacao.produtoNome')}
                  value={values?.operacao?.produtoNome}
                  onBlur={(e) =>
                    setFieldValue(
                      'endeoperacao.produtoNomedouro',
                      e.target.value,
                    )
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="operacao.vencimento"
            label={
              <>
                <label>1º vencimento</label>
                {campoChecar('operacao.vencimento') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.vencimento')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.vencimento')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.vencimento') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('operacao.vencimento') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'operacao.vencimento', '1º Vencimento')
            }
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="operacao.vencimento"
              placeholder="00/00/0000"
              disabledDate={disabledDate}
              disabled={!editableField('cliente.nascimento')}
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="operacao.tabelaJurosId"
            label={
              <>
                <label>Tabela</label>
                {campoChecar('operacao.tabelaJurosId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('operacao.tabelaJurosId')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.tabelaJurosId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.tabelaJurosId') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('operacao.tabelaJurosId') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'operacao.tabelaJurosId', 'Tabela')
            }
            hasFeedback
          >
            <SelectSearch
              name="operacao.tabelaJurosId"
              onChange={handleChangeTabela}
              disabled={!editableField('operacao.tabelaJurosId')}
              suffixIcon={<IconArrowSelect />}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={values?.operacao?.tabelaJurosId}
              fillDefault={editableField(`operacao.tabelaJurosId`)}
            >
              {contexto?.tabelaJuros ? (
                contexto?.tabelaJuros.map((tabela) => (
                  <Option key={tabela.id} value={tabela.id}>
                    {tabela.nome}
                  </Option>
                ))
              ) : (
                <Option value={values?.operacao?.tabelaJurosId}>
                  {values?.operacao?.tabelaJurosNome ||
                    values?.operacao?.tabelaJurosId}
                </Option>
              )}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="operacao.taxa"
            label={
              <>
                <label>Taxa de Juros (%)</label>
                {campoChecar('operacao.taxa') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.taxa')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.taxa')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.taxa') && <TooltipAlterados />}
              </>
            }
            className={highlightField('operacao.taxa') && `campo-alterado`}
          >
            <Field name="operacao.taxa">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix=""
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // value={`${values.operacao.taxa}%`}
                  style={{ textAlign: 'right' }}
                  disabled={!editableField('operacao.taxa')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="operacao.operacao"
            label={
              <>
                <label>Operação</label>
                {campoChecar('operacao.operacao') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.operacao')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.operacao')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.operacao') && <TooltipAlterados />}
              </>
            }
            className={highlightField('operacao.operacao') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'operacao.operacao', 'Valor da Operação')
            }
          >
            <Field name="operacao.operacao">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled={!editableField('operacao.operacao')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="operacao.valorContratado"
            label={
              <>
                <label>Valor Solicitado</label>
                {campoChecar('operacao.valorContratado') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('operacao.valorContratado')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.valorContratado')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.valorContratado') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('operacao.valorContratado') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'operacao.valorContratado',
                'Valor Contratado',
              )
            }
          >
            <Field name="operacao.valorContratado">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={13}
                  disabled={!editableField('operacao.valorContratado')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="operacao.valorLiberado"
            label={
              <>
                <label>Valor Liberado</label>
                {campoChecar('operacao.valorLiberado') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('operacao.valorLiberado')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.valorLiberado')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.valorLiberado') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('operacao.valorLiberado') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'operacao.valorLiberado', 'Valor Liberado')
            }
          >
            <Field name="operacao.valorLiberado">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={13}
                  disabled={!editableField('operacao.valorLiberado')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="operacao.prazo"
            label={
              <>
                <label>Prazo</label>
                {campoChecar('operacao.prazo') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.prazo')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.prazo')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.prazo') && <TooltipAlterados />}
              </>
            }
            className={highlightField('operacao.prazo') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'operacao.prazo', 'Prazo')
            }
            hasFeedback
          >
            <SelectSearch
              className="text-right select-prazo"
              name="operacao.prazo"
              onChange={handleChangePlano}
              disabled={!editableField('operacao.prazo')}
              suffixIcon={<IconArrowSelect />}
              getPopupContainer={(trigger) => trigger.parentNode}
              value={values?.operacao?.prazo}
              fillDefault={editableField(`operacao.prazo`)}
            >
              {planos &&
                planos.map((plano) => (
                  <Option
                    className="text-right"
                    key={plano.id}
                    value={plano.plano}
                  >
                    {plano.plano}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="operacao.prestacao"
            label={
              <>
                <label>Prestação</label>
                {campoChecar('operacao.prestacao') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('operacao.prestacao')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('operacao.prestacao')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('operacao.prestacao') && <TooltipAlterados />}
              </>
            }
            className={highlightField('operacao.prestacao') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'operacao.prestacao', 'Prestação')
            }
          >
            <Field name="operacao.prestacao">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input input-prestacao"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled={!editableField('operacao.prestacao')}
                  id="input-prestacao"
                />
              )}
            </Field>
          </Form.Item>
        </div>
        {values?.profissional?.orgaoDados.length > 0 && (
          <>
            <div className="col-lg-3">
              <Form.Item
                name="operacao.valorDebitoConcorrente"
                label={
                  <>
                    <label>Débitos concorrentes</label>
                    {campoChecar('operacao.valorDebitoConcorrente') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('operacao.valorDebitoConcorrente')
                              ?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.valorDebitoConcorrente')
                              ?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.valorDebitoConcorrente') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
              >
                <InputMoney
                  name="operacao.valorDebitoConcorrente"
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled
                  value={values?.operacao?.valorDebitoConcorrente}
                />
              </Form.Item>
            </div>

            <div className="col-lg-3">
              <Form.Item
                name="operacao.valorDebitoInterno"
                label={
                  <>
                    <label>Débitos internos</label>
                    {campoChecar('operacao.valorDebitoInterno') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('operacao.valorDebitoInterno')?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.valorDebitoInterno')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.valorDebitoInterno') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
              >
                <InputMoney
                  name="operacao.valorDebitoInterno"
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled
                  value={
                    values?.operacao?.valorDebitoInterno == null
                      ? 0
                      : values?.operacao?.valorDebitoInterno
                  }
                />
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                name="operacao.renda"
                label={
                  <>
                    <label>Renda</label>
                    {campoChecar('operacao.renda') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(campoChecar('operacao.renda')?.campoId)
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.renda')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.renda') && <TooltipAlterados />}
                  </>
                }
              >
                <InputMoney
                  name="operacao.renda"
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled
                  value={values?.profissional?.renda}
                />
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                name="operacao.comprometimento"
                label={
                  <>
                    <label>Comprometimento (%)</label>
                    {campoChecar('operacao.comprometimento') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('operacao.comprometimento')?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.comprometimento')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.comprometimento') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
                className={
                  highlightField('operacao.comprometimento') && `campo-alterado`
                }
              >
                <Field name="operacao.comprometimento">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix=""
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={values?.operacao?.comprometimento}
                      style={{ textAlign: 'right' }}
                      disabled={!editableField('operacao.comprometimento')}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                name="operacao.diaRecebimento"
                label={
                  <>
                    <label>Dia de recebimento</label>
                    {campoChecar('operacao.diaRecebimento') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('operacao.diaRecebimento')?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.diaRecebimento')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.diaRecebimento') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
                className={
                  highlightField('operacao.diaRecebimento') && `campo-alterado`
                }
              >
                <Select
                  name="operacao.diaRecebimento"
                  className="text-right"
                  disabled
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                  suffixIcon={<IconArrowSelect />}
                >
                  {contexto?.diaRecebimento?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      <span className="text-right col-12">{item.nome}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="simulate-calc d-flex align-items-center col-lg-auto">
              <TooltipCalculadora />
            </div>

            <div className="col-lg-3">
              <Form.Item
                name="operacao.limitePrestacao"
                validate={(value) =>
                  validationField(
                    value == null ? '' : value,
                    'operacao.limitePrestacao',
                    'prestação máxima',
                  )
                }
                className={
                  highlightField('operacao.limitePrestacao') && `campo-alterado`
                }
                label={
                  <>
                    <label>Prestação máxima</label>
                    {campoChecar('operacao.limitePrestacao') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('operacao.limitePrestacao')?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('operacao.limitePrestacao')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('operacao.limitePrestacao') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
              >
                <InputMoney
                  name="operacao.limitePrestacao"
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  placeholder={'R$ 0,00'}
                  maxLength={12}
                  disabled
                  onBlur={() => setFieldTouched('operacao.limitePrestacao')}
                  value={values?.operacao?.limitePrestacao}
                  onValueChange={(value) =>
                    setFieldValue('operacao.prestacaoMaxima', value.floatValue)
                  }
                />
              </Form.Item>
            </div>
          </>
        )}

        {values?.operacao?.saldoDevedor && (
          <div className="col-lg-3">
            <Form.Item
              name="operacao.saldoDevedor"
              label={
                <>
                  <label>Saldo Devedor</label>
                  {campoChecar('operacao.saldoDevedor') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('operacao.saldoDevedor')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('operacao.saldoDevedor')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('operacao.saldoDevedor') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('operacao.saldoDevedor') && `campo-alterado`
              }
            >
              <Field name="operacao.saldoDevedor">
                {({ field }) => (
                  <InputMoney
                    {...field}
                    className="ant-input"
                    prefix="R$ "
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    maxLength={12}
                    disabled={!editableField('operacao.saldoDevedor')}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        )}
        <div className="col-lg-3">
          <Form.Item
            name="operacao.prestacao"
            label="Limite do Cliente"
          >
            <SimpleInput
              disabled
              value={currencyFormat(limiteCliente)}
              style={{
                textAlign: "right"
              }}
              onBlur={(e) =>
                setFieldValue(
                  'endeoperacao.produtoNomedouro',
                  e.target.value,
                )
              }
            />
          </Form.Item>
        </div>
        {validaCompanhiaCampoLimiteParcela &&
          <div className="col-lg-3">
            <Form.Item name="operacao.prestacao" label="Limite da Parcela">
              <SimpleInput
                disabled
                value={currencyFormat(valorAjusteParcelaProposta)}
                style={{
                  textAlign: 'right',
                }}
                className="input-limite-parcela"
              />
            </Form.Item>
          </div>
        }

        {
          values?.operacao?.produtoNome === "CP Auto" &&
          <div className="col-lg-3">
            <Form.Item
              name="dadosBemGarantia.ltv"
              label={
                <>
                  LTV (%)

                  <TooltipComponent
                    title="Índice de risco. Percentual sobre a cotação da garantia que será concedido como crédito na operação."
                    placement="top"
                    className="tooltip-info"
                    overlayClassName="tooltip-info"
                    color="#435361"
                  >
                    <BulletInfo className="bullet-info-icon" />
                  </TooltipComponent>
                </>
              }
            >
              <InputMoney
                className="ant-input"
                prefix=""
                decimalScale={2}
                fixedDecimalScale={true}
                value={values?.dadosBemGarantia?.ltv}
                style={{ textAlign: 'right' }}
                disabled
              />
            </Form.Item>
          </div>
        }
      </div>

      {
        values?.operacao?.produtoNome === "CP Auto" &&
        <div className='form-row'>
          <div className="col-lg-3">
            <Form.Item
              name="dadosBemGarantia.valorGarantia"
              label="Valor da Garantia"
            >
              <InputMoney
                value={values?.dadosBemGarantia?.valorGarantia}
                className="ant-input"
                prefix="R$ "
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                maxLength={12}
                style={{
                  textAlign: "right"
                }}
                disabled />
            </Form.Item>

          </div>
          <div className="col-lg-3">
            <Form.Item
              name="dadosBemGarantia.ltvMax"
              label={
                <>
                  LTV Máx. (%)
                  <TooltipComponent
                    title="Índice de avaliação de risco. % máximo permitido sobre a garantia, conforme Política."
                    placement="top"
                    className="tooltip-info"
                    overlayClassName="tooltip-info"
                    color="#435361"
                  >
                    <BulletInfo className="bullet-info-icon" />
                  </TooltipComponent>
                </>
              }
            >
              <SimpleInput
                disabled
                value={values?.dadosBemGarantia?.ltvMax}
                style={{
                  textAlign: "right"
                }}
              />
            </Form.Item>

          </div>
        </div>
      }
    </>
  );
}
export default OperacaoForm;
