import React, { createContext } from 'react';

import moment from 'moment';

import {
  calcularLimitesParcelas,
  calcularVencimentos,
  calcularPlanos,
} from '../services/calculadoraService';

import { currencyFormat } from '../utils/numberFormatter';

export const CalculadoraContext = createContext({});

const api = {
  calcularLimitesParcelas,
  calcularVencimentos,
  calcularPlanos,
};

function floor2(value) {
  return Math.floor(value * 100, 2);
}

function CalculadoraProvider({ children }) {
  const calcularVencimentos = async (
    produtoId,
    propostaId,
    convenioId,
    tabelaJurosId,
    diaRecebimentoId,
  ) => {
    let vencimentosPossiveis;

    const response = await api.calcularVencimentos({
      produtoId: parseInt(produtoId),
      propostaId: parseInt(propostaId),
      convenioId,
      rota: null,
      leitura: null,
      vencimento: null,
      tabelaJurosId,
      diaRecebimentoId,
    });

    if (response?.data?.length > 0) {
      vencimentosPossiveis = response?.data?.map((vencimento) => {
        return vencimento?.vencimento;
      });

      return vencimentosPossiveis;
    }
  };

  const calcularLimitesParcelas = async (
    renda,
    produtoId,
    propostaId,
    tabelaJurosId,
    vencimento,
    objetoLimitesProduto,
    parcelaContratoRefin,
    recalculo = null,
    rendaComprometida = 0,
    debitosConcorrentes,
    abateSomenteLimiteParcela,
    maximoParcelaCia
  ) => {
    let objetoLimitesParcela;
    let maiorLimiteRenda;
    let menorLimiteRenda;
    let maiorLimiteProduto;
    let menorLimiteProduto;
    let limites = [];
    let maiorValorLimiteParcela = 0;
    let rendaLimitesIguais = false;
    let maximoParcelaCrivo;
    let minimoParcelaCrivo;

    const response = await api.calcularLimitesParcelas({
      renda,
      produtoId: parseInt(produtoId),
      propostaId: parseInt(propostaId),
      tabelaJurosId,
      vencimento,
      recalculo,
    });

    objetoLimitesParcela = response.data;

    if (objetoLimitesProduto?.length > 0) {
      // Verifica se os limites de renda retornados pelo back-end são iguais
      objetoLimitesParcela?.forEach((item, index) => {
        if (
          item.limiteParcela === objetoLimitesParcela[index - 1]?.limiteParcela
        ) {
          rendaLimitesIguais = true;
        }
      });

      // Para cada range de limite de parcela retornado
      objetoLimitesParcela?.forEach((item) => {
        // Busca pelo valor de maximoParcelaCrivo para comparação com limites
        minimoParcelaCrivo = item?.minimoParcelaCrivo;
        if(maximoParcelaCia){
          maximoParcelaCrivo = maximoParcelaCia
        }
        else if (item?.maximoParcelaCrivo !== null) {
          maximoParcelaCrivo = item?.maximoParcelaCrivo;
        } else {
          maximoParcelaCrivo = null;
        }

        // Calcula os consoles
        menorLimiteRenda = menorLimiteRenda || item.limiteParcela;
        maiorLimiteRenda = item.limiteParcela;
        menorLimiteProduto =
          menorLimiteProduto ||
          objetoLimitesProduto
            ?.filter(
              (subitem) =>
                subitem.plano >= item.rangeInicial &&
                subitem.plano <= item.rangeFinal &&
                subitem.prestacao >= minimoParcelaCrivo,
            )
            .reduce((a, b) => Math.max(a, b.prestacao), 0);
        maiorLimiteProduto = objetoLimitesProduto
          ?.filter(
            (subitem) =>
              subitem.plano >= item.rangeInicial &&
              subitem.plano <= item.rangeFinal &&
              subitem.prestacao >= minimoParcelaCrivo,
          )
          .reduce((a, b) => Math.max(a, b.prestacao), 0);

        // IMPROVEMENT 8338
        // Inserção do valor de débitos concorrentes no cálculo de valor máximo de parcela
        if (debitosConcorrentes) {
          rendaComprometida = rendaComprometida + debitosConcorrentes;
        }

        // Busca o menor limite entre a parcela e as parcelas do produto para o mesmo range
        // IMPROVEMENT 6734
        // Inserir a renda comprometida no cálculo de Limite Parcela Valor Máximo Produto e
        // Limite Parcela Compromentimento de Renda
        // IMPROVEMENT 1253 - Para o produto débito em conta a renda comprometida não será abatida da maior parcela dentro do range retornado pelo crivo. Para esse controle foi criada a flag 'abateSomenteLimiteParcela' onde somente para o débito em conta é retornada true.

        item.limiteParcela = Math.min(
          item?.limiteParcela - rendaComprometida,
          objetoLimitesProduto
            ?.filter(
              (subitem) =>
                subitem.plano >= item.rangeInicial &&
                subitem.plano <= item.rangeFinal &&
                subitem.prestacao >= minimoParcelaCrivo,
            )
            .reduce((a, b) => Math.max(a, b.prestacao), 0) - (abateSomenteLimiteParcela ? 0 : rendaComprometida),
        );


        if (maximoParcelaCrivo !== null) {
          item.limiteParcela = Math.min(
            item?.limiteParcela,
            maximoParcelaCrivo,
          );
        }

        // IMPROVEMENT 6734
        // Se o limite máximo de parcela for menor que o limite mínimo de parcela (retornado pelo crivo)
        if (item.limiteParcela < minimoParcelaCrivo) {
          item.limiteParcela = 0;
          item.minimoParcelaCrivo = 0;
        }
      });

      // Verifica e "abate" a renda comprometida retornada do back-end (do Crivo) nos limites da parcela
      // IMPROVEMENT 4231
      objetoLimitesParcela?.forEach((item) => {
        // Verifica se a rendaComprometida é zero e se existe um valor de parcela de refin selecionada
        // logo calcula a margem baseada no valor da parcela selecionada
        // IMPROVEMENT 4694
        // eslint-disable-next-line
        if (rendaComprometida == 0 && parcelaContratoRefin) {
          item.limiteParcela = item.limiteParcela - parcelaContratoRefin;
        }

        // Executa a regra de cálculo de Refin de acordo com a margem
        // Se a margem for positiva ele some essa margem à parcela do refin selecionada,
        // caso contrário ele utiliza apenas o valor da parcela
        // IMPROVEMENT 3036
        if (parcelaContratoRefin) {
          item.limiteParcela =
            item.limiteParcela > 0
              ? item.limiteParcela + parcelaContratoRefin
              : parcelaContratoRefin;
        }
      });

      // Consolida os ranges caso o limite seja o mesmo e calcula o limite máximo de parcela
      limites = [];

      objetoLimitesParcela?.forEach((item) => {
        maiorValorLimiteParcela = Math.max(
          maiorValorLimiteParcela,
          item.limiteParcela,
        );

        if (
          limites.length === 0 ||
          (floor2(limites[limites.length - 1].limiteParcela) !==
            floor2(item.limiteParcela) &&
            !rendaLimitesIguais)
        ) {
          limites.push(item);
        } else {
          limites[limites.length - 1].rangeInicial = Math.min(
            limites[limites.length - 1].rangeInicial,
            item.rangeInicial,
          );
          limites[limites.length - 1].rangeFinal = Math.max(
            limites[limites.length - 1].rangeFinal,
            item.rangeFinal,
          );
        }
      });

      objetoLimitesParcela = limites;
    }

    console.clear();

    console.log('rendaComprometidaRetornadaCrivo', rendaComprometida);
    console.log('parcelaContratoRefin', parcelaContratoRefin);

    console.log('menorLimiteRenda', menorLimiteRenda);
    console.log('maiorLimiteRenda', maiorLimiteRenda);

    console.log('menorLimiteProduto', menorLimiteProduto);
    console.log('maiorLimiteProduto', maiorLimiteProduto);

    return {
      objetoLimitesParcela,
      maiorValorLimiteParcela,
    };
  };

  const calcularValorLimite = async (
    produtoId,
    propostaId,
    convenioId,
    tabelaJurosId,
    vencimento,
    valorContingenciaProduto,
    renda,
    objetoLimitesParcela,
    objetoLimitesProduto,
    saldoDevedorRefin,
    recalculo = null,
    minimo = false,
  ) => {
    let limiteValor = 0;
    const unresolved = objetoLimitesParcela?.map(async (limite) => {
      if (
        objetoLimitesProduto?.find(
          (item) =>
            item.plano >= limite.rangeInicial &&
            item.plano <= limite.rangeFinal &&
            floor2(item.prestacao) >= floor2(limite.limiteParcela),
        ) &&
        (minimo ? limite.minimoParcelaCrivo : limite.limiteParcela)
      ) {
        await calcularPlanos(
          {
            propostaId: parseInt(propostaId),
            produtoId: parseInt(produtoId),
            convenioId,
            tabelaJurosId,
            vencimento,
            valorPrestacao: minimo
              ? Math.round(limite.minimoParcelaCrivo * 100) / 100
              : Math.round(limite.limiteParcela * 100) / 100,
            renda,
            recalculo,
          },
          1,
        ).then((response) => {
          const valorCalculado = response
            ?.filter(
              (item) =>
                item.plano >= limite.rangeInicial &&
                item.plano <= limite.rangeFinal,
            )
            .reduce(
              (a, b) =>
                minimo
                  ? a < b.solicitado
                    ? a
                    : b.solicitado
                  : Math.max(a, b.solicitado),
              minimo ? undefined : limiteValor,
            );
          if (minimo) {
            // Registra o menor valor do menor range disponível do objetoLimitesParcela
            if (
              (valorCalculado < limiteValor && valorCalculado > 0) ||
              limiteValor === 0
            ) {
              limiteValor = valorCalculado;
            }
          } else {
            limiteValor = valorCalculado;
          }
        });
      }
    });
    await Promise.all(unresolved);

    limiteValor = Math.min(limiteValor, valorContingenciaProduto);

    if (saldoDevedorRefin) {
      limiteValor = Math.round((limiteValor - saldoDevedorRefin) * 100) / 100;
    }

    return limiteValor <= 0 ? 0 : limiteValor;
  };

  const calcularValoresLimitesPrestacao = async (
    produtoId,
    propostaId,
    convenioId,
    tabelaJurosId,
    vencimento,
    valorContingencia,
    renda,
    recalculo = null,
  ) => {
    // Recebe o valor de contingência do produto para retornar o limite de parcelas
    const response = await calcularPlanos(
      {
        propostaId: parseInt(propostaId),
        produtoId: parseInt(produtoId),
        convenioId,
        tabelaJurosId,
        vencimento,
        valorContrato: valorContingencia,
        renda,
        recalculo,
      },
      0,
    );

    return response;
  };

  const calcularParcelas = async (
    tabelaJuros,
    produtoId,
    convenioId,
    tabelaJurosId,
    recalculo = null,
    valorAjusteParcela = null
  ) => {
    
    const uniqueParcelas = [];
    let count = 0;
    let valores = []
    let parcelas = []
    if(tabelaJuros?.tabelaJurosValores?.length > 0 ){
      const unresolved = tabelaJuros?.tabelaJurosValores?.map(async (el) => {
        const response = await calcularPlanos(
          {
            produtoId,
            convenioId,
            tabelaJurosId,
            valorContrato: el.valor,
            vencimento: moment()
              .add(
                tabelaJuros?.carenciaMedia || tabelaJuros?.carenciaMinima,
                'day',
              )
              .startOf('day'),
            recalculo,
          },
          0,
        );

        count++;

        if (response?.length > 0) {
          response?.forEach((el) => {
            if (
              uniqueParcelas.indexOf({
                valor: el.prestacao,
                texto: currencyFormat(el.prestacao),
                solicitado: el.valor,
              }) < 0
            ) {
              if (!valorAjusteParcela) {
                uniqueParcelas.push({
                  valor: el.prestacao,
                  texto: currencyFormat(el.prestacao),
                  solicitado: el.valor,
                });
              } else if(el.prestacao <= valorAjusteParcela) {
                const existeValor = valores.find(item => item?.valor === el.valor)
                const existeParcelas = parcelas.find(item => item?.valor === el.prestacao)

                if(!existeValor) {
                  valores.push({
                    valor: el.valor,
                    texto: currencyFormat(el.valor),
                  })
                }
                if(!existeParcelas) {
                  parcelas.push({
                    valor: el.prestacao,
                    texto: currencyFormat(el.prestacao),
                    solicitado: el.valor,
                  })
                }

              }
            }
          });

          if(valorAjusteParcela){
            valores.sort((a, b) => (a.valor > b.valor ? -1 : 1));
            parcelas.sort((a, b) => (a.valor > b.valor ? -1 : 1));
          }

          if (count === tabelaJuros?.tabelaJurosValores?.length) {
            uniqueParcelas.sort((a, b) => (a.valor > b.valor ? -1 : 1));
          }
        }
      });
      await Promise.all(unresolved);
    }
      if(valorAjusteParcela){
        return {parcelas: parcelas, valores}
      }

    return uniqueParcelas;
  };

  const calcularValores = async (tabelaJuros, valorContingencia) => {
    const uniqueValores = tabelaJuros?.tabelaJurosValores?.map((el) => {
      if (el.valor <= valorContingencia && valorContingencia > 0) {
        return {
          valor: el.valor,
          texto: currencyFormat(el.valor),
        };
      } else {
        return {
          valor: el.valor,
          texto: currencyFormat(el.valor),
        };
      }
    });

    return uniqueValores;
  };

  const calcularPlanos = async (dados, tipoCalculo, saldoDevedor) => {
    // Recebe os dados da simulação e o tipo de cálculo (por valor solicitado ou prestação) a ser realizado, e retorna os planos possíveis
    let planosAtualizados;

    if (!dados?.vencimento?._isAMomentObject && dados?.vencimento?.indexOf('/'))
      dados.vencimento = dados.vencimento.split('/').reverse().join('-');

    const { data } = await api.calcularPlanos(dados, tipoCalculo);

    if (saldoDevedor) {
      if (tipoCalculo === 0) {
        planosAtualizados = data.map((plano) => ({
          ...plano,
          valor: Math.round((plano.valor - saldoDevedor) * 100) / 100,
        }));
      } else {
        planosAtualizados = data.map((plano) => ({
          ...plano,
          solicitado: Math.round((plano.solicitado - saldoDevedor) * 100) / 100,
        }));
      }
    }

    return planosAtualizados || data;
  };

  return (
    <CalculadoraContext.Provider
      value={{
        calcularVencimentos,
        calcularLimitesParcelas,
        calcularValoresLimitesPrestacao,
        calcularValorLimite,
        calcularParcelas,
        calcularValores,
        calcularPlanos,
      }}
    >
      {children}
    </CalculadoraContext.Provider>
  );
}

export default CalculadoraProvider;
