import React, { useContext, useEffect, useState } from 'react';

import { Tabs } from 'antd';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import WidgetRelatorio from '../../../components/WidgetRelatorio';
import Overlay from '../../../components/Overlay';

import { RelatorioFollowUpContext } from '../../../contexts/RelatorioFollowUpContext';

import { ReactComponent as ArrowLeft } from '../../../assets/images/icons/carousel-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/icons/carousel-arrow-right.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/images/icons/expand.svg';

import 'antd/dist/antd.css';

import './styles.scss';
import { isBoolean } from 'lodash';
import PaginationFollowUp from '../../../components/PaginationFollowUp';

function RelatorioSintetico({ isFullScreen, setIsFullScreen }) {
  const {
    followUp,
    getFollowUpCard,
    showOverlay,
    setShowOverlay,
    tempoRefresh,
  } = useContext(RelatorioFollowUpContext);

  const [activeTab, setActiveTab] = useState('0');
  const [cardProduto, setCardProduto] = useState([]);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);

  const PAGE_SIZE = 16;
  const TIME_TO_DISPLAY_TAB = tempoRefresh * 1000;

  const { TabPane } = Tabs;

  const tabs = followUp?.map(({ id, nome }, index) => (
    <TabPane tab={nome} key={index} tabBarExtraContent produtoGrupoId={id} />
  ));

  const totalCount = cardProduto.reduce(
    (total, card) => total + card.etapaDecisao.length,
    0,
  );

  const totalPages = Math.ceil(
    cardProduto.reduce(
      (total, card) => total + Math.ceil(card.etapaDecisao.length / PAGE_SIZE),
      0,
    ),
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const statusList = cardProduto
    .flatMap((etapa) => etapa.etapaDecisao)
    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

  const getFollowUpCardByTab = async (activeTab) => {
    setShowOverlay(true);
    if (tabs.length) {
      const produtoGrupoId = tabs?.[activeTab]?.props?.produtoGrupoId;
      await getFollowUpCard(produtoGrupoId).then((response) => {
        setCardProduto(response.followUp);
        setShowOverlay(false);
      });
    }
  };

  const handleTabChange = async (activeTab, isFullScreen) => {
    setActiveTab(activeTab);
    setCurrentPage(1);
    if (!isFullScreen) {
      getFollowUpCardByTab(activeTab);
    }
  };

  useEffect(() => {
    let pageInterval;

    if (isFullScreen && totalPages > 0) {
      const TIME_TO_DISPLAY_PAGE = TIME_TO_DISPLAY_TAB / totalPages;
      let activeTabLocal = activeTab;

      pageInterval = setInterval(() => {
        let currentPageLocal = 1;
        setCurrentPage((prevPage) => {
          currentPageLocal = prevPage + 1;
          return prevPage < totalPages ? currentPageLocal : 1;
        });

        if (currentPageLocal - 1 >= totalPages) {
          if (document.visibilityState === 'visible') {
            handleTabChange(activeTab, isFullScreen);
            let newActiveTab = '';
            if (Number(activeTabLocal) + 1 < tabs.length) {
              newActiveTab = String(Number(activeTabLocal) + 1);
            } else {
              newActiveTab = '0';
            }
            activeTabLocal = newActiveTab;
            setActiveTab(activeTabLocal);
            getFollowUpCardByTab(activeTabLocal);
          }
        }
      }, TIME_TO_DISPLAY_PAGE);
    }

    return () => clearInterval(pageInterval);
    // eslint-disable-next-line
  }, [isFullScreen, totalPages, followUp, activeTab]);

  useEffect(() => {
    handleTabChange(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followUp, isFullScreen]);

  const OperationsSlot = {
    left: (
      <ArrowLeft
        className="arrow-left"
        onClick={() => {
          let dec;
          dec = Number(activeTab) - 1;

          if (dec >= 0) {
            setActiveTab(String(dec));
            handleTabChange(String(dec));
          }
        }}
      />
    ),
    right: (
      <ArrowRight
        className="arrow-right"
        onClick={() => {
          let sum;
          sum = +activeTab + 1;

          if (sum < tabs.length) {
            setActiveTab(String(sum));
            handleTabChange(String(sum));
          }
        }}
      />
    ),
  };

  const fullScreenButton = () => (
    <div
      className="expand-icon"
      onClick={() => {
        if (!isFullScreen) {
          document.body.id = 'fullscreen';
        } else {
          document.body.id = '';
        }
        setIsFullScreen(!isFullScreen);
      }}
    >
      <ExpandIcon />
      {isFullScreen ? 'RECOLHER' : 'EXPANDIR'}
    </div>
  );

  return (
    <Overlay active={showOverlay}>
      <div className="analitico">
        <Tabs
          style={{ display: isFullScreen && isBoolean(isFullScreen) && 'none' }}
          className="tabs"
          tabBarStyle={{ fontSize: 30 }}
          tabBarExtraContent={OperationsSlot}
          centered
          activeKey={activeTab}
          onTabClick={setActiveTab}
          onChange={handleTabChange}
        >
          {tabs}
        </Tabs>
        {cardProduto.map(({ nomeProduto }, idx) => (
          <Card
            className={
              isFullScreen ? 'title-card fullscreen-card' : 'title-card'
            }
            title={<Title label={`Follow UP - ${nomeProduto}`} />}
            pagination={
              !isFullScreen && (
                <PaginationFollowUp
                  totalCount={totalCount}
                  pageSize={PAGE_SIZE}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )
            }
            rightBar={fullScreenButton()}
            centerBar={
              isFullScreen && (
                <Tabs
                  className="tabs tabs-center tabs-fullscreen"
                  tabBarExtraContent={OperationsSlot}
                  activeKey={activeTab}
                  onTabClick={setActiveTab}
                  onChange={handleTabChange}
                >
                  {tabs}
                </Tabs>
              )
            }
          >
            <div>
              <div className="row widgets-followup">
                {statusList.map((etapa, id) => (
                  <div className="card-relatorio" key={id}>
                    <WidgetRelatorio
                      date={etapa.dataMaisAntigo}
                      title={etapa.situacao}
                      color={etapa.corSituacao}
                      total={etapa.quantidadeProposta}
                      time={etapa.tempoEspera}
                      isFullScreen={isFullScreen}
                    />
                  </div>
                ))}
              </div>
            </div>
            {isFullScreen && totalPages > 1 && (
              <div className="fullscreen-pagination">
                <span>
                  {currentPage}/{totalPages}
                </span>
              </div>
            )}
          </Card>
        ))}
      </div>
    </Overlay>
  );
}

export default RelatorioSintetico;
