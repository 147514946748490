import React, { createContext, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import { listarOcupacoes } from '../services/ocupacaoService';

import {
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

import {
  inserirProposta,
  fetchPreAnaliseCpf,
  getPropostaPreAnalise,
  putPropostaPreAnalise,
  postPreAnalise,
} from '../services/propostaService';

import ModalAlerta from '../components/Modal';
import validationSchema from '../pages/PreAnalise/validationSchema';

const initialValues = {
  cpf: '',
  nome: '',
  nascimento: '',
  telefone: '',
  ocupacaoId: null,
  cep: '',
  ufId: null,
  cidadeId: null,
  bairro: null,
  logradouro: null,
  funcionarioConveniada: undefined,
  possuiVeiculoGarantia: undefined,
  convenioId: null
};

export const PreAnaliseContext = createContext({});

function PreAnaliseProvider({ children }) {
  const params = useParams();
  const history = useHistory();

  const propostaId = params?.propostaId;

  const [proposta, setProposta] = useState({});
  const [ocupacoes, setOcupacoes] = useState([]);
  const [cpfMessage, setCpfMessage] = useState([]);
  const [preAnaliseContexto, setPreAnaliseContexto] = useState();
  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState(null);
  const [modalPropostaAndamentoVisible, setmodalPropostaAndamentoVisible] =
    useState(false);
  const [cpfValido, setCpfValido] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loadingSalvarProposta, setLoadingSalvarProposta] = useState(false);
  const [flagNovaProposta, setFlagNovaProposta] = useState(false);

  const formik = useFormik({
    validationSchema,
    initialValues,
  });

  const redirecionarParaSelecaoOfertas = (propostaId) => {
    history.push(`/ofertas-produtos/${propostaId}`);
  };

  const redirecionarParaAcompanhamento = () => {
    history.push(`acompanhamento-proposta`);
  };

  const carregarProposta = useCallback(async (propostaId) => {
    const data = await getPropostaPreAnalise(propostaId);
    return data;
  }, []);

  const redirecionaAcompanhamentoProposta = () => {
    history.push('/acompanhamento-proposta');
  };

  const fetchOcupacoes = async () => {
    const response = await listarOcupacoes();
    setOcupacoes(response);
  };

  const fetchUfs = async () => {
    const response = await listarUfs();
    return response;
  };

  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    return response;
  };

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    return response;
  };

  const preAnaliseCpf = async (cpf) => {
    const response = await fetchPreAnaliseCpf(cpf);
    return response;
  };

  const solicitarProposta = async (proposta) => {
    const { data } = await inserirProposta(proposta);
    setProposta(data);
    return data;
  };

  const fetchPreAnalise = async (cidadeId, propostaId) => {
    const response = await postPreAnalise(cidadeId, propostaId);
    setPreAnaliseContexto(response);
    return response;
  };

  const limpaCampos = () => {
    formik.setFieldValue('bairro', null);
    formik.setFieldTouched('bairro', false);
    formik.setFieldValue('cep', '');
    formik.setFieldTouched('cep', false);
    formik.setFieldValue('cidadeId', null);
    formik.setFieldTouched('cidadeId', false);
    formik.setFieldValue('cpf', '');
    formik.setFieldTouched('cpf', false);
    formik.setFieldValue('logradouro', null);
    formik.setFieldTouched('logradouro', false);
    formik.setFieldValue('nascimento', '');
    formik.setFieldTouched('nascimento', false);
    formik.setFieldValue('nome', '');
    formik.setFieldTouched('nome', false);
    formik.setFieldValue('ocupacaoId', null);
    formik.setFieldTouched('ocupacaoId', false);
    formik.setFieldValue('telefone', '');
    formik.setFieldTouched('telefone', false);
    formik.setFieldValue('ufId', null);
    formik.setFieldTouched('ufId', false);
    formik.setFieldValue('convenioId', null);
    formik.setFieldTouched('convenioId', false);
  };

  const verificaPropostaAndamento = (data) => {
    if (data?.usuarioDiferente) {
      setCpfValido(false);
      setLoadingSalvarProposta(false);
      ModalAlerta('', data?.mensagem, 'error');
      limpaCampos();
    } else if (data?.mensagem) {
      ModalAlerta('', data.mensagem, 'warning', () => {
        if (data?.redirecionar) {
          redirecionarParaAcompanhamento();
        }
      });
      limpaCampos();
    }
  };

  const verificaPropostaSimultanea = (data) => {
    if (data?.mensagem) {
      ModalAlerta('', data.mensagem, 'warning', () => {
        if (data?.redirecionar) {
          redirecionarParaAcompanhamento();
        }
      });
      limpaCampos();
    }
  };

  formik.handleSubmit = async () => {
    const errors = await formik
      .validateForm()
      .then((errors) => formik.setTouched({ ...errors }, true));

    formik.setTouched(errors, true);

    if (!Object.keys(errors).length) {
      setDisableSubmit(true);

      let temp = Object.assign({}, formik.values);
      if (formik.values.cep) {
        temp.cep = formik.values.cep.replace(/\D/g, '');
      }

      let data;
      if (temp.cep !== '') {
        data = await fetchEndereco(temp.cep);
        const { logradouro, bairro, cidade, cidadeId, uf, ufId } = data.data;
        temp = { ...temp, logradouro, bairro, cidade, cidadeId, uf, ufId };
      } else {
        temp = {
          ...temp,
          logradouro: '',
          bairro: '',
        };
      }
      if (data?.data?.status || temp.cep === '') {
        setLoadingSalvarProposta(true);

        temp.cpf = temp.cpf.replace(/\D/g, '');

        if (flagNovaProposta) {
          temp.novaProposta = flagNovaProposta;
        }

        let tempData;

        if (propostaId) {
          tempData = await putPropostaPreAnalise(temp);
          if (tempData?.bloqueio) {
            ModalAlerta('', tempData?.bloqueio, 'warning', redirecionaAcompanhamentoProposta)
          }
        } else {
          tempData = await solicitarProposta(temp);
        }

        if (!propostaId && tempData?.propostaEmAndamento) {
          verificaPropostaAndamento(tempData?.propostaEmAndamento);
        } else if (!propostaId && tempData?.propostaSimultanea){
          verificaPropostaSimultanea(tempData?.propostaSimultanea)
        } else {
          setLoadingSalvarProposta(false);
          if (tempData?.respostaCrivo?.aprovado || tempData?.respostaCrivo?.liberaSelecaoOfertaServico) {
            redirecionarParaSelecaoOfertas(tempData?.respostaCrivo?.propostaId);
          }
        }
      } else {
        // CEP inválido ou não encontrado
        ModalAlerta(
          '',
          'CEP inválido ou não encontrado, favor informar o CEP correto ou prosseguir com o preenchimento manual',
          'error',
        );
      }
      setDisableSubmit(false);
    }
  };

  return (
    <PreAnaliseContext.Provider
      value={{
        formik,
        ocupacoes,
        preAnaliseContexto,
        preAnaliseCpf,
        solicitarProposta,
        verificaPropostaAndamento,
        verificaPropostaSimultanea,
        redirecionarParaAcompanhamento,
        modalPropostaAndamentoVisible,
        setmodalPropostaAndamentoVisible,
        disableSubmit,
        loadingSalvarProposta,
        setFlagNovaProposta,
        cpfValido,
        setCpfValido,
        proposta,
        produto,
        setProduto,
        fetchUfs,
        fetchCidades,
        fetchEndereco,
        fetchOcupacoes,
        fetchPreAnalise,
        cpfMessage,
        setCpfMessage,
        carregarProposta,
        loading,
        setLoading,
        propostaId,
        putPropostaPreAnalise,
      }}
    >
      {children}
    </PreAnaliseContext.Provider>
  );
}

export default PreAnaliseProvider;
