import React, { useContext } from 'react';
import Slider from 'react-slick';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import CardChartInfo from '../../../components/CardChartInfo';
import Button from '../../../components/Button';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const ArrowLeft = (props) => (
  <LeftOutlined
    className="slick-arrow"
    style={{
      position: 'absolute',
      top: '35%',
      left: '-12px',
      fontSize: '14px',
      color: 'rgba(103, 106, 108, 0.5)',
    }}
    onClick={props.onClick}
  />
);
const ArrowRight = (props) => (
  <RightOutlined
    className="slick-arrow"
    style={{
      position: 'absolute',
      top: '35%',
      right: '-12px',
      fontSize: '14px',
      color: 'rgba(103, 106, 108, 0.5)',
    }}
    onClick={props.onClick}
  />
);

function CrivoComponent() {
  const settingsCharts = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <ArrowRight /> ,
    prevArrow:<ArrowLeft />,
  };

  const { processamentoCrivo, proposta } = useContext(AnaliseMesaContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <div className="">
      <div className="itens-crivo">
        <ul>
          {processamentoCrivo?.processamentoCrivo?.map((item, i) => (
            <li key={i}>
              <div
                className="ellipse"
                style={{ background: item.processamentoCor }}
              ></div>
              {item.processamentoCriterio} | {item.processamentoRetorno}
            </li>
          ))}
        </ul>
      </div>
      {proposta?.id &&
        checarPermissao(
          'botao',
          'botao.analiseMesaCredito.verMais',
          'Visualizar',
        ) && (
          <div className="btn-ver-mais d-flex justify-content-start mb-3">
            <a
              href={`/processamento-crivo/${proposta?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="orange-outline">Ver Mais</Button>
            </a>
          </div>
        )}

        {processamentoCrivo?.processamentoCards?.length > 0 && 
          <div className="charts-info">
            <Slider {...settingsCharts}>
              {processamentoCrivo?.processamentoCards?.map((card, i) => (
                <div className="col-chart card-chat-info-proc-crivo" key={i}>
                  <CardChartInfo
                    title={card.cardTitulo}
                    subtitle={card?.cardRestricao}
                    value={card.cardScore}
                    date={card.cardDataHora}
                  />
                </div>
              ))}
            </Slider>
          </div>
        }
    </div>
  );
}

export default CrivoComponent;
