import React, { useContext, useEffect, useState } from 'react';

import { Field, useFormik, FormikProvider } from 'formik';
import { Form, Input, Switch, Select } from 'formik-antd';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon';

import {Modal  as ModalInfo} from 'antd';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

import { validationForm } from './validationForm';

import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import { blockNonNumericChars } from '../../../utils/masks';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { ReactComponent as SuccessOutlinedIcon } from '../../../assets/images/icons/confirm-outline.svg';

const { Option } = Select;

function TabelaForm() {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);

  const {
    formData,
    isEdit,
    showOverlay,
    showTable,
    tabelaJurosValores,
    salvarTabelaJuros,
    removerTabelaJuros,
    calcularValorParcela,
    resetForm,
    setShowTable,
    setShowDate,
    setCarenciaMedia,
    acesso,
    fetchContextoTabelaJuros,
    tiposTabelaJuros,
    produtosTabelaJuros,
    searchFirstPage,
    goToFirstPage,
    setProdutoId,
    produtoId,
    carenciaMedia,
    tipoTabelaJuros,
    setTipoTabelaJuros,
    setTabelaJurosValores,
    setShowOverlayTabelaJuros,
    resetTableForm,
  } = useContext(TabelaJurosContext);

  const handleSubmit = async (
    data,
    { resetForm, setFieldError, validateForm, setTouched },
  ) => {

    // eslint-disable-next-line
    if (controleCarencia() == 0) {
      if (isEdit) {
        if (verificaTabelaCarenciaMedia()) {
          if (data.carenciaMedia <= 0) {
            setFieldError('carenciaMedia', 'Carência é obrigatório');
            return;
          }
        } else {
          if (!data.carenciaMinima || !data.carenciaMaxima) {
            setFieldError('carenciaMinima', 'Carência é obrigatório');
            setFieldError('carenciaMaxima', 'Carência é obrigatório');
            return;
          } else if (data.carenciaMaxima < data.CarenciaMinima) {
            setFieldError(
              'carenciaMaxima',
              'Carência máxima deve ser maior que carência mínima',
            );
            setFieldError(
              'carenciaMinima',
              'Carência mínima deve ser menor que carência máxima',
            );
            return;
          }
        }
      } else {
        if (
          !data.carenciaMinima &&
          !data.carenciaMaxima &&
          !data.carenciaMedia
        ) {
          setFieldError('carenciaMinima', 'Carência é obrigatório');
          setFieldError('carenciaMaxima', 'Carência é obrigatório');
          setFieldError('carenciaMedia', 'Carência é obrigatório');
          return;
        }
      }
    }

    const errors = await validateForm().then((errors) =>
      setTouched({ ...errors }, true),
    );
    setTouched(errors, true);

    if (Object.keys(errors).length) return;

    data.carenciaMinima = !data.carenciaMinima ? null : data.carenciaMinima
    data.carenciaMaxima = !data.carenciaMaxima ? null : data.carenciaMaxima
    data.carenciaMedia = !data.carenciaMedia ? null : data.carenciaMedia

    const response = await salvarTabelaJuros(data);

    if (!response.success) {
      Modal(
        'Ocorreu 1 ou mais erros:',
        response.messages.toString().replace(/ *, */g, '\n\n'),
        'error',
      );
    } else {
      resetForm();
      Modal('Registro salvo com sucesso', '', 'success');
    }
    goToFirstPage();
    searchFirstPage();
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationForm,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { setFieldValue, values } = formik;

  const handleTipoTabela = (value) => {
    setFieldValue('carenciaMinima', 0, false);
    setFieldValue('carenciaMaxima', 0, false);
    setFieldValue('carenciaMedia', 0, false);
    setShowTable(value);
    setTipoTabelaJuros(value);
    handleChangeTabelaJurosValores(produtoId, carenciaMedia, value);
  };

  const handleProdutoTabela = (value) => {
    setFieldValue('carenciaMinima', 0, false);
    setFieldValue('carenciaMaxima', 0, false);
    setFieldValue('carenciaMedia', 0, false);
    setProdutoId(value);
    handleChangeTabelaJurosValores(value);
  };

  const handleTabelaCliente = (value) => {
    setShowDate(value);
  };

  const handleCarenciaMedia = (value) => {
    setFieldValue('carenciaMinima', 0, true);
    setFieldValue('carenciaMaxima', 0, true);
    setCarenciaMedia(value);
    handleChangeTabelaJurosValores(produtoId, value);
  };

  const deleteTable = async (id) => {
    setVisibleModalDelete(false)
    const response = await removerTabelaJuros(id);

    if (!response.success) {
      Modal(
        'Ocorreu 1 ou mais erros:',
        response.messages.toString().replace(/ *, */g, '\n\n'),
        'error',
      );
    } else {
      resetForm();
      setVisibleModalSuccess(true)
    }

  };

  async function handleChangeTabelaJurosValores(
    id = produtoId,
    carencia = carenciaMedia,
    tabelaTipo = tipoTabelaJuros,
  ) {
    // eslint-disable-next-line
    if (id && tabelaTipo == 1) {
      let tempTabelaJurosValores = [];
      let unresolved = tabelaJurosValores.map(async (el) => {
        setShowOverlayTabelaJuros(true);
        if (el.valor) {
          await calcularValorParcela({
            valor: el.valor,
            carencia: Number(carencia),
            plano: el.plano,
            juros: el.juros,
            produtoId: id,
          }).then((response) => {
            tempTabelaJurosValores.push({ ...el, prestacao: response });
          });
        } else {
          tempTabelaJurosValores.push(el);
        }
      });
      await Promise.all(unresolved).catch(() =>
        setShowOverlayTabelaJuros(false),
      );
      setTabelaJurosValores(
        tempTabelaJurosValores.sort((a, b) => {
          return a.plano - b.plano || a.valor - b.valor;
        }),
      );
      setShowOverlayTabelaJuros(false);
    }
  }

  useEffect(() => {
    fetchContextoTabelaJuros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function verificaTabelaCarenciaMedia() {
    return (
      formData.carenciaMedia > 0 &&
      !formData.carenciaMaxima &&
      !formData.carenciaMinima
    );
  }

  function controleCarencia() {
    if (!formik.values.produtoId) {
      return;
    }

    let indexProduto = produtosTabelaJuros.findIndex(
      (produto) => produto.id === formik.values.produtoId,
    );
    return produtosTabelaJuros[indexProduto]?.controleCarencia;
  }

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.carenciaMedia, values.carenciaMaxima, values.carenciaMinima]);

  useEffect(() => {
      formik.setFieldTouched("codigoCrivo")
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting, formik.values.tabelaCliente])

  return (
    <Overlay active={showOverlay}>
      <Card
        title={<Title label={`${isEdit ? 'Edição' : 'Inclusão'}  de Tabela`} />}
      >
        <div id="form-tabela-juros">
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              autoComplete="off"
              style={{ marginTop: -24 }}
            >
              <div className="form-row">
                <div className="col-lg-12">
                  <Form.Item name="ativo">
                    <span className="mr-2">Ativo</span>
                    <Switch
                      name="ativo"
                      id="ativo"
                      size="small"
                      disabled={!acesso}
                      onChange={(value) => {
                        setFieldValue('ativo', value);
                      }}
                      value={formik.values.ativo}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="form-row">
                <div className="col-lg-6">
                  <Form.Item name="id" label="Código">
                    <Input
                      name="id"
                      id="id"
                      disabled
                      onChange={formik.handleChange}
                      value={formik.values.id}
                    />
                  </Form.Item>
                </div>

                <div className="col-lg-6">
                  <Form.Item name="codigoCrivo" label="Código Crivo"
                    validate={(value) =>
                      (!value && formik.values.tabelaCliente) && "Campo obrigatório"
                    }
                  >
                    <Input
                      name="codigoCrivo"
                      id="codigoCrivo"
                      disabled={!acesso}
                      onChange={(event) => {
                        const regexCaracterEspecial = /[^a-zA-Z0-9\s]/g;
                        event.target.value = event?.target?.value?.replace(regexCaracterEspecial, '');
                        formik.handleChange(event)
                      }}
                      maxLength={50}
                      value={formik.values.codigoCrivo}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="form-row">
                <div className="col-lg-12">
                  <Form.Item name="nome" label="Nome">
                    <Input
                      name="nome"
                      id="nome"
                      disabled={!acesso}
                      onChange={formik.handleChange}
                      value={formik.values.nome}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="form-row">
                <div className="col-lg-6">
                  <Form.Item
                    name="produtoId"
                    label="Produto"
                    validate={(value) =>
                      !value ? 'O produto é obrigatório' : null
                    }
                  >
                    <Select
                      name="produtoId"
                      id="produtoId"
                      allowClear={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => {
                        setFieldValue('produtoId', value);
                        handleProdutoTabela(value);
                      }}
                      value={formik.values.produtoId}
                      disabled={!acesso || isEdit}
                    >
                      {produtosTabelaJuros?.map((produto) => (
                        <Option key={produto.id} value={produto.id}>
                          {produto.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item name="tipo" label="Tipo">
                    <Select
                      name="tipo"
                      id="tipo"
                      allowClear={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => {
                        setFieldValue('tipo', value);
                        handleTipoTabela(value);
                      }}
                      value={formik.values.tipo}
                      disabled={!acesso || isEdit}
                      className='tipo-juros'
                    >
                      {tiposTabelaJuros?.map((tipo) => (
                        <Option key={tipo.id} value={tipo.id}>
                          {tipo.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {formik.values.produtoId && controleCarencia() !==  1 && (
                  <>
                    <div className="col-lg-4">
                      <Form.Item name="carenciaMedia" label="Carência Média">
                        <Input
                          name="carenciaMedia"
                          id="carenciaMedia"
                          onBlur={(e) =>
                            handleCarenciaMedia(e.target.value, setFieldValue)
                          }
                          disabled={
                            !acesso ||
                            (isEdit && !formData?.carenciaMedia) ||
                            values?.carenciaMinima > 0 ||
                            // eslint-disable-next-line
                            values?.carenciaMaxima > 0 || (isEdit && controleCarencia() == 2 && formData.carenciaMedia == null && formData.carenciaMinima == null && formData.carenciaMaxima == null)
                          }
                          validate={(value) => {
                            if (controleCarencia() !==  1) {
                              if (
                                formData?.carenciaMinima ||
                                values?.carenciaMinima ||
                                values?.carenciaMaxima
                              ) {
                                return null;
                              }
                              if (!value && formData?.carenciaMedia && controleCarencia() !==  2) {
                                return 'A carência média é obrigatória';
                              }
                              // eslint-disable-next-line
                              if (value <= 0 && controleCarencia() == 0) {
                                return 'A carência é obrigatória';
                              }
                            }
                          }}
                          type="number"
                          onKeyDown={blockNonNumericChars}
                          onChange={formik.handleChange}
                          value={formik.values.carenciaMedia}
                        />
                      </Form.Item>
                    </div>
                    {showTable === 0 && (
                      <>
                        <div className="col-lg-4 col-md-6">
                          <Form.Item
                            name="carenciaMinima"
                            label="Carência Mínima"
                            validate={(value) => {
                              const { carenciaMedia, carenciaMaxima } = values;

                              if (controleCarencia() !== 1) {
                                if (
                                  (!!carenciaMedia && carenciaMedia > 0) ||
                                  formData?.carenciaMedia
                                )
                                  return null;
                                if (
                                  (!value && carenciaMaxima >= 1) ||
                                  (formData?.carenciaMinima && !value)
                                )
                                  return 'A carência mínima é obrigatória';
                                if (
                                  value > 0 &&
                                  value >= carenciaMaxima &&
                                  carenciaMaxima > 0
                                )
                                  return 'Carência mínima deve ser menor que carência máxima';

                                // eslint-disable-next-line
                                if (value <= 0 && controleCarencia() == 0) {
                                  return 'A carência é obrigatória';
                                } else return null;
                              }
                            }}
                          >
                            <Field name="carenciaMinima">
                              {({ field }) => (
                                <Input
                                  name="carenciaMinima"
                                  id="carenciaMinima"
                                  className="ant-input"
                                  inputMode="numeric"
                                  {...field}
                                  disabled={
                                    !acesso ||
                                    values?.carenciaMedia > 0 ||
                                    // eslint-disable-next-line
                                    formData?.carenciaMedia || (isEdit && controleCarencia() == 2 &&
                                    formData.carenciaMedia == null && formData.carenciaMinima == null &&
                                    // eslint-disable-next-line
                                    formData.carenciaMaxima == null) || (isEdit && controleCarencia() == 2 &&
                                    formData.carenciaMedia == null && formData.carenciaMinima == null && formData.carenciaMaxima == null)
                                  }
                                  type="number"
                                  onKeyDown={blockNonNumericChars}
                                  onChange={formik.handleChange}
                                  value={formik.values.carenciaMinima}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <Form.Item
                            name="carenciaMaxima"
                            label="Carência Máxima"
                            validate={(value) => {
                              const { carenciaMedia, carenciaMinima } = values;
                              if (controleCarencia() !== 1) {
                                if (
                                  formData?.carenciaMedia ||
                                  (!!carenciaMedia && carenciaMedia > 0)
                                ) {
                                  return null;
                                }

                                if (
                                  (!value && carenciaMinima >= 1) ||
                                  (formData?.carenciaMaxima && !value)
                                )
                                  return 'A carência máxima é obrigatória';
                                if (value > 0 && value <= carenciaMinima)
                                  return 'Carência máxima deve ser maior que carência mínima';
                                // eslint-disable-next-line
                                if (value <= 0 && controleCarencia() == 0) {
                                  return 'A carência é obrigatória';
                                } else return null;
                              }
                            }}
                          >
                            <Field name="carenciaMaxima">
                              {({ field }) => (
                                <Input
                                  name="carenciaMaxima"
                                  id="carenciaMaxima"
                                  className="ant-input"
                                  inputMode="numeric"
                                  {...field}
                                  disabled={
                                    !acesso ||
                                    values?.carenciaMedia > 0 ||
                                    // eslint-disable-next-line
                                    formData?.carenciaMedia || (isEdit && controleCarencia() == 2 && formData.carenciaMedia == null && formData.carenciaMinima == null && formData.carenciaMaxima == null)
                                  }
                                  type="number"
                                  onKeyDown={blockNonNumericChars}
                                  onChange={formik.handleChange}
                                  value={formik.values.carenciaMaxima}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="form-row tabela-cliente">
                <div className="col-lg-4 col-md-4">
                  <Form.Item name="tabelaCliente" label={' '}>
                    <span className="mr-2">Tabela Cliente</span>
                    <Switch
                      name="tabelaCliente"
                      id="tabelaCliente"
                      size="small"
                      disabled={!acesso}
                      onChange={(value) => {
                        setFieldValue('tabelaCliente', value);
                        handleTabelaCliente(value);
                      }}
                      value={formik.values.tabelaCliente}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-2">
                <Button
                  type="reset"
                  variant="gray"
                  className="btn-xs-block"
                  onClick={() => {
                    resetForm();
                    resetTableForm();
                  }}
                  style={{ minWidth: '115px' }}
                  disabled={!acesso}
                >
                  <span>Cancelar</span>
                </Button>

                <span className="m-2"></span>

                {isEdit
                  ?
                  (
                    <>
                     {(checarPermissao(
                        'botao',
                        'botao.cadastro.tabelajuros.excluir',
                        'Visualizar'
                      ) &&
                        !formData.ativo) && (
                          <>
                            <Button
                              type="button"
                              variant="red-outline"
                              className="btn-xs-block"
                              style={{ minWidth: '100px' }}
                              onClick={() => { setVisibleModalDelete(true) }}
                            >
                              <span>Excluir</span>
                            </Button>
                            <span className="m-2"></span>
                          </>
                      )}


                      {checarPermissao(
                        'botao',
                        'botao.cadastro.tabelajuros.salvar',
                        'Visualizar',
                      ) && (
                        <Button
                          type="submit"
                          variant="orange"
                          className="btn-xs-block"
                          style={{ minWidth: '115px' }}
                          disabled={
                            !acesso ||
                            !checarPermissao(
                              'botao',
                              'botao.cadastro.tabelajuros.salvar',
                              'Editar',
                            )
                          }
                        >
                          <Icon variant="save" />
                          <span className="ml-2">Salvar</span>
                        </Button>
                      )}


                    </>
                  )
                  : checarPermissao(
                    'botao',
                    'botao.cadastro.tabelajuros.incluir',
                    'Visualizar',
                  ) && (
                    <Button
                      type="submit"
                      variant="green"
                      className="btn-xs-block"
                      style={{ minWidth: '115px' }}
                      disabled={
                        !acesso ||
                        !checarPermissao(
                          'botao',
                          'botao.cadastro.tabelajuros.incluir',
                          'Criar',
                        )
                      }
                    >
                      <Icon variant="include" />
                      <span className="ml-2">Incluir</span>
                    </Button>
                  )}
              </div>
            </Form>
          </FormikProvider>
        </div>
      </Card>
      <ModalInfo
        visible={visibleModalDelete}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalDelete(false)}}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Deseja realmente excluir a tabela:<br/>{formik.values.nome} ?</div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => {setVisibleModalDelete(false)}}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => deleteTable(formik.values.id)}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </ModalInfo>
      <ModalInfo
        visible={visibleModalSuccess}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalSuccess(false)}}
      >
        <div className="icon">
          <SuccessOutlinedIcon width={75} />
        </div>
        <div className="text-modal">Sucesso, tabela excluída.</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {setVisibleModalSuccess(false)}}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </ModalInfo>
    </Overlay>
  );
}

export default TabelaForm;
