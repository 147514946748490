import api from './api';

export const loadContexto = async () => {
  const { data } = await api.get(`/contextos/usuarios`);
  return data.data;
};

export const getMatriz = async () => {
  const { data } = await api.get(`/correspondente`);
  return data.data;
};

export const getUnidades = async (id) => {
  const { data } = await api.get(`/correspondente/${id}/unidade`);
  return data.data;
};

export const getUnidadeSubunidade = async (unidadeId) => {
  const { data } = await api.get(`/correspondente/${unidadeId}`)
  return data
}

export const getUsuarioEdit = async (id) => {
  const { data } = await api.get(`/usuario/${id}`);
  return data.data;
};

export const getPossuiPosicao = async (id) => {
  const { data } = await api.get(`usuario/${id}/possui-posicao-hierarquica`);
  return data.data;
};

export const bloquearUsuario = async (id, body) => {
  const { data } = await api.put(`usuario/${id}/bloquear`, body);
  return data.data.status;
};

export const desbloquearUsuario = async (id, body) => {
  const { data } = await api.put(`usuario/${id}/desbloquear`, { id, ...body });
  return data.data.status;
};

export const inativarUsuario = async (id, body) => {
  const { data } = await api.put(`usuario/${id}/inativar`, body);
  return data.data.status;
};

export const gerarLogin = async (nome) => {
  const { data } = await api.post('/usuario/cria-login', nome);
  return data.data;
};

export const getHistoricoHierarquia = async (userId) => {
  const { data } = await api.get(`/hierarquia/historico-usuario/${userId}`);
  return data.data;
};

export const postUsuariosSemEmailList = async (idList) => {
  const { data } = await api.post(`/usuario/redefinir-senha/sem-email`, idList);
  return data.data;
};

export const postSendEmailRedefinirSenha = async (idList) => {
  const { data } = await api.post(`/usuario/redefinir-senha`, idList);
  return data.data;
};

export const getObservacoes = async (userId, ultimoPostId = false) => {
  function getUltimoPostIdParams() {
    if (ultimoPostId) {
      return `?id=${ultimoPostId}`;
    }
    return '';
  }
  const { data } = await api.get(
    `/usuario/${userId}/observacoes/${getUltimoPostIdParams()}`,
  );
  const { observacoes, refreshObservacoes } = data.data;
  return {
    fetchedObservacoes: observacoes,
    fetchedRefreshObservacoes: refreshObservacoes,
  };
};

export const postObservacao = async (userId, mensagem) => {
  const { data } = await api.post(`/usuario/${userId}/observacoes`, {
    mensagem,
  });
  const sentMessage = data?.data;
  if (sentMessage) {
    return { sentMessage };
  }
  return;
};

export const validaExclusaoPerfil = async (userId, listaPerfis) => {
  const { data } = await api.post(
    `/usuario/${userId}/valida-perfis-hierarquia`,
    listaPerfis,
  );
  return data.data;
};

export const loadUsuarios = async ({
  pagina = null,
  quantidadePorPagina = null,
  filtroTipo = null,
  filtroCPF = null,
  filtroNome = null,
  filtroLogin = null,
  filtroMatriz = null,
  filtroUnidade = null,
  filtroAtivo = null,
  filtroBloqueado = null,
  ordenacao = null,
  ordenacaoAsc = false,
}) => {
  const { data } = await api.post(`/usuario/lista`, {
    pagina,
    quantidadePorPagina,
    filtroTipo,
    filtroCPF,
    filtroNome,
    filtroLogin,
    filtroMatriz,
    filtroUnidade,
    filtroAtivo,
    filtroBloqueado,
    ordenacao,
    ordenacaoAsc,
    validaExclusaoPerfil,
  });
  return data;
};

export const postUsuario = async (user) => {
  const success = await api.post('/usuario', user);
  return success;
};

export const putUsuario = async (userId, user) => {
  const success = await api.put(`/usuario/${userId}`, user);
  return success;
};
export const redefinirSenha = async ({
  id,
  token,
  senhaAtual,
  senha,
  confirmacaoSenha,
}) => {
  const { data } = await api.put(`/usuario/${id}/nova-senha?token=${token}`, {
    senhaAtual,
    senha,
    confirmacaoSenha,
  });
  return data;
};
export const validaLink = async (id, token) => {
  const { data } = await api.get(`/usuario/${id}/definir-nova-senha?token=${token}`);
  return data.data;
};

export const usuariosAtivos = async (body) => {
  const { data } = await api.post(`/usuario/ativos`, body);
  return data.data;
};

export const getEmailUsuarioRedefinicao = async (user) => {
  const { data } = await api.get(
    `usuario/obter-dados-redefinicao-acesso?login=${user}`
  );
  return data.data;
};

export const enviarEmailRedefinicao = async (user) => {
  const { data } = await api.post(
    `usuario/receber-email-redefinicao-acesso?login=${user}`
  );
  return data.data;
};

export const enviarSmsRedefinicao = async (user) => {
  const { data } = await api.post(
    `usuario/receber-sms-redefinicao-acesso?login=${user}`
  );
  return data.data;
};

export const getHistoricoSeguranca = async (payload) => {
  const response = await api.post('/usuario/historico-seguranca', payload);
  return response.data.data;
};


export const getUsuarioAtivo = async (cpf) => {
  const {data}  = await api.get(`/usuario/ativo/${cpf}`)
   return data;
}

export const getTokenRedefinicao = async (userId) => {
  const {data}  = await api.post(`/usuario/token-atualiza-senha/${userId}`)
   return data;
}