import React, { useContext } from 'react';

import Layout from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import { Breadcrumb } from 'antd';
import { Link, useParams } from 'react-router-dom';

import useInspinia from '../../hooks/useInspinia';

import PropostaServicoForm from './PropostaServicoForm';
import PropostaServicoProvider from '../../contexts/PropostaServicoContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function PropostaServico() {
  const params = useParams();
  const idPropostaServico = params?.propostaId;
  const { checarPermissao } = useContext(ControleAcessoContext);
  useInspinia();

  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta Crefaz Mais</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <PropostaServicoProvider>
      {checarPermissao('tela', 'tela.propostaCrefazMais', 'Visualizar') && (
        <>
          <Content>
            <div className="proposta-servico">
              <PropostaServicoForm propostaServicoId={idPropostaServico} />
            </div>
          </Content>
        </>
      )}
      </PropostaServicoProvider>
    </Layout >
  )
}

export default PropostaServico;