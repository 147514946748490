import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import './styles.scss';

import { CadastroPessoaContext } from '../../../contexts/CadastroPessoaContext';

import Button from '../../../components/Button';
import ConversationMessages from '../../../components/ConversationMessages';

import { ReactComponent as UserIcon } from '../../../assets/images/icons/client-circle.svg';
import { ReactComponent as SaveIcon } from '../../../assets/images/icons/save.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/icons/back.svg';
import { ReactComponent as WriteClipboardIcon } from '../../../assets/images/icons/write-clipboard.svg';
import { ReactComponent as ConfigIcon } from '../../../assets/images/icons/config.svg';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function EdicaoCadastroPessoa({ formik, setHasErrors }) {
  const history = useHistory();
  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    pessoa,
    anotacoes,
    cadastrarAnotacao,
    setIsModalVisible,
    setIdPessoaModalBlocklist,
    disabled,
  } = useContext(CadastroPessoaContext);

  const generateAnotacoes = () => {
    moment.locale('pt-br');
    return anotacoes.map((anotacao) => ({
      tipo: 2,
      remetente: anotacao.usuario,
      tempo: '',
      horario: moment(anotacao.data).format('hh:mm'),
      diaMes: moment(anotacao.data).format('DD/MM'),
      ano: moment(anotacao.data).format('YYYY'),
      mensagem: anotacao.anotacao,
    }));
  };

  const handleSubmit = () => {
    let hasErrors = {};

    if (formik.errors.pessoa) {
      hasErrors.pessoa = formik.errors.pessoa;
    } else {
      hasErrors.pessoa = null;
    }

    if (formik.errors.contatos) {
      hasErrors.contatos = formik.errors.contatos;
    } else {
      hasErrors.contatos = null;
    }

    if (formik.errors.endereco) {
      hasErrors.endereco = formik.errors.endereco;
    } else {
      hasErrors.endereco = null;
    }

    if (formik.errors.profissao) {
      hasErrors.profissao = formik.errors.profissao;
    } else {
      hasErrors.profissao = null;
    }

    if (formik.errors.referencias) {
      hasErrors.referencias = formik.errors.referencias;
    } else {
      hasErrors.referencias = null;
    }

    setHasErrors(hasErrors);
    formik.submitForm();
  };

  return (
    <div className="toolbar-container">
      <div>
        <div className="d-flex align-items-center">
          <UserIcon height="100%" color="#445566" />
          <span
            style={{
              fontSize: '16px',
              fontWeight: 600,
              marginLeft: 5,
              color: '#445566',
            }}
          >
            {pessoa?.pessoa?.nome}
          </span>
        </div>
      </div>

      <div>
        <div className="buttons-container">
          <Button
            type="button"
            onClick={() => handleSubmit()}
            variant="green"
            className="btn-xs-block"
            data-cy="submit-pessoa"
            disabled={
              checarPermissao(
                'botao',
                'botao.cadastroPessoa.edicao',
                'Visualizar',
              )
                ? disabled
                : true
            }
          >
            <SaveIcon style={{ marginRight: 10 }} />
            <span>Salvar</span>
          </Button>
          <span className="p-1"></span>
          <Button
            onClick={() => {
              setIdPessoaModalBlocklist(pessoa?.pessoa?.id);
              setIsModalVisible(true);
            }}
            type="button"
            variant="brown"
            className="btn-xs-block"
          >
            <ConfigIcon
              className="config-icon-svg"
              style={{ marginRight: 10 }}
            />
            <span>Blocklist</span>
          </Button>

          <span className="p-1"></span>

          <ConversationMessages
            title="Anotações"
            mode="tooltip"
            cpf={pessoa?.pessoa?.cpf}
            listMessages={generateAnotacoes()}
            sendMessage={cadastrarAnotacao}
            placementTooltip="bottomRight"
            type="anotacoes"
            origem={2}
            externo={false}
            disabled={
              !checarPermissao(
                'botao',
                'botao.cadastroPessoa.cliente.anotacoesInternas',
                'Criar',
              )
            }
          >
            <Button 
              type="button" 
              variant="gray" 
              className="btn-xs-block"
              disabled={
                !checarPermissao(
                  'botao',
                  'botao.cadastroPessoa.cliente.anotacoesInternas',
                  'Visualizar',
                )
              }
            >
              <WriteClipboardIcon style={{ marginRight: 10 }} />
              <span>Interno</span>
            </Button>
          </ConversationMessages>

          <span className="p-1"></span>

          <Button type="button" variant="orange">
            <BackIcon onClick={() => history.push('/cadastro-pessoa')} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EdicaoCadastroPessoa;
