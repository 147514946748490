import React, { useState, useContext, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Form } from 'formik-antd';
import { FormikProvider } from 'formik';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import ModalBlocklist from '../../../components/ModalBlockList';
import ModalMotivosDecisao from '../../../components/ModalMotivosDecisao';
import Overlay from '../../../components/Overlay';

import { ReactComponent as QuestionMark } from '../../../assets/images/icons/question-mark.svg';

import OperacaoForm from '../Forms/OperacaoForm';
import HistoricoCiaForm from '../Forms/HistoricoCiaForm';
import ClienteForm from '../Forms/ClienteForm';
import ContatoForm from '../Forms/ContatoForm';
import HistoricoForm from '../Forms/HistoricoForm';
import EnderecoForm from '../Forms/EnderecoForm';
import BancariosForm from '../Forms/BancariosForm';
import ProfissionalForm from '../Forms/ProfissionalForm';
import ArquivoForm from '../Forms/ArquivoForm';
import UnidadeForm from '../Forms/UnidadeForm';
import ResumoContato from '../Forms/ResumoContato';
import ProcCrivoForm from '../Forms/ProcCrivoForm';

import './styles.scss';
import { DadosGarantiaForm } from '../Forms/DadosGarantiaForm';

function TabsComponent() {
  const {
    proposta,
    formik,
    contexto,
    carregarImagem,
    dadosModalBlocklist,
    isModalBlacklistVisible,
    setIsModalBlacklistVisible,
    isModalMotivosVisible,
    setIsModalMotivosVisible,
    highlightTab,
    loadingProposta,
    listaAnalista,
    debitosConveniada,
    setDebitosConveniada,
    historicoChamada,
    setHistoricoChamada,
    clienteElegivel,
    setClienteElegivel,
    editableField,
  } = useContext(AnaliseMesaContext);

  const [tabIndex, setTabIndex] = useState(0);

  const { values, setValues } = formik;

  useEffect(() => {
    if (contexto?.dadosBemGarantia && proposta) {
      setValues({...proposta, dadosBemGarantia: contexto?.dadosBemGarantia});
    } else if (proposta) {
      setValues(proposta)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta, setValues]);

  const tabPanels = () =>
    // eslint-disable-next-line array-callback-return
    contexto?.abasAcessiveis?.map((aba) => {
      switch (aba.Nome) {
        case 'VER +':
          return (
            <TabPanel>
              <ProcCrivoForm/>
            </TabPanel>
          );
        case 'Dados Cadastrais':
          return (
            <TabPanel>
              <ClienteForm values={values} />
            </TabPanel>
          );
        case 'Documentos':
          return (
            <TabPanel>
              <ArquivoForm />
            </TabPanel>
          );
        case 'Operação':
          return (
            <TabPanel>
              <OperacaoForm values={values} />
            </TabPanel>
          );
        case 'Histórico CIA':
          return (
            <TabPanel>
              <HistoricoCiaForm
                values={values}
                contexto={contexto}
                debitosConveniada={debitosConveniada}
                setDebitosConveniada={setDebitosConveniada}
                carregarImagem={carregarImagem}
                clienteElegivel={clienteElegivel}
                setClienteElegivel={setClienteElegivel}
                editableField={editableField}
              />
            </TabPanel>
          );
        case 'Dados Garantia' :
          return (
            <TabPanel>
              <DadosGarantiaForm />
            </TabPanel>
          );
        case 'Contatos':
          return (
            <TabPanel>
              <ContatoForm values={values} />
            </TabPanel>
          );
        case 'Chamadas':
          return (
            <TabPanel>
              <HistoricoForm
                values={values}
                contexto={contexto}
                listaAnalista={listaAnalista}
                proposta={proposta}
                historicoChamada={historicoChamada}
                setHistoricoChamada={setHistoricoChamada}
              />
            </TabPanel>
          );
        case 'Endereço':
          return (
            <TabPanel>
              <EnderecoForm />
            </TabPanel>
          );
        case 'Bancários':
          return (
            <TabPanel>
              <BancariosForm values={values} />
            </TabPanel>
          );
        case 'Profissional':
          return (
            <TabPanel>
              <ProfissionalForm values={values} />
            </TabPanel>
          );
        case 'Unidade':
          return (
            <TabPanel>
              <UnidadeForm />
            </TabPanel>
          );
        case 'Resumo Contato':
          return (
            <TabPanel>
              <ResumoContato formik={formik} />
            </TabPanel>
          );
        default:
      }
    });

  return (
    <>
      <Overlay active={loadingProposta}>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <Tabs selectedIndex={tabIndex}>
              <TabList>
                {contexto?.abasAcessiveis?.map((aba, i) => (
                  <Tab
                    key={i}
                    onClick={() => {
                      setTabIndex(i);
                    }}
                  >
                    {i !== tabIndex && highlightTab(aba.Tag) ? (
                      <QuestionMark className="question-icon" />
                    ) : null}
                    <span
                      className={
                        i !== tabIndex &&
                        highlightTab(aba.Tag) &&
                        `destaque-alterados`
                      }
                    >
                      {aba.Nome}
                    </span>
                  </Tab>
                ))}
              </TabList>

              <div className="react-tabs__content-holder">{tabPanels()}</div>
            </Tabs>
          </Form>
        </FormikProvider>
      </Overlay>
      <ModalBlocklist
        isModalVisible={isModalBlacklistVisible}
        setIsModalVisible={setIsModalBlacklistVisible}
        dados={dadosModalBlocklist}
        disabled={contexto?.bloqueiaAcessoSimultaneo}
      />
      <ModalMotivosDecisao
        isModalVisible={isModalMotivosVisible}
        setIsModalVisible={setIsModalMotivosVisible}
      />
    </>
  );
}

export default TabsComponent;
