import React, { useContext } from 'react';
import Button from '../Button';
import Overlay from '../Overlay';

import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import './styles.scss';

function CardFormServico({
  children,
  action,
  submitButton,
  loading = false,
  servico,
  setServico,
}) {

  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <Overlay active={loading}>
      <div className="product-card-form">
        <div
          className="product-card-header"
        >
          <div className="product-name">{servico.servicoNome}</div>

          <div className="title-buttons">
            {checarPermissao(
              'botao',
              'botao.credito.ofertaProdutos.cadastrarCrefazMais',
              'Visualizar',
            ) && submitButton && (
                <Button
                  className="add-button"
                  onClick={action}
                  variant="orange"
                  disabled={!checarPermissao(
                    'botao',
                    'botao.credito.ofertaProdutos.cadastrarCrefazMais',
                    'Criar',
                  )}
                >
                  CADASTRAR
                  <ArrowRightIcon
                    height="15"
                    fill="#17222D"
                    style={{ marginLeft: 10 }}
                  />
                </Button>
              )}
            <button
              type="button"
              className="x-button"
              onClick={() => setServico(null)}
            >
              X
            </button>
          </div>
        </div>
        <div className="product-card-content">
          {children}
        </div>
      </div>
    </Overlay>
  );
}

export default CardFormServico;
