import React, { useContext, useState } from 'react';
import swal from 'sweetalert';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/CustomTable';
import Icon from '../../../components/Icon';
import JurosPercentuaisForm from './form';
import JurosPercentuaisFormEdit from './formEdit';
import { Upload } from 'antd';
import {Modal} from 'antd';
import Papa from 'papaparse';
import { ReactComponent as IconTrash } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen.svg';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

import { percentualFormat } from '../../../utils/numberFormatter';
import Button from '../../../components/Button';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

function TabelaJurosPercentuais() {

  const [visibleModalErrorCsv, setVisibleModalErrorCsv] = useState(false);
  const [visibleModalErrorCsvFormat, setVisibleModalErrorCsvFormat] = useState(false);

  const {
    tabelaJurosValores,
    isEditJuros,
    setTabelaJurosValores,
    setTableFormData,
    setIsEditJuros,
    trataNovosValoresTabelaPercentual
  } = useContext(TabelaJurosContext);

  const removeItem = (pItem) => {
    swal({
      title: '',
      text: 'Tem certeza que deseja excluir o item?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then((willDelete) => {
      if (willDelete) {
        setTabelaJurosValores(
          tabelaJurosValores.filter((item) => item !== pItem),
        );
        swal('Item excluído com sucesso', { icon: 'success' });
      }
    });
  };

  const columns = [
    {
      Header: 'Plano',
      accessor: 'plano',
      disableSortBy: true,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Taxa',
      accessor: 'juros',
      disableSortBy: true,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{percentualFormat(value, 4)}</div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      disableSortBy: true,
      style: {
        minWidth: 60,
        width: 60,
        textAlign: 'center',
      },
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                removeItem(row.original);
              }}
            >
              <IconTrash width="15" />
            </a>

            <span className="m-1"></span>

            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setTableFormData({
                  ...row?.original,
                  oldPlano: row?.original?.plano,
                  jurosFloat: row?.original?.juros,
                  edit: true,
                });
                setIsEditJuros(true);
              }}
            >
              <IconEdit width="15" />
            </a>
          </div>
        );
      },
    },
  ];

  const beforeUpload = (file) => {
    if (file.type !== 'text/csv') {
      setVisibleModalErrorCsv(true);
    } else {
      handleFileUpload(file);
    }
    return false;
  };

  const handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true, // Define se a primeira linha do CSV é um cabeçalho
      skipEmptyLines: true,
      complete: (result) => {
        const headers = result.meta.fields;

        // Verificações de cabeçalho e conteúdo
        const isJurosPercentuais = JSON.stringify(headers) === JSON.stringify(["PrazoInicial", "PrazoFinal", "Taxa"]);

        if(isJurosPercentuais){
          let newArray = [];
          result.data.forEach((row) => {
            const data = {
              id: null,
              tabelaJurosId: 0,
              valor: 0,
              plano: 0,
              edit: false,
              oldValor: 0,
              oldPlano: 0,
              prazoInicial: row.PrazoInicial,
              prazoFinal: row.PrazoFinal,
              juros: row.Taxa,
              jurosFloat: parseFloat(row.Taxa.toString().replace(',', '.')),
            };
            newArray = trataNovosValoresTabelaPercentual(data, newArray)
          });
            setTabelaJurosValores(newArray);
        }
        else{
          setVisibleModalErrorCsvFormat(true);
        }
      },
      error: () => {
        setVisibleModalErrorCsvFormat(true);
      },
    });
  };

  return (
    <>
    <Card 
      title={<Title label="Tabela de Juros Percentuais" />} 
      className="flex" 
      rightBar={
        <Upload
          multiple={false}
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button variant='blue'>
            <Icon variant="upload" />
            <span className="text-uppercase ml-1">CSV</span>
          </Button>
        </Upload>
      }>
      <div>
        {isEditJuros ? <JurosPercentuaisFormEdit /> : <JurosPercentuaisForm />}
      </div>

      <Table columns={columns} data={tabelaJurosValores} pagination />
    </Card>

    <Modal
        visible={visibleModalErrorCsv}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalErrorCsv(false)}}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Somente podem ser enviados arquivos no formato "CSV".</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {setVisibleModalErrorCsv(false)}}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>

      <Modal
        visible={visibleModalErrorCsvFormat}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalErrorCsvFormat(false)}}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Erro ao anexar o arquivo CSV. Verifique o arquivo e tente novamente.</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {setVisibleModalErrorCsvFormat(false)}}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default TabelaJurosPercentuais;
