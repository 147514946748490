import React, { Fragment, useContext, useState, useEffect } from 'react';

import { Button } from 'antd';
import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';

import InputPhone from '../../../components/InputPhone';

import validaPhone from '../../../utils/validaPhone';
import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';
import { getDDDs } from '../../../services/contatoService';

const { Option } = Select;

function DadosContatoForm({ formik }) {
  const { setFieldTouched, setFieldValue, touched, values, errors } = formik;

  const {
    telefoneExtra,
    setTelefoneExtra,
    tipoTelefone,
    editableField,
  } = useContext(PropostaServicoContext);

  const [statusEmail, setStatusEmail] = useState('');
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusTelefoneExtra, setStatusTelefoneExtra] = useState([]);
  const [telefonesAdicionais, setTelefonesAdicionais] = useState([]);

  const valuesTelefonesExtras = {
    telefone: '',
    tipoTelefone: 0,
  };

  const handleAddTelefoneExtra = () => {
    const indice = telefoneExtra.length;

    if (telefoneExtra) {
      setTelefoneExtra((state) => [...state, { ...valuesTelefonesExtras }]);
    } else {
      setTelefoneExtra([valuesTelefonesExtras]);
    }
    setFieldTouched(`contato.telefoneExtra.${indice}.telefone`, true);
  };

  const handleAddTelefoneAdicional = () => {
    const indice = telefoneExtra.length;
    if (telefonesAdicionais) {
      setTelefonesAdicionais((state) => [
        ...state,
        { ...valuesTelefonesExtras },
      ]);
    } else {
      setTelefonesAdicionais([valuesTelefonesExtras]);
    }
    setFieldTouched(`telefoneAdicional.${indice}.telefone`, true);
  };

  const handleRemoveTelefoneExtra = (index) => {
    setTelefoneExtra((newArray) => newArray.filter((_, idx) => idx !== index));
    values.contato.telefoneExtra.splice(index, 1);
    const newStateArray = [...statusTelefoneExtra];
    newStateArray.splice(index, 1);
    setStatusTelefoneExtra(newStateArray);
    setFieldTouched(`contato.telefoneExtra.${index}.telefone`, false);
    setFieldTouched(
      `contato.telefoneExtra.${index}.tipoTelefone`,
      false,
    );
  };

  const handleTelefoneExtraType = (value, indice) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(
        `contato.telefoneExtra.${indice}.tipoTelefone`,
        type,
      );
    } else {
      setFieldValue(
        `contato.telefoneExtra.${indice}.tipoTelefone`,
        '',
      );
    }
  };


  const handleTelefoneType = async (value) => {
    const ddds = JSON.parse(sessionStorage.getItem('ddds'));

    if (!ddds) {
      const data = await getDDDs()

      if (data) {
        sessionStorage.setItem('ddds', JSON.stringify(data));
      }
    }

    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(`contato.tipoTelefone`, type);
    } else {
      setFieldValue(`contato.tipoTelefone`, '');
    }
  };


  const handleTelefoneExtraValidation = (index, value) => {
    const newStateArray = [...statusTelefoneExtra];
    const type = validaPhone(value);
  
    if (type === 0) {
      newStateArray[index] = 'warning';
    } else {
      newStateArray[index] = ''; 
    }
  
    setStatusTelefoneExtra(newStateArray);
  };

    useEffect(() => {
      if(formik?.values?.contato?.telefone && tipoTelefone?.length > 0){
        let phone = formik?.values?.contato?.telefone.replace(/\D/g, '')

        if(phone?.length === 11 || phone?.length === 10){
          handleTelefoneType(formik?.values?.contato?.telefone)
        }
      }
    }, [formik?.values?.contato?.telefone, tipoTelefone])

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados de Contato do Cliente</div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-lg-6 col-xl-6">
          <Form.Item
            name="contato.email"
            label="E-mail"
            validateStatus={ touched?.contato?.email && statusEmail}
            validate={(value) => {
              let msgError = null; 

                if (!value) {
                  msgError = 'Campo Obrigatório';
                  setStatusEmail('error');

                } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
                  msgError = 'E-mail inválido';
                  setStatusEmail('warning');

                } else {
                  msgError = '';
                  setStatusEmail('');
                }
              return msgError;
            }}
            help={((statusEmail === 'error' && !formik?.values?.contato?.email) || (statusEmail === 'warning' && formik?.values?.contato?.email )) && touched?.contato?.email && errors?.contato?.email}
            hasFeedback
          >
            <Input
              name="contato.email"
              disabled={!editableField('contato.email')}
              placeholder="mail@example.com"
              value={formik?.values?.contato?.email}
              onBlur={(e) => {
                setFieldTouched('contato.email', true)
                setFieldValue('contato.email', e.target.value);
              }}
              maxLength={100}
            />
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name={`contato.telefone`}
            label="Telefone"
            validateStatus={touched?.contato?.telefone && statusTelefone}
            help={touched?.contato?.telefone && errors?.contato?.telefone}
            validate={(value) => {
              let msgError = '';

              if (!value) {
                msgError = 'Campo Obrigatório';
                setStatusTelefone('error');
              } else if (value && !validaPhone(value)) {
                msgError =
                  'Telefone inválido';
                setStatusTelefone('warning');
              } else {
                msgError = '';
                setStatusTelefone('');
              }

              return msgError;
            }}
          >
            <Field name={`contato.telefone`}>
              {({ field }) => (
                <InputPhone
                  {...field}
                  disabled={!editableField('contato.telefone')}
                  onBlur={(e) => {
                    setFieldTouched('contato.telefone', true)
                  }}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name={`contato.tipoTelefone`}
            label="Tipo de telefone"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              name={`contato.tipoTelefone`}
              suffixIcon={''}
              disabled={true}
              className={`selectTipoTelefone ${!editableField('contato.tipoTelefone') &&
                'selectTipoTelefoneDisabled'
                }`}
            >
              {tipoTelefone.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        {telefoneExtra &&
          telefoneExtra.map((_, i) => (
            <Fragment key={i}>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contato.telefoneExtra.${i}.telefone`}
                  label="Telefone Extra"
                  validateStatus={statusTelefoneExtra[i]}
                  help={
                    statusTelefoneExtra[i] === 'warning'
                      ? 'Telefone inválido'
                      : ''
                  }
                >
                  <Field name={`contato.telefoneExtra.${i}.telefone`}>
                    {({ field }) => (
                      <InputPhone
                        {...field}
                        onBlur={(e) =>{
                            setFieldTouched(`contato.telefoneExtra.${i}.telefone`, true);
                            handleTelefoneExtraValidation(i, e.target.value);
                            handleTelefoneExtraType(e.target.value, i)
                          }
                        }
                        disabled={!editableField(`contato.telefone`)}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contato.telefoneExtra.${i}.tipoTelefone`}
                  label="Tipo do Telefone"
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    name={`contato.telefoneExtra.${i}.tipoTelefone`}
                    suffixIcon={''}
                    disabled={true}
                    className="selectTipoTelefone"
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                  >
                    {tipoTelefone.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-1 col-lg-1 col-xl-1">
                <Button
                  htmlType="button"
                  className="btnRemoveInputs"
                  disabled={!editableField(`contato.telefone`)}
                  onClick={() => handleRemoveTelefoneExtra(i)}
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
              <div className="col-md-5 col-lg-5 col-xl-5"></div>
            </Fragment>
          ))}
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={
              editableField('contato.telefone')
                ? handleAddTelefoneExtra
                : handleAddTelefoneAdicional
            }
            disabled={!editableField(`contato.adicionar`)}
          >
            <i className="fa fa-plus mr-2" /> Telefone
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosContatoForm };
