
export const initialValues = {
  id: null,
  operacao: {
    pacoteId: null,
    adicional: false,
    meioPagamentoId: null,
    prestacao: '',
    valorAdicional: '',
  },
  cliente: {
    cpf: '',
    nome: '',
    nascimento: '',
    rg: '',
    rgUfId: null,
    rgEmissor: '',
    rgEmissao: '',
    sexo: '',
    estadoCivil: null,
    naturalidadeCidadeId: null,
    nomeConjuge: '',
  },  
  contato: {
    email: '',
    telefone: '',
    telefoneFixo: '',
    telefoneExtra: [],
  },
  endereco: {
    cep: '',
    logradouro: '',
    numero: null,
    complemento: '',
    bairro: '',
    cidadeId: null,
    cidade:'',
    ufId: null,
  },
  unidade: {
    nomeMatriz: '',
    loginAgente: '',
    supervisorComercial: '',
    telefoneExtraMatriz: '',
    celularSupervisor: '',
    nomeVendedor: '',
    cpfVendedor: '',
    celularVendedor: '',
  }
};