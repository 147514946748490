import React, { useContext, useEffect } from 'react';

import CardProduto from '../../../components/CardProduto';
import CardServico from '../../../components/CardServico';
import { ReactComponent as CrefazMaisLogo } from '../../../assets/images/crefaz-mais-logo.svg';
import { ReactComponent as CircleCheck } from '../../../assets/images/icons/circle-check.svg';

import Overlay from '../../../components/Overlay';

import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';
import { OfertasServicosContext } from '../../../contexts/OfertasServicosContext';

import './styles.scss';

function OfertasProdutosList() {
  const { setProduto, renda, setTouchedRenda, produtos, loadingList, produto, setDiaRecebimento, completeListProduto, setLoadingList } =
    useContext(OfertasProdutosContext);
  
  const { servicos, setServicos, setServico, servico, completeListServico } = useContext(OfertasServicosContext);

  const simularPropostaProduto = (produto) => {
    if (renda > 0) {
      setProduto(produto);

      // Improvement 8338 - Dia recebimento passa a ser enviado dentro do array de cada produto, exceto para débito em conta que é enviado dentro de cada órgão
      if(produto.tipoCalculoVencimento === 5){
        if(produto?.orgao?.length === 1){
          setDiaRecebimento(produto?.orgao[0]?.diaRecebimento)
        }
      }
      else{
        setDiaRecebimento(produto?.diaRecebimento)
      }
      
    } else {
      setTouchedRenda(true);
    }
  };

  const simularPropostaServico = (servico) => {
    setServico(servico);
  };


  useEffect(() => {
    if(completeListProduto && completeListServico) {
      setLoadingList(false);
    }
  }, [completeListProduto, completeListServico])

  return (
    <Overlay active={loadingList}>
      
      <div className="produtos">
        {produtos.length > 0 &&
        <>
          <div className='wrapper-produtos-financeiros-title-icon'>
            <CircleCheck className="circle-icon-produtos-financeiros" />
            <span className='title-produtos-financeiros'>Produtos financeiros</span>
          </div>
          
          {produtos.map((item) => (
            <div
            key={item.id}
            >
            <CardProduto
              key={item.id}
              disabled={item.id === produto?.id}
              selecionarProduto={async () => {
                if (item?.id !== produto?.id) {
                  await setProduto(null);
                  setServico(null)
                  simularPropostaProduto(item);
                }
              }}
              produto={item}
            />
            </div>
          ))}
          </>
        }
      </div>
      {servicos.length > 0 && 
      <div className={`wrapper-card-crefaz-mais ${produtos.length > 0 && 'has-product'}`}>
        <CrefazMaisLogo
          className='crefaz-mais-logo'
        />
        {servicos.map((item) => (
            <div
              key={item.servicoId}>
              <CardServico
                key={item.servicoId}
                disabled={item?.servicoId === servico?.servicoId}
                selecionarServico={ async () => {
                  if(item?.servicoId !== servico?.servicoId) {
                    await setServico(null);
                    setProduto(null);                    
                    simularPropostaServico(item)
                  }
                }}
                setServicos={setServicos}
                servico={item}
              />
            </div>
          ))
        }
      </div>
      } 
    </Overlay>
  );
}

export default OfertasProdutosList;
