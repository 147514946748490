import api from './api';

export const listarTabelaJuros = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  filter = '',
  ativo = false,
  ordenacao = { id: 'id', desc: true },
}) => {
  const { data } = await api.post('/tabelajuros/tabela-juros-filtro', {
    pagina,
    quantidadePorPagina,
    filter,
    ativo,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data;
};

export const inserirTabelaJuros = async (dados) => {
  const { data } = await api.post('/tabelaJuros', dados);
  return data;
};

export const atualizarTabelaJuros = async (dados) => {
  const { data } = await api.put(`/tabelaJuros/${dados.id}`, dados);
  return data;
};

export const deletarTabelaJuros = async (id) => {
  const { data } = await api.delete(`/tabelaJuros/${id}`);
  return data;
};

export const buscarTabelaJuros = async (id) => {
  const { data } = await api.get(`/tabelajuros/${id}`);
  return data;
};

export const calcularValorPrestacao = async (dados) => {
  const { data } = await api.post('/tabelajuros/calcula-prestacao', dados);
  return data;
};

export const buscarContextoTabelaJuros = async () => {
  const response = await api.get('/contextos/tabelajuros');
  const data = response.data.data;
  return data;
};
