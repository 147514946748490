import React, { useContext } from 'react';
import moment from 'moment';
import Tooltip from '../../components/tooltip';

import ButtonSituacao from '../../components/ButtonSituacao';

import { ReactComponent as EditIcon } from '../../assets/images/icons/icon-editar.svg';

import { currencyFormat } from '../../utils/numberFormatter';
import { cpfFormatter } from '../../utils/textFormatter';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function AcompanhamentoServicoColumns() {
  const { checarPermissao } = useContext(ControleAcessoContext); 

  const columns = [
    {
      Header: 'Cadastro',
      accessor: 'dataCadastro',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      Header: 'Alteração',
      accessor: 'dataAlteracao',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) =>
        value && (
          <div style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY HH:mm')}
          </div>
        ),
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{cpfFormatter(value)}</div>
      ),
    },
    {
      Header: 'Cliente',
      accessor: 'cliente',
      style: {
        minWidth: 250,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{value}</div>
      ),
    },
    {
      Header: 'Produto',
      accessor: 'tipoProduto',
      style: {
        width: 150,
        minWidth: 150,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    // modalidade
    {
      Header: 'Matriz',
      accessor: 'matrizNome',
      style: {
        width: 180,
        minWidth: 180,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Login',
      accessor: 'agente',
      style: {
        width: 150,
        minWidth: 150,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Nome Usuário',
      accessor: 'nomeUsuario',
      style: {
        width: 180,
        minWidth: 180,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Valor',
      accessor: 'valorProposta',
      style: {
        width: 100,
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: 'Nº Proposta',
      accessor: 'id',
      style: {
        width: 125,
        minWidth: 125,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
  ];

  if (
    checarPermissao(
      'botao',
      'botao.acompanhamento.situacaoCrefazMais',
      'Visualizar',
    )
  ) {
    columns.splice(0, 0, {
      Header: 'Status',
      accessor: 'situacaoDescricao',
      style: {
        textAlign: 'center',
      },
      Cell: ({ row }) => {

        const linkDeDirecionamento = () => {
          return `/proposta-servico/${row.original.id}`;
        };

        const rowContent = () => {
          return (
            <ButtonSituacao
              disabled={!checarPermissao(
                'tela',
                'tela.propostaCrefazMais',
                'Visualizar',
              )}
              link={linkDeDirecionamento()}
              linkState={row.values.id}
              bgColor={row.original.situacaoCor}
            >
              <div className="btn-situacao">
                {row.original.situacaoDescricao === 'Aguard. Cadastro' && (
                    <EditIcon />
                  )}
                <div>{row.original.situacaoDescricao}</div>
              </div>
            </ButtonSituacao>
          );
        };

        return <div style={{ textAlign: 'center' }}>{rowContent()}</div>;
        
      },
      disableSortBy: true,
    });
  }

  return columns;
}

export default AcompanhamentoServicoColumns;
