import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Modal from '../components/Modal';
import {
  postAvancaServico,
  getOfertasServicos
} from '../services/propostaService';

export const OfertasServicosContext = createContext({});

function OfertasServicosProvider({ children }) {
  const params = useParams();
  const propostaId = params?.propostaId;
  const [loading, setLoading] = useState(false);
  const [servicos, setServicos] = useState([]);
  const [servico, setServico] = useState(null);
  const [completeListServico, setCompleteListServico] = useState(false);
  const [bloqueiaPreAnaliseServico, setBloqueiaPreAnaliseServico] = useState(false);

  const history = useHistory();

  const CarregarServicos = useCallback(async (propostaId) => {
    const data = await getOfertasServicos(propostaId);
    if (data?.success) {
      setServicos(data?.data?.servico || []);
      setBloqueiaPreAnaliseServico(data?.data?.bloqueiaPreAnalise)
      setCompleteListServico(true);
    } else if (data?.messages) {
      Modal('', data?.messages[0], 'warning', redirecionaAcompanhamentoProposta);
      setCompleteListServico(true);
    }

  }, []);

  const redirecionaAcompanhamentoProposta = () => {
    history.push('/acompanhamento-proposta');
  };

  const avancarServico = async (payload) => {
    const data = await postAvancaServico(payload);
    return data;
  };

  useEffect(() => {
    if (propostaId) {
      CarregarServicos(propostaId);
    }
  }, [CarregarServicos, propostaId]);

  return (
    <OfertasServicosContext.Provider
      value={{
        servicos,
        setServicos,
        servico,
        setServico,
        completeListServico,
        setCompleteListServico,
        loading,
        setLoading,
        avancarServico,
        propostaId,
        bloqueiaPreAnaliseServico
      }}
    >
      {children}
    </OfertasServicosContext.Provider>
  );
}

export default OfertasServicosProvider;
