import React from 'react';
import './styles.scss';

const SemiCircleChart = ({ value }) => {
  let fill = '';

  if (value < 300) {
    fill = '#FA8B3C';
  } else if (value < 700) {
    fill = '#FA8B3C';
  } else {
    fill = '#1AB394';
  }

  return (
    <div
      className="semi-donut"
      style={{ '--percentage': value / 10, '--fill': fill }}
    >
      {value}
    </div>
  );
};

export default SemiCircleChart;
