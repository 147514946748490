import React, { createContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import validaPhone from '../utils/validaPhone';
import validaCPF from '../utils/validaCpf';

import { atualizaPropostaServico, getContextoServico, getPropostaServico } from '../services/propostaService';

import {
  listarPaises,
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

export const PropostaServicoContext = createContext({
  tipoTelefone: [],
  telefoneExtra: [],
  estados: [],
  cidades: [],
  nacionalidades: [],
  fetchCidades: async (f) => f,
  fetchEndereco: async (f) => f,
  salvarPropostaServico: async (f) => f,
  setEnderecoUfId: (f) => f,
});

function PropostaServicoProvider({ children }) {
  const [propostaServico, setPropostaServico] = useState([]);
  const [contextoPropostaServico, setContextoPropostaServico] = useState([]);
  const [pacotes, setPacotes] = useState([]);
  const [pacoteSelecionado, setPacoteSelecionado] = useState(null);
  const [telefoneExtra, setTelefoneExtra] = useState([]);
  const [tipoTelefone, setTipoTelefone] = useState([]);
  const [liberaTelefoneExtraPreencher, setLiberaTelefoneExtraPreencher] =
    useState(false);
  const [cepInvalido, setCepInvalido] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [sexo, setSexo] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [listaMeiosPagamentos, setListaMeiosPagamentos] = useState([]);
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState(null);
  const [camposProposta, setCamposProposta] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loadingContext, setLoadingContext] = useState(false);
  const [loadingGetProposta, setLoadingGetProposta] = useState(false);
  const [enderecoUfId, setEnderecoUfId] = useState(0);
  

  const fetchContextoServico = useCallback(async () => {
    setShowOverlay(true);
    const response = await getContextoServico();
    if (response) {
      setEstadoCivil(response?.estadoCivil);
      setListaMeiosPagamentos(response?.meioPagamento);
      setSexo(response?.sexo);
      setTipoTelefone(response?.tipoTelefone);
      setPacotes(response?.pacote);
      setLoadingContext(true);
    }
  }, [])

  const fetchServico = useCallback(async (propostaServicoId) => {
    setShowOverlay(true);
    const response = await getPropostaServico(propostaServicoId);
    if (response) {

      if (response?.propostaServico?.operacao?.adicional) {
        response.propostaServico.operacao.pacoteId = response.propostaServico.operacao.pacotePaiId;

      }
      setPropostaServico(response?.propostaServico);
      setCamposProposta(response?.propostaServico?.itens);
      setEnderecoUfId(response?.propostaServico?.endereco?.ufId);
      setLoadingGetProposta(true);
    }
  }, [])

  const salvarPropostaServico = async (data) => {
    setShowOverlay(true);
    const response = await atualizaPropostaServico(data);
    if(response){
      setShowOverlay(false);
    }
    return response;
    };

  useEffect(() => {
    if (loadingContext && loadingGetProposta) {
      setShowOverlay(false);
    }

  }, [loadingContext, loadingGetProposta])


  const validationField = (value, name, label, type) => {
    let msgError = null;

    if (editableField(name)) {
      if (type && value) {
        value = value?.trim();
        switch (type) {
          case 'email':
            if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
              msgError = 'E-mail inválido';
            }
            break;
          case 'telefone':
            if (!validaPhone(value)) {
              msgError = 'Telefone inválido';
            }
            break;
          case 'cpf':
            if (!validaCPF(value?.replace(/\D/g, ''))) {
              msgError = 'CPF inválido';
            }
            break;
          case 'nome':
            if (!value.match(/(\w.+\s).+/)) {
              msgError = 'Nome incompleto';
            }

            if (value.match(/\d+/)) {
              msgError = 'Nome inválido';
            }
            break;
          default:
        }
      }

      if ((!type || type === 'nome') || msgError === '') {
        const campoObrigatorio = camposProposta?.find(
          (el) => el.tag === name && el.obrigatorio,
        );

        if (name === 'endereco.numero') {
          if (!!value && value <= 0) {
            msgError = 'O número deve ser maior do que 0';
          }
        }

        if (!value && campoObrigatorio) {
          msgError = 'Campo Obrigatório';
        }

        if (
          ((name === 'cliente.sexo' ||
            name === 'cliente.estadoCivil' ||
            name === 'bancario.conta' ||
            name === 'bancario.tipoConta' ||
            name === 'bancario.tempoConta' ||
            name === 'profissional.tempoEmpregoAtual') &&
            value === 0) ||
          (name === 'cliente.pep' && value === false)
        ) {
          msgError = null;
        }
      }

      if (msgError === null && name === 'cliente.rgEmissao') {
        const dataAtual = moment().format('YYYY-MM-DD');
        const dataRGEmissao = moment(value).format('YYYY-MM-DD');
        if (dataRGEmissao > dataAtual) {
          msgError =
            'A data de emissão do RG não pode ser superior a data atual';
        }
      }
    }

    return msgError;
  };

  const editableField = (name) => {
    const campoEditavel = camposProposta?.find((el) => el.tag === name);

    return campoEditavel;
  };

  const fetchListaCidades = async (id) => {
    const response = await listarCidades(id);
    setCidades(response);
  };

  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
  };

  const fetchUfs = async () => {
    const response = await listarUfs();
    return response;
  };

  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    return response;
  };

  const fetchPaises = async () => {
    const response = await listarPaises();
    setNacionalidades(response);
    return response;
  };


  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);

    if (response?.success) {
      return response.data;
    }
  };

  useEffect(() => {
    fetchEstados();
    fetchPaises();
  }, []);

    useEffect(() => {
      if (enderecoUfId) {
        fetchListaCidades(enderecoUfId);
      }
    }, [enderecoUfId]);

  return (
    <PropostaServicoContext.Provider
      value={{
        validationField,
        editableField,
        contextoPropostaServico,
        propostaServico,
        setContextoPropostaServico,
        setPropostaServico,
        pacoteSelecionado,
        setPacoteSelecionado,
        telefoneExtra,
        setTelefoneExtra,
        tipoTelefone,
        liberaTelefoneExtraPreencher,
        setLiberaTelefoneExtraPreencher,
        cepInvalido,
        setCepInvalido,
        estados,
        cidades,
        fetchEndereco,
        fetchListaCidades,
        fetchUfs,
        fetchCidades,
        fetchEstados,
        nacionalidades,
        meioPagamentoSelecionado,
        listaMeiosPagamentos,
        sexo,
        estadoCivil,
        setMeioPagamentoSelecionado,
        fetchContextoServico,
        pacotes,
        fetchServico,
        showOverlay,
        salvarPropostaServico,
        setEnderecoUfId
      }}
    >
      {children}
    </PropostaServicoContext.Provider>

  )
}

export default PropostaServicoProvider;