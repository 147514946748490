import React, { useRef, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { Form } from 'formik-antd';
import { Button } from 'antd';
import moment from 'moment';

import Overlay from '../../../components/Overlay';
import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Modal from '../../../components/Modal';
import Tooltip from '../../../components/tooltip';
import Tag from '../../../components/Tag';
import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';
import { DadosPacoteForm } from './DadosPacoteForm';
import { MeiosPagamento } from './MeiosPagamentoForm';
import { DadosContatoForm } from './DadosContatoForm';
import { DadosEnderecoForm } from './DadosEnderecoForm';
import { DadosMatrizForm } from './DadosMatrizForm';
import { DadosVendedorForm } from './DadosVendedorForm';
import { DadosPessoaisForm } from './DadosPessoaisForm';

import { ReactComponent as IconCadastrar } from '../../../assets/images/icons/cadastrar-proposta-credito.svg';
import { initialValues } from './initialValues';
import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';



function TooltipStatus({ children, color }) {
  return (
    <Tag className="tag-color" color={color}>
      <Tooltip color={color}>{children}</Tooltip>
    </Tag>
  );
}


function PropostaServicoForm({ propostaServicoId }) {

  const { checarPermissao } = useContext(ControleAcessoContext);

  const {
    propostaServico,
    fetchServico,
    fetchContextoServico,
    showOverlay,
    salvarPropostaServico,
    meioPagamentoSelecionado
  } = useContext(PropostaServicoContext)

  const history = useHistory();

  const [shouldNavigateToOfertasProdutos, setShouldNavigateToOfertasProdutos] = useState(false);

  const navigateToAcompanhamentoProposta = () => {
    history.push("/acompanhamento-proposta", { navigationCrefazMais: true });
  };

  const navigateToSelecaoOfertas = (propostaId) => {
    history.push(`/ofertas-produtos/${propostaId}`);
  };

  const containerInternoScrollRef = useRef(null);

  const formik = useFormik({
    initialValues,
  });

  const { setValues } = formik;

  useEffect(() => {
    fetchContextoServico()
    fetchServico(propostaServicoId)
  }, [propostaServicoId])

  useEffect(() => {
    if (propostaServico) {
      setValues({
        ...propostaServico,
      });
    }
  }, [propostaServico, setValues]);


  useEffect(() => {
    if (shouldNavigateToOfertasProdutos && typeof shouldNavigateToOfertasProdutos === "boolean") {
      handleEnvioFormulario();
    }
  }, [shouldNavigateToOfertasProdutos]);

  const handleEnvioFormulario = async () => {
    const errors = await formik
      .validateForm()
      .then((errors) => formik.setTouched({ ...errors }, true));

    formik.setTouched(errors, true);

    if(errors) {
      setShouldNavigateToOfertasProdutos(false);
    }

    if (!Object.keys(errors).length) {
      const changedData = JSON.parse(JSON.stringify(formik.values));

      //Tratamento de dados - remocao de caracteres

      changedData.cliente.rgEmissao = moment(changedData.cliente.rgEmissao).format('YYYY-MM-DD')
      changedData.operacao.meioPagamentoId = meioPagamentoSelecionado
      

      changedData.contato.telefone = changedData.contato.telefone?.replace(/\D/g, '');
      
      if (
        changedData.contato.telefoneExtra &&
        changedData.contato.telefoneExtra.length > 0
      ) {
        changedData.contato.telefoneExtra =
          changedData.contato.telefoneExtra.map(
            (telefoneExtra) => ({
              ...telefoneExtra,
              telefone: telefoneExtra.telefone.replace(/\D/g, ''),
            }),
          );
      } else {
        changedData.contato.telefoneExtra = [];
      }
      changedData.unidade.cpfVendedor = changedData.unidade.cpfVendedor?.replace(/\D/g, '');

      changedData.unidade.celularVendedor = changedData.unidade.celularVendedor?.replace(/\D/g, '');
      changedData.endereco.cep = changedData.endereco.cep?.replace(/\D/g,'',);


      const allPhones = [
        { tipo: changedData?.contato?.tipoTelefone },
        ...changedData?.contato?.telefoneExtra.map((extra) => ({ tipo: extra.tipoTelefone })),
      ];

      const hasMobilePhone = allPhones.some((phone) => phone.tipo === 1);
  
      if (!hasMobilePhone) {
        Modal('', 'Necessário informar ao menos um telefone do tipo "Celular".', 'warning');
      }

      //remoção de itens do formik não utilizados

      delete changedData.itens; 
      delete changedData.operacao.pacotePaiId; 
      delete changedData.operacao.pacoteNome; 
      delete changedData.operacao.pacotePaiNome; 

      if(hasMobilePhone){
        const response = await salvarPropostaServico(changedData);
        if (response.success) {
          Modal(
            '',
            'Proposta enviada com sucesso!',
            'success',
            shouldNavigateToOfertasProdutos ? navigateToSelecaoOfertas(propostaServico?.operacao?.propostaId) : navigateToAcompanhamentoProposta,
          );
        } else {
          const usuarioNaoCadastrado = response.data.errors === "Usuário digitador não identificado. Entre em contato com o cadastro."
          Modal('', response.data.errors, 'warning', usuarioNaoCadastrado && navigateToAcompanhamentoProposta );
        }
      }
    }
    else {
      Modal('', 'Existem campos obrigatórios que ainda não foram preenchidos. Favor verificar e tentar novamente.', 'warning');
    }
  }


  return (
    <>
      <FormikProvider value={formik}>
        <Overlay active={showOverlay} className="overlay-loading">
          <Card
            title={<Title label="Proposta Crefaz Mais" />}
            rightBar={
              <>
                <div className="d-flex justify-content-end align-items-center">
                  <TooltipStatus
                    color={formik.values.situacaoCor}
                  >
                    {formik.values.situacaoDescricao}
                  </TooltipStatus>
                </div>
              </>
            }
          >
            <Form layout="vertical" autoComplete="off" disabled>
              <div
                className="container-scroll-form"
              >
                <div
                  className="container-interno-scroll-form"
                  ref={containerInternoScrollRef}
                >
                  <DadosPacoteForm formik={formik} />
                  <MeiosPagamento formik={formik} />
                  <DadosPessoaisForm formik={formik} />
                  <DadosContatoForm formik={formik} />
                  <DadosEnderecoForm formik={formik} />
                  <DadosMatrizForm formik={formik} />
                  <DadosVendedorForm formik={formik} />
                  <div className='wrapper-buttons-cadastrar'>

                    {propostaServico?.primeiroPropostaServico && 
                    checarPermissao(
                      'botao',
                      'botao.propostaCrefazMais.cadastrarSelecionarOutroProduto',
                      'Visualizar',
                    ) &&
                      <div className="form-row pt-3">
                        <div className="col-lg-12 text-right">
                          <Button
                            className="btn-blue btn-cadastrar-outro-produto"
                            onClick={() => {
                              setShouldNavigateToOfertasProdutos(true);
                            }}
                            disabled={propostaServico.situacaoDescricao !== "Aguard. Cadastro" || !checarPermissao(
                              'botao',
                              'botao.propostaCrefazMais.cadastrarSelecionarOutroProduto',
                              'Editar',
                            )}
                          >
                            <IconCadastrar /> CADASTRAR E SELECIONAR OUTRO PRODUTO
                          </Button>
                        </div>
                      </div>
                    }
                    {checarPermissao(
                      'botao',
                      'botao.propostaCrefazMais.cadastrarCrefazMais',
                      'Visualizar',
                    ) &&
                      <div className="form-row pt-3">
                        <div className="col-lg-12 text-right">
                          <Button
                            className="btn-cadastrar-proposta-crefaz-mais"
                            type="primary"
                            onClick={() => {
                              setShouldNavigateToOfertasProdutos(false);
                              handleEnvioFormulario();
                            }}
                            disabled={propostaServico.situacaoDescricao !== "Aguard. Cadastro" || !checarPermissao(
                              'botao',
                              'botao.propostaCrefazMais.cadastrarCrefazMais',
                              'Editar',
                            )}

                          >
                            <IconCadastrar /> CADASTRAR
                          </Button>
                        </div>
                      </div>
                    } 
                  </div>
                </div>
              </div>
            </Form>
          </Card>
        </Overlay>
      </FormikProvider>
    </>
  )
}

export default PropostaServicoForm;