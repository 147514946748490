import React, { Fragment, useState, useEffect, useContext } from 'react';

import { Field } from 'formik';
import { Form } from 'formik-antd';
import Input from '../../../components/SimpleInput';
import InputMask from 'react-text-mask';

import { cpfMask } from '../../../utils/masks';
import InputPhone from '../../../components/InputPhone';
import validaPhone from '../../../utils/validaPhone';
import validaCPF from '../../../utils/validaCpf';

import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';

function DadosVendedorForm({ formik }) {
  const { setFieldTouched, setFieldValue, touched, errors} = formik;
  const { validationField, editableField } = useContext(PropostaServicoContext);

  const [statusCPF, setStatusCPF] = useState('');


  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Informações do Vendedor</div>
      </div>

      <div className="form-row">
        <div className="col-md-7 col-lg-7 col-xl-7">
          <Form.Item
            name="unidade.nomeVendedor"
            label="Nome do vendedor"
            validate={(value) =>
              validationField(value, 'unidade.nomeVendedor', 'Nome do vendedor')
            }
            hasFeedback
          >
            <Input
              name="unidade.nomeVendedor"
              disabled={!editableField('unidade.nomeVendedor')}
              value={formik?.values?.unidade?.nomeVendedor}
              onBlur={(e) => {
                setFieldValue('unidade.nomeVendedor', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        <div className="col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="unidade.cpfVendedor"
            label="CPF"
            validateStatus={touched?.unidade?.cpfVendedor && statusCPF}
            validate={(value) => {
              let msgError = null; 

              if (value && !validaCPF(value?.replace(/\D/g, ''))) {
                msgError = 'CPF inválido';
                setStatusCPF('warning');

              } else {
                setStatusCPF('');
              }
              return msgError;
            }}
            help={touched?.unidade?.cpfVendedor && errors?.unidade?.cpfVendedor}
            hasFeedback
          >
            <Field name="unidade.cpfVendedor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="000.000.000-00"
                  mask={cpfMask}
                  showMask={false}
                  disabled={ !editableField('unidade.cpfVendedor')}
                  onBlur={() => setFieldTouched('unidade.cpfVendedor', true)}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="unidade.celularVendedor"
            label="Celular vendedor"
            validate={(value) => {
              let error;
              if (!value) {
                error = 'Campo Obrigatório';
              } else if (value && !validaPhone(value)) {
                error = 'Telefone inválido';
              }
              return error;
            }}
            hasFeedback
          >
            <Field name="unidade.celularVendedor">
              {({ field }) => (
                <InputPhone
                  {...field}
                  className="ant-input"
                  placeholder="Ex.: (00) 0000-0000"
                  disabled={!editableField('unidade.celularVendedor')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosVendedorForm };
