import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './privateRoute';

import Login from '../pages/Login';
import NovaSenha from '../pages/NovaSenha';
import RedefinirSenha from '../pages/RedefinirSenha';
import DashBoard from '../pages/DashBoard';
import Simulacao from '../pages/Simulacao';
import Parametro from '../pages/Parametro';
import Tarifas from '../pages/Tarifas';
import FilaCredito from '../pages/FilaCredito';
import PreAnalise from '../pages/PreAnalise';
import OfertasProdutos from '../pages/OfertasProdutos';
import Correspondente from '../pages/Correspondente';
import AcompanhamentoProposta from '../pages/AcompanhamentoProposta';
import AcompanhamentoCredenciamento from '../pages/AcompanhamentoCredenciamento';
import AnaliseProposta from '../pages/AnaliseProposta';
import TabelaJuros from '../pages/TabelaJuros';
import Aprovacao from '../pages/Aprovacao';
import RelatorioEsteiraCredito from '../pages/RelatorioEsteiraCredito';
import RelatorioAnaliticoProposta from '../pages/RelatorioAnaliticoProposta';
import RelatorioControleProposta from '../pages/RelatorioControleProposta'
import RelatoriosProposta from '../pages/RelatoriosProposta';
import ImpressaoProposta from '../pages/ImpressaoProposta';
import ParametroDeProduto from '../pages/ParametroDeProdutos';
import AnaliseMesaCredito from '../pages/AnaliseMesaCredito';
import PropostaCredito from '../pages/PropostaCredito';
import CadastroPessoa from '../pages/CadastroPessoa';
import GerenciamentoBordero from '../pages/GerenciamentoBordero';
import BlocklistCPF from '../pages/BlocklistCPF';
import BlocklistTelefone from '../pages/BlocklistTelefone';
import BlocklistUnidadeConsumidora from '../pages/BlocklistUnidadeConsumidora';
import HistoricoGravacoes from '../pages/HistoricoGravacoes';
import RelatorioComissao from '../pages/RelatorioComissao';
import NotasFiscais from '../pages/NotasFiscais';
import ProcessamentoCrivo from '../pages/ProcessamentoCrivo';
import PdfEsteiraCredito from '../pages/RelatorioEsteiraCredito/pdf';
import HistoricoAcoesProposta from '../pages/HistoricoAcoesProposta';
import PdfAcompanhamentoCredito from '../pages/AcompanhamentoProposta/pdf';
import PdfRelatorioAnalitico from '../pages/RelatorioAnaliticoProposta/RelatorioAnalitico/pdf';
import PdfComissao from '../pages/RelatorioComissao/pdf';
import DadosCredenciado from '../pages/DadosCredenciado';
import Credenciamento from '../pages/Credenciamento';
import CadastroUsuarios from '../pages/CadastroUsuarios';
import Usuarios from '../pages/Usuarios';
import Hierarquias from '../pages/Hierarquias';
import Logout from '../pages/Logout';
import SistemaIndisponivel from '../pages/SistemaIndisponivel';
import ConfirmacaoEmail from '../pages/Login/ConfirmacaoEmail';
import EscolhaEnvioRedefinicao from '../pages/Login/EscolhaEnvioRedefinicao';
import Integracoes from '../pages/Integracoes';
import PropostaServico from '../pages/PropostaServico';

function Routes() {
  return (
    <Fragment>
      <Switch>
        <PrivateRoute exact path="/" component={DashBoard} />
        <PrivateRoute path="/tarifas" component={Tarifas} />
        <PrivateRoute path="/simulacao" component={Simulacao} />
        <PrivateRoute path="/parametro" component={Parametro} />
        <PrivateRoute path="/filaCredito" component={FilaCredito} />
        <PrivateRoute path="/analise-proposta" component={AnaliseProposta} />
        <PrivateRoute path="/pre-analise/:propostaId?" component={PreAnalise} />
        <PrivateRoute
          path="/ofertas-produtos/:propostaId?"
          component={OfertasProdutos}
        />
        <PrivateRoute
          path="/gerenciamento-bordero"
          component={GerenciamentoBordero}
        />
        <PrivateRoute
          path="/acompanhamento-credenciamento"
          component={AcompanhamentoCredenciamento}
        />
        <PrivateRoute
          path="/impressao-proposta/:propostaId?"
          component={ImpressaoProposta}
        />
        <PrivateRoute
          path="/proposta-credito/:propostaId"
          component={PropostaCredito}
        />
        <PrivateRoute
          path="/relatorios-proposta"
          component={RelatoriosProposta}
        />
        <PrivateRoute
          path="/correspondente-credenciamento"
          component={Correspondente}
        />
        <PrivateRoute
          path="/acompanhamento-proposta"
          component={AcompanhamentoProposta}
        />
        <PrivateRoute path="/integracoes" component={Integracoes} />

        <PrivateRoute
          exact
          path="/cadastro-pessoa"
          component={CadastroPessoa}
        />
        <PrivateRoute
          path="/cadastro-pessoa/:idPessoa"
          component={CadastroPessoa}
        />
        <PrivateRoute
          path="/relatorio-esteira-credito"
          component={RelatorioEsteiraCredito}
        />
        <PrivateRoute
          path="/relatorio-analitico"
          component={RelatorioAnaliticoProposta}
        />
        <PrivateRoute
          path="/relatorio-controle-proposta"
          component={RelatorioControleProposta}
        />
        <PrivateRoute path="/produtos" component={ParametroDeProduto} />
        <PrivateRoute path="/tabela-juros" component={TabelaJuros} />
        <PrivateRoute
          path="/analise-mesa-credito/:propostaId"
          component={AnaliseMesaCredito}
        />

        <PrivateRoute path="/aprovacao-correspondente" component={Aprovacao} />

        <PrivateRoute path="/blocklist-cpf" component={BlocklistCPF} />
        <PrivateRoute
          path="/blocklist-telefone"
          component={BlocklistTelefone}
        />
        <PrivateRoute
          path="/blocklist-unidade-consumidora"
          component={BlocklistUnidadeConsumidora}
        />

        <PrivateRoute
          path="/historico-gravacoes"
          component={HistoricoGravacoes}
        />

        <PrivateRoute
          path="/relatorio-comissao"
          component={RelatorioComissao}
        />

        <PrivateRoute path="/notas-fiscais" component={NotasFiscais} />

        <Route path="/login" component={Login} />
        <Route path="/nova-senha" component={NovaSenha} />
        <Route path="/redefinir-senha" component={RedefinirSenha} />

        <Route
          path="/processamento-crivo/:propostaId"
          component={ProcessamentoCrivo}
        />

        <Route path="/relatorio-esteira-pdf" component={PdfEsteiraCredito} />

        <Route
          path="/historico-acoes/:propostaId"
          component={HistoricoAcoesProposta}
        />

        <Route
          path="/relatorio-acompanhamento-pdf"
          component={PdfAcompanhamentoCredito}
        />

        <Route
          path="/visao-analitica-pdf"
          component={PdfRelatorioAnalitico}
        />

        <Route path="/relatorio-comissao-pdf" component={PdfComissao} />
        <PrivateRoute
          exact
          path="/dados-credenciado"
          component={DadosCredenciado}
        />
        <PrivateRoute
          path="/dados-credenciado/:userId/:action?"
          component={DadosCredenciado}
        />
        <PrivateRoute path="/credenciamento" component={Credenciamento} />
        <PrivateRoute
          exact
          path="/cadastro-usuarios"
          component={CadastroUsuarios}
        />
        <PrivateRoute
          path="/cadastro-usuarios/:userId"
          component={CadastroUsuarios}
        />
        <PrivateRoute path="/usuarios" component={Usuarios} />

        <PrivateRoute path="/hierarquias" component={Hierarquias} />

        <PrivateRoute path="/logout" component={Logout} />

        <Route
          path="/confirmacao-redefinicao"
          component={ConfirmacaoEmail}
        />

        <Route
          path="/escolha-envio"
          component={EscolhaEnvioRedefinicao}
        />

        <Route path="/sistema-indisponivel" component={SistemaIndisponivel} />

        <PrivateRoute
          path="/proposta-servico/:propostaId"
          component={PropostaServico}
        />


      </Switch>
    </Fragment>
  );
}

export default Routes;
