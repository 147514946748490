import React, { Fragment, useContext, useState } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';
import DatePicker from '../../../components/DatePicker';

import InputMask from 'react-text-mask';

import moment from 'moment';

import SelectSearch from '../../../components/SearchSelect';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';

import { cpfMask } from '../../../utils/masks';

const { Option } = Select;


function DadosPessoaisForm({ formik }) {
  const { setFieldTouched, setFieldValue, values, touched, errors} = formik;

  const {
    estados,
    sexo,
    estadoCivil,
    validationField,
    editableField,
  } = useContext(PropostaServicoContext);

  const [statusNomeCliente, setStatusNomeCliente] = useState('');

  const handleNascimento = (e) => {
    setFieldTouched('cliente.nascimento');
  };

  const handleRgEmissao = (e) => {
    setFieldTouched('cliente.rgEmissao');
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  const disabledEmissao = (current) => {
    return (
      current &&
      (current > moment().endOf('day') ||
        current < moment(values.cliente.nascimento))
    );
  };

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados Pessoais do Cliente</div>
      </div>

      <div className="form-row">
        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="cliente.cpf"
            label="CPF"
            validate={(value) => validationField(value, 'cliente.cpf', 'CPF')}
          >
            <Field name="cliente.cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="000.000.000-00"
                  mask={cpfMask}
                  disabled={!editableField('cliente.cpf') }
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-5 col-md-5 col-lg-5 col-xl-5">
          <Form.Item
            name="cliente.nome"
            label="Nome do Cliente"
            validate={(value) => {
              let msgError = null;
              if (!value) {
                msgError = 'Campo obrigatório';
                setStatusNomeCliente('error');
              } else if (!value.match(/(\w.+\s).+/)) {
                setStatusNomeCliente('warning');
                msgError = 'Nome incompleto';
              } else {
                setStatusNomeCliente('');
              }
              return msgError;
            }}
            validateStatus={touched?.cliente?.nome && statusNomeCliente}
            help={touched?.cliente?.nome && errors?.cliente?.nome}
            hasFeedback
          >
            <Input
              name="cliente.nome"
              value={formik?.values?.cliente?.nome}
              placeholder="Nome completo"
              disabled={!editableField('cliente.nome')}
              onBlur={(e) => {
                setFieldTouched('cliente.nome', true)
                setFieldValue('cliente.nome', String(e.target.value).toUpperCase().trim())}
              }
              uppercase={true}
              formatWhitespace={true}
            />
          </Form.Item>
        </div>

        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="cliente.nascimento"
            label="Data de Nascimento"
            validate={(value) =>
              validationField(value, 'cliente.nascimento', 'Data de Nascimento')
            }
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.nascimento"
              placeholder="00/00/0000"
              disabledDate={disabledDate}
              onBlur={(e) => handleNascimento(e)}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('cliente.nascimento')}
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
            />
          </Form.Item>
        </div>

        <div className="col-xs-3 col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="cliente.rg"
            label="Número do RG/CNH"
            validate={(value) =>
              validationField(value, 'cliente.rg', 'número do RG')
            }
            hasFeedback
          >
            <Input
              name="cliente.rg"
              placeholder="00000-00"
              disabled={!editableField('cliente.rg')}
              value={formik?.values?.cliente?.rg}
              onBlur={(e) => setFieldValue('cliente.rg', e.target.value)}
              maxLength={14}
            />
          </Form.Item>
        </div>

        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="cliente.rgEmissor"
            label="Órgão Emissor"
            validate={(value) =>
              validationField(value, 'cliente.rgEmissor', 'emissor do RG')
            }
            hasFeedback
          >
            <Input
              name="cliente.rgEmissor"
              disabled={!editableField('cliente.rgEmissor')
              }
              value={formik?.values?.cliente?.rgEmissor}
              onBlur={(e) => setFieldValue('cliente.rgEmissor', e.target.value)}
            />
          </Form.Item>
        </div>

        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="cliente.rgUfId"
            label="UF de Emissão"
            validate={(value) =>
              validationField(value, 'cliente.rgUfId', 'UF do RG')
            }
            hasFeedback
          >
            <Field name="cliente.rgUfId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('cliente.rgUfId', value)}
                  disabled={!editableField('cliente.rgUfId')}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                  suffixIcon={<IconArrowSelect />}
                  optionFilterProp="children"
                >
                  {estados?.map((uf) => (
                    <Option key={uf.id} value={uf.id}>
                      {uf.uf}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-2 col-md-2 col-lg-2 col-xl-2">
          <Form.Item
            name="cliente.rgEmissao"
            label={<label className='label-data-emissao'>Data de Emissão</label>}
            validate={(value) =>
              validationField(value, 'cliente.rgEmissao', 'Data de Emissão')
            }
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.rgEmissao"
              disabled={!editableField('cliente.rgEmissao')}
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
              placeholder="00/00/0000"
              disabledDate={disabledEmissao}
              onBlur={(e) => handleRgEmissao(e)}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </div>

        <div className="col-xs-3 col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="cliente.sexo"
            label="Sexo"
            validate={(value) => validationField(value, 'cliente.sexo', 'Sexo')}
            hasFeedback
          >
            <Field name="cliente.sexo">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('cliente.sexo', value)}
                  disabled={!editableField('cliente.sexo')}
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                >
                  {sexo?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-3 col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="cliente.estadoCivil"
            label="Estado Civil"
            validate={(value) =>
              validationField(value, 'cliente.estadoCivil', 'Estado Civil')
            }
            hasFeedback
          >
            <Field name="cliente.estadoCivil">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    setFieldValue('cliente.estadoCivil', value)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={!editableField('cliente.estadoCivil')}
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {estadoCivil?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        {estadoCivil &&
          estadoCivil.length > 0 &&
          formik.values.cliente?.estadoCivil !== null &&
          estadoCivil[formik.values.cliente?.estadoCivil]?.exigeConjuge && (
            <div className="col-xs-6 col-md-6 col-lg-6 col-xl-6">
              <Form.Item
                name="cliente.nomeConjuge"
                label="Nome do Cônjuge"
                validate={(value) => {
                  let msgError;
                  if (
                    !value &&
                    estadoCivil[formik.values.cliente?.estadoCivil]
                      ?.exigeConjuge
                  ) {
                    msgError = 'Campo Obrigatório';
                  }
                  return msgError;
                }}
                hasFeedback
              >
                <Input
                  name="cliente.nomeConjuge"
                  disabled={!editableField('cliente.nomeConjuge')}
                  placeholder="Nome completo"
                  value={formik?.values?.cliente?.nomeConjuge}
                  onBlur={(e) =>
                    setFieldValue('cliente.nomeConjuge', e.target.value)
                  }
                />
              </Form.Item>
            </div>
          )}
      </div>
    </Fragment>
  );
}

export { DadosPessoaisForm };
