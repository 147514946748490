import React, { useContext, useState } from 'react';
import swal from 'sweetalert';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/CustomTable';
import Icon from '../../../components/Icon';
import { ReactComponent as IconTrash } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen.svg';
import {Modal} from 'antd';
import { Upload } from 'antd';
import {
  percentualFormat,
  currencyFormat,
} from '../../../utils/numberFormatter';

import ValoresFixosForm from './form';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';
import Overlay from '../../../components/Overlay';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import Papa from 'papaparse';
import ModalSwall from '../../../components/Modal';

function TabelaValoresFixos() {
  const [visibleModalErrorCsv, setVisibleModalErrorCsv] = useState(false);
  const [visibleModalErrorCsvFormat, setVisibleModalErrorCsvFormat] = useState(false);
  const {
    produtoId,
    carenciaMedia,
    tabelaJurosValores,
    setTabelaJurosValores,
    setTableFormData,
    showOverlayTabelaJuros,
    setShowOverlayTabelaJuros,
    calcularValorParcela,
    tratarNovosValoresFixos
  } = useContext(TabelaJurosContext);

  const removeItem = (pItem) => {
    swal({
      title: '',
      text: 'Tem certeza que deseja excluir o item?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then((willDelete) => {
      if (willDelete) {
        setTabelaJurosValores(
          tabelaJurosValores.filter((item) => item !== pItem),
        );
        swal('Item excluído com sucesso', { icon: 'success' });
      }
    });
  };

  const columns = [
    {
      Header: 'Plano',
      accessor: 'plano',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Taxa',
      accessor: 'juros',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{percentualFormat(value, 4)}</div>
      ),
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: 'Valor Parcela',
      accessor: 'prestacao',
      disableSortBy: true,
      style: {
        minWidth: 80,
        cursor: 'default',
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      disableSortBy: true,
      style: {
        minWidth: 60,
        width: 60,
        textAlign: 'center',
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center' }}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              removeItem(row.original);
            }}
          >
            <IconTrash width="15" />
          </a>

          <span className="m-1"></span>

          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setTableFormData({
                ...row?.original,
                oldPlano: row?.original?.plano,
                oldValor: row?.original?.valor,
                jurosFloat: row?.original?.juros,
                edit: true,
              });
            }}
          >
            <IconEdit width="15" />
          </a>
        </div>
      ),
    },
  ];

  const beforeUpload = (file) => {
    if (file.type !== 'text/csv') {
      setVisibleModalErrorCsv(true);
    } else {
      handleFileUpload(file);
    }
    return false;
  };

  const insereParcelas = async (row) => {
    const data = {
      id: null,
      tabelaJurosId: 0,
      valor: parseFloat(row.Valor.replace('.', '').replace(',', '.')),
      plano: Number(row.Plano),
      edit: false,
      oldValor: 0,
      oldPlano: 0,
      prazoInicial: 0,
      prazoFinal: 0,
      juros: row.Taxa,
      jurosFloat: parseFloat(row.Taxa.toString().replace(',', '.')),
    };
  
    try {
      const response = await calcularValorParcela({
        valor: parseFloat(row.Valor.replace('.', '').replace(',', '.')),
        carencia: Number(carenciaMedia),
        plano: Number(row.Plano),
        juros: parseFloat(row.Taxa.toString().replace(',', '.')),
        produtoId,
      });
  
      const lista = { ...data, prestacao: response };
      return lista; 
    } catch (error) {
      setShowOverlayTabelaJuros(false);
      return null; 
    }
  };

  const handleFileUpload = (file) => {
    Papa.parse(file, {
      header: true, // Define se a primeira linha do CSV é um cabeçalho
      skipEmptyLines: true,
      complete: async (result) => {
        setShowOverlayTabelaJuros(true);
        const headers = result.meta.fields;
        // Verificações de cabeçalho e conteúdo
        const isValoresFixos = JSON.stringify(headers) === JSON.stringify(["Plano", "Taxa", "Valor"]);
  
        if (isValoresFixos) {
          if(produtoId){
            let newArray = [];
            const promises = result.data.map(async (row) => {
              const newList = await insereParcelas(row);
              newArray = tratarNovosValoresFixos(newList, newArray);
            });
    
            await Promise.all(promises);
            
            setTabelaJurosValores(newArray);
            setShowOverlayTabelaJuros(false);
          }else{
            setShowOverlayTabelaJuros(false);
            ModalSwall('', 'É necessário selecionar um produto antes', 'warning');
          }
         
        } else {
          setVisibleModalErrorCsvFormat(true);
          setShowOverlayTabelaJuros(false);
        }
      },
      error: () => {
        setVisibleModalErrorCsvFormat(true);
      },
    });
  };
  return (
    <Overlay active={showOverlayTabelaJuros}>
      <Card 
        title={<Title label="Tabela de Valores Fixos" />} 
        className="flex"
        rightBar={
          <Upload
            multiple={false}
            beforeUpload={beforeUpload}
            showUploadList={false}
          >
            <Button variant='blue'>
              <Icon variant="upload" />
              <span className="text-uppercase ml-1">CSV</span>
            </Button>
          </Upload>
        }
        >
        <div>
          <ValoresFixosForm />
        </div>

        <Table columns={columns} data={tabelaJurosValores} pagination />
      </Card>
      <Modal
        visible={visibleModalErrorCsvFormat}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalErrorCsvFormat(false)}}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Erro ao anexar o arquivo CSV. Verifique o arquivo e tente novamente.</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {setVisibleModalErrorCsvFormat(false)}}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        visible={visibleModalErrorCsv}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {setVisibleModalErrorCsv(false)}}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Somente podem ser enviados arquivos no formato "CSV".</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {setVisibleModalErrorCsv(false)}}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
    </Overlay>
  );
}

export default TabelaValoresFixos;
