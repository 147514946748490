import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/expanded-arrow.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/done_outlined.svg';
import { ReactComponent as DotListIcon } from '../../assets/images/icons/dot-list.svg';
import { ReactComponent as AddIcon } from '../../assets/images/icons/plus-icon.svg';
import { Radio} from 'antd'
import './styles.scss';


function CardPacoteServico({ id, pacote, isSelected, onClick, onRadioChange }) {

  const [radiusValue, setRadiusValue] = useState(null);
  const [currentValue, setCurrentValue] = useState(pacote.valor);

  const onChangeRadius = (e) => {
    const value = e.target.value;
    setRadiusValue(value);

    if (isSelected) {
      if (value === true) {
        setCurrentValue(pacote.pacoteAdicional[0]?.valor); //garantir de que só vai retornar um pacote adicional, por isso posição 0
      } else {
        setCurrentValue(pacote.valor);
      }
      onRadioChange(value);
    }
  };

  return (
    <div  
      className={`card-pacote-servico ${isSelected && 'selected'} ${!pacote.melhorEscolha && 'not-best-choice'}`}
      onClick={() => {
        onClick(id); 
        setRadiusValue(null);
        setCurrentValue(pacote.valor);
      }}
    >
        { pacote.melhorEscolha && 
          <div className='indication'>
            <span className='info'>Melhor escolha</span>
            <CheckIcon/>
          </div>
        }
        
        <div className='infos-pacote'>
            <span className='title'>{pacote.nome}</span>
            <span className='sub-title'>{pacote.descricaoDependentes}</span>
            <div className='infos-price'>
                <span className='cifrao'>R$</span>
                <span className='price'>{currentValue.toFixed(2).replace('.', ',')}</span>
            </div>
            <span className='period'>por mês</span>
        </div>
        <div  className={`infos-expanded ${isSelected && 'expanded-list'} ${pacote?.pacoteAdicional && isSelected  && 'border-rad'}`}>
          <span className='title'>Incluso no pacote</span>
          <ArrowIcon className={isSelected ? 'rotated' : ''}/>
        </div>

        {isSelected && (
          <div className={`benefits-list ${pacote?.pacoteAdicional && isSelected && 'border-rad'}`}>
            {pacote.incluso.map((item, index) => (
              <div className='item-list' key={index}>
                <DotListIcon/>
                <span className='description' >
                  {item}
                </span>
              </div>
            ))}
          </div>
        )}

        {isSelected && pacote?.pacoteAdicional && pacote?.pacoteAdicional[0]?.descricaoPacoteAdicional && (
          <div className='add-list'>
            <span className='font-bold'>Aumentar</span>
            <div className='infos-add-list'>
              <span className='font-normal'>para <b>{pacote?.pacoteAdicional[0]?.descricaoPacoteAdicional.aumento}</b> {pacote?.pacoteAdicional[0]?.descricaoPacoteAdicional.nome}?</span>
            </div>
            
            <div className='price-add'>
              <AddIcon/>
              <span className='price'>R${pacote?.pacoteAdicional[0]?.descricaoPacoteAdicional.valor.toFixed(2).replace('.', ',')}</span>
            </div>
             <div className='bloco-radius-buttons'>
                  <Radio.Group onChange={onChangeRadius} value={radiusValue}>
                      <Radio value={true}>
                          Sim
                      </Radio>
                      <Radio value={false}>
                          Não
                      </Radio>
                  </Radio.Group>
                
              </div>
          </div>
        )}
      
    </div>
  );
}

export default CardPacoteServico;
