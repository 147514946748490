import React, { createContext, useState, useEffect, useCallback, useRef} from 'react';
import moment from 'moment';

import {
  exportarPropostasAcompanhamentoCSV,
  imprimirPdfAcompanhamento,
  listarAcompanhamentoPropostas,
  listarAcompanhamentoServico,
  getContextoProposta,
  getContextoServico,
  putModalidade,
  avancarPropostaAssinatura,
  exportarPropostasServicoAcompanhamentoCSV,
} from '../services/acompanhamentopropostaService';

import {
  reanaliseProposta,
  getHistoricoReanalise,
  getLinkAnexoHistoricoReanalise
} from '../services/propostaService';
import { carregarImagem } from '../services/imagemService';
import { getImagem } from '../services/imagemService';
import Modal from '../components/Modal';
import { useFormik } from 'formik';

export const AcompanhamentoPropostaContext = createContext({});

const initialFilters = {
  filtroDinamico: null,
  filtroEtapa: null,
  filtroProduto: null,
  filtroTiposData: null,
  filtroDataFinal: moment(),
  filtroDataInicial: moment().add(-1, 'day'),
  filtroData: [moment().add(-1, 'day'), moment()],
};

function AcompanhamentoPropostaProvider({ children }) {

  //Variáveis tabela acompanhamento de produtos financeiros 
  const [propostas, setPropostas] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState(null)
  const [contexto, setContexto] = useState({});
  const [tiposData, setTiposData] = useState([]);
  const [tempoRefresh, setTempoRefresh] = useState();
  const [updateList, setUpdateList] = useState(false);


  //Variáveis tabela acompanhamento de serviços crefaz mais
  const [isPropostasFinanceiras, setIsPropostasFinanceiras] = useState(true);
  const [resetPage, setResetPage] = useState(false);
  const [liberaCrefazMais, setLiberaCrefazMais] = useState(false);
  const [propostasServico, setPropostasServico] = useState([]);
  const [totalServico, setTotalServico] = useState(0);
  const [pageCountServico, setPageCountServico] = useState(0);
  const [pageIndexServico, setPageIndexServico] = useState(false);
  const [loadingServico, setLoadingServico] = useState(false);
  const [filtersServico, setFiltersServico] = useState(initialFilters);
  const [searchServico, setSearchServico] = useState(null)
  const [contextoServico, setContextoServico] = useState({});
  const [tiposDataServico, setTiposDataServico] = useState([]);
  const [updateListServico, setUpdateListServico] = useState(false);
  const formik = useFormik({
    initialValues: initialFilters,
  });

  const formikServico = useFormik({
    initialValues: initialFilters,
  });

  const { values } = isPropostasFinanceiras ? formik : formikServico;
  const prevIsPropostasFinanceiras = useRef(isPropostasFinanceiras);

  const fetchPropostas = useCallback(
    async (props) => {
      if (tempoRefresh) {
        setLoading(true);

        const adjustedProps = { ...props };

        if (isPropostasFinanceiras !== prevIsPropostasFinanceiras.current) {
          adjustedProps.sortBy = ""; 
          adjustedProps.ordenacaoAsc = false;
          prevIsPropostasFinanceiras.current = isPropostasFinanceiras;
        }

        const page = adjustedProps?.pageIndex + 1;
        if (
          !(
            adjustedProps?.filter?.filtroDinamico !== '' &&
            /^[0-9]*$/.test(
              adjustedProps?.filter?.filtroDinamico
                ?.replaceAll('.', '')
                ?.replaceAll('-', ''),
            )
          ) &&
          !(adjustedProps?.filter?.filtroDataInicial || adjustedProps?.filter?.filtroDataFinal)
        ) {
          Modal('', 'É necessário informar a data inicial e final', 'warning');
          setLoading(false);
        } else {
          const data = await listarAcompanhamentoPropostas({
            pagina: page || 1,
            ordenacao: adjustedProps?.sortBy?.[0],
            filtroEtapa: adjustedProps?.filter.filtroEtapa,
            filtroProduto: adjustedProps?.filter.filtroProduto,
            filtroTipoData: adjustedProps?.filter.filtroTipoData,
            filtroDataInicial: adjustedProps?.filter.filtroDataInicial ? moment(adjustedProps?.filter.filtroDataInicial).format("YYYY-MM-DD") : null,
            filtroDataFinal:adjustedProps?.filter.filtroDataFinal? moment(adjustedProps?.filter.filtroDataFinal).format("YYYY-MM-DD"): null,
            filtroDinamico: adjustedProps?.filter.filtroDinamico,
          });
          if (data?.itens) {
            setPropostas(data.itens);
          } else {
            setPropostas([]);
          }
          setTotal(data?.total);
          setPageCount(data?.totalPages);
          setLoading(false);
        }
      }
    },
    [tempoRefresh],
  );

  const fetchPropostasServico = useCallback(
    async (props) => {
        setLoadingServico(true);

        const adjustedProps = { ...props };
        
        if (isPropostasFinanceiras !== prevIsPropostasFinanceiras.current) {
          adjustedProps.sortBy = ""; 
          adjustedProps.ordenacaoAsc = false;
          prevIsPropostasFinanceiras.current = isPropostasFinanceiras;
        }

        const page = adjustedProps?.pageIndex + 1;
        if (
          !(
            adjustedProps?.filter?.filtroDinamico !== '' &&
            /^[0-9]*$/.test(
              adjustedProps?.filter?.filtroDinamico
                ?.replaceAll('.', '')
                ?.replaceAll('-', ''),
            )
          ) &&
          !(adjustedProps?.filter?.filtroDataInicial || adjustedProps?.filter?.filtroDataFinal)
        ) {
          Modal('', 'É necessário informar a data inicial e final', 'warning');
          setLoadingServico(false);
        } else {
          const data = await listarAcompanhamentoServico({
            pagina: page || 1,
            ordenacao: adjustedProps?.sortBy?.[0],
            filtroEtapa: adjustedProps?.filter.filtroEtapa,
            filtroProduto: adjustedProps?.filter.filtroProduto,
            filtroTipoData: adjustedProps?.filter.filtroTipoData,
            filtroDataInicial: adjustedProps?.filter.filtroDataInicial ? moment(adjustedProps?.filter.filtroDataInicial).format("YYYY-MM-DD") : null,
            filtroDataFinal: adjustedProps?.filter.filtroDataFinal ? moment(adjustedProps?.filter.filtroDataFinal).format("YYYY-MM-DD") : null,
            filtroDinamico: adjustedProps?.filter.filtroDinamico,
          });
          if (data?.itens) {
            setPropostasServico(data.itens);
          } else {
            setPropostasServico([]);
          }
          setTotalServico(data?.total);
          setPageCountServico(data?.totalPages);
          setLoadingServico(false);
        }
    }
  );

  const exportarPropostasCsv = () => {
    setLoading(true);
    const csv = exportarPropostasAcompanhamentoCSV(values).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio-acompanhamento.csv');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });

    return csv;
  };

  const exportarPropostasServicoCsv = () => {
    setLoading(true);
    const csv = exportarPropostasServicoAcompanhamentoCSV(values).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio-acompanhamento.csv');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });

    return csv;
  };

  const exportarPropostasPdf = async () => {
    setLoading(true);
    const response = await imprimirPdfAcompanhamento(values);
    setLoading(false);
    return response.data;
  };

  const fetchContexto = async () => {
    const data = await getContextoProposta();
    setTempoRefresh(data?.atualizarTela);
    setContexto(data);
  };

  const fetchContextoServico = async () => {
    const data = await getContextoServico();
    setLiberaCrefazMais(data?.liberaAbaCrefazMais)
    setContextoServico(data);
  };

  useEffect(() => {
    fetchContexto();
    fetchContextoServico()
  }, []);

  useEffect(() => {
    const tiposData = [
      { id: 0, nome: 'Cadastro' },
      { id: 1, nome: 'Alteração' },
    ];
    setTiposData(tiposData);
    setTiposDataServico(tiposData);
  }, []);

  useEffect(() => {
    formik.resetForm({ values: initialFilters });
    formikServico.resetForm({ values: initialFilters });
    setPropostas([]);
    setTotal(0);
    setPageCount(0);
    setPageIndex(false);
    setFilters(initialFilters);
    setSearch(null);

    setPropostasServico([]);
    setTotalServico(0);
    setPageCountServico(0);
    setPageIndexServico(false);
    setFiltersServico(initialFilters);
    setSearchServico(null);
  }, [isPropostasFinanceiras, resetPage]);

  return (
    <AcompanhamentoPropostaContext.Provider
      value={{
        formik,
        propostas,
        total,
        loading,
        setLoading,
        pageCount,
        contexto,
        tiposData,
        filters,
        exportarPropostasCsv,
        exportarPropostasPdf,
        setFilters,
        fetchPropostas,
        putModalidade,
        avancarPropostaAssinatura,
        getHistoricoReanalise,
        tempoRefresh,
        reanaliseProposta,
        carregarImagem,
        getImagem,
        pageIndex,
        setPageIndex,
        getLinkAnexoHistoricoReanalise, 
        search, 
        setSearch,
        setUpdateList,
        updateList,
        isPropostasFinanceiras, 
        setIsPropostasFinanceiras,
        setResetPage,
        resetPage,
        propostasServico,
        fetchPropostasServico,
        updateListServico,
        totalServico,
        loadingServico,
        pageCountServico,
        filtersServico,
        formikServico,
        pageIndexServico,
        setPageIndexServico,
        searchServico,
        tiposDataServico,
        contextoServico,
        setFiltersServico,
        setSearchServico,
        setUpdateListServico,
        liberaCrefazMais,
        exportarPropostasServicoCsv,
      }}
    >
      {children}
    </AcompanhamentoPropostaContext.Provider>
  );
}

export default AcompanhamentoPropostaProvider;
