import React, {
    Fragment,
    useContext,
    useEffect,
    useState,
  } from 'react';
  
  import { Field } from 'formik';
  import { Form, Select } from 'formik-antd';
  import Input from '../../../components/SimpleInput';
  import { Tooltip } from 'antd';
  
  import InputMask from 'react-text-mask';
  
  import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
  import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
  
  import { cepMask } from '../../../utils/masks';
  import SelectSearch from '../../../components/SearchSelect';
  import Modal from '../../../components/Modal';
import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';
  
  const { Option } = Select;
  
  const TooltipInfo = ({ text }) => (
    <Tooltip
      placement="bottom"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
  
  function DadosEnderecoForm({ formik }) {
    const { values, errors, setFieldTouched, setFieldValue, setFieldError, touched } =
      formik;
    const [tempCEP, setTempCEP] = useState('');
    const [statusCep, setStatusCep] = useState('');
  
    const {
      estados,
      cidades,
      fetchEndereco,
      setEnderecoUfId,
      validationField,
      editableField,
      fetchListaCidades,
    } = useContext(PropostaServicoContext);
  
    const handleConsultarCEP = async (value) => {
      setFieldTouched('endereco.cep');
  
      if (value) {
        value = value.replace(/\D/g, '');

        if (value.length === 8){
        
          const data = await fetchEndereco(value);

          if (data?.status) {
            if (data.ufId > 0 && data.ufId === values.endereco.ufId) {
              await fetchListaCidades(data.ufId);
    
              setFieldValue('endereco.ufId', data.ufId);
              setFieldValue('endereco.cidadeId', data.cidadeId);
              setFieldValue('endereco.logradouro', data.logradouro);
              setFieldValue('endereco.bairro', data.bairro);
              setTempCEP(values?.endereco?.cep);
              
            } else {
              setFieldValue('endereco.cep', tempCEP);
              Modal(
                '',
                'Alteração inválida, o endereço somente pode ser alterado desde que não ocorra alteração na UF do cliente. Se necessário correção da UF, a proposta deve ser cancelada e lançada novamente',
                'warning',
              );
            }
          } else {
            setStatusCep('error');
            setFieldError('endereco.cep', 'CEP inválido ou não encontrado');
          }
        }
        
      }
    };

  
    const handleSelecionarEstado = (value) => {
      setEnderecoUfId(value);
      setFieldValue('endereco.ufId', value);
      setFieldValue('endereco.cidadeId', '');
    };
  
    const validaCEP = () => {
      if (values?.endereco?.cep?.length === 8) {
        if (!values?.endereco?.cep?.match(/[\d]{8}/)) {
          setFieldValue('endereco.cep', '');
        } else {
          setTempCEP(values?.endereco?.cep);
          handleConsultarCEP(values?.endereco?.cep)
        }
      }
    };
  
    useEffect(() => {
      validaCEP();
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [values?.endereco?.cep]);

    useEffect(() => {
      const cidadeSelecionada = cidades.find((cidade) => cidade.id === values?.endereco?.cidadeId);
      setFieldValue('endereco.cidade', cidadeSelecionada?.nome ); 

    }, [values?.endereco?.cidadeId, cidades]);
  
    return (
      <Fragment>
        <div className="form-row pt-4 pb-3">
          <div className="Container  ContainerColor mt-1"></div>
          <div className="row pl-4 titleMsg">Dados de Endereço do Cliente</div>
        </div>
  
        <div className="form-row">
          <div className="col-md-3 col-lg-3 col-xl-2">
            <Form.Item
              name="endereco.cep"
              label="CEP"
              validateStatus={touched?.endereco?.cep && statusCep}
              help={touched?.endereco?.cep && errors?.endereco?.cep }
              validate={(value) => {
                let msgError = null;
                if (!value) {
                  msgError = 'Campo obrigatório';
                  setStatusCep('error');
                } else if (value.replace(/\D/g, '').length < 8) {
                  setStatusCep('warning');
                  msgError = 'CEP Inválido';
                } else {
                  setStatusCep('');
                }
                return msgError;
              }}
              hasFeedback
            >
              <Field name="endereco.cep">
                {({ field }) => (
                  <InputMask
                    {...field}
                    className="ant-input"
                    placeholder="00000-000"
                    autoComplete="cep"
                    mask={cepMask}
                    showMask={false}
                    disabled={!editableField('endereco.cep')}
                    onBlur={(e) => handleConsultarCEP(e.target.value)}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
  
          <div className="col-md-9 col-lg-9 col-xl-4">
            <Form.Item
              className="form-logradouro"
              name="endereco.logradouro"
              validate={(value) =>
                validationField(value, 'endereco.logradouro', 'Logradouro')
              }
              label={
                <>
                  <label>Logradouro</label>
                  <TooltipInfo text="Rua, Avenida..." />
                </>
              }
              hasFeedback
            >
              <Input
                name="endereco.logradouro"
                disabled={!editableField('endereco.logradouro')}
                placeholder="Ex.: Rua, Avenida"
                value={formik?.values?.endereco?.logradouro}
                onBlur={(e) => {
                  setFieldValue('endereco.logradouro', String(e.target.value).trim());
                }}
                formatWhitespace={true}
              />
            </Form.Item>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-2">
            <Form.Item
              name="endereco.numero"
              label="Número"
              validate={(value) =>
                validationField(value, 'endereco.numero', 'Número')
              }
              hasFeedback
            >
              <Input
                name="endereco.numero"
                type="number"
                disabled={!editableField('endereco.numero')}
                placeholder="Ex.: 603"
                min={0}
                value={formik?.values?.endereco?.numero}
                onBlur={(e) => {
                  setFieldValue('endereco.numero', e.target.value);
                }}
              />
            </Form.Item>
          </div>
  
          <div className="col-md-6 col-lg-6 col-xl-4">
            <Form.Item
              name="endereco.bairro"
              label="Bairro"
              validate={(value) =>
                validationField(value, 'endereco.bairro', 'Bairro')
              }
              hasFeedback
            >
              <Input
                name="endereco.bairro"
                disabled={!editableField('endereco.bairro')}
                placeholder="Ex.: Santa Lucia"
                value={formik?.values?.endereco?.bairro}
                onBlur={(e) => {
                  setFieldValue('endereco.bairro', String(e.target.value).trim());
                }}
                formatWhitespace={true}
              />
            </Form.Item>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-2">
            <Form.Item
              name="endereco.ufId"
              label="UF"
              validate={(value) => validationField(value, 'endereco.ufId', 'UF')}
              hasFeedback
            >
              <Field name="endereco.ufId">
                {({ field }) => (
                  <SelectSearch
                    {...field}
                    onChange={(value) => handleSelecionarEstado(value)}
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                    disabled={ !editableField('endereco.ufId')}
                    optionFilterProp="children"
                    autoComplete="ufId"
                    suffixIcon={<IconArrowSelect />}
                  >
                    {estados.length > 0 &&
                      estados.map((estado) => (
                        <Option key={estado.id} value={estado.id}>
                          {estado.uf}
                        </Option>
                      ))}
                  </SelectSearch>
                )}
              </Field>
            </Form.Item>
          </div>
  
          <div className="col-md-6 col-lg-6 col-xl-4">
            <Form.Item
              name="endereco.cidadeId"
              label="Cidade"
              validate={(value) =>
                validationField(value, 'endereco.cidadeId', 'Cidade')
              }
              hasFeedback
            >
              <Field name="endereco.cidadeId">
                {({ field }) => (
                  <SelectSearch
                    {...field}
                    onChange={(value) =>
                      setFieldValue('endereco.cidadeId', value)
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                    showSearch
                    disabled={!editableField('endereco.cidadeId')}
                    optionFilterProp="children"
                    autoComplete="cidadeId"
                    suffixIcon={<IconArrowSelect />}
                  >
                    {cidades.length > 0 &&
                      cidades.map((cidade) => (
                        <Option key={cidade.id} value={cidade.id}>
                          {cidade.nome}
                        </Option>
                      ))}
                  </SelectSearch>
                )}
              </Field>
            </Form.Item>
          </div>
  
          <div className="col-md-6 col-lg-6 col-xl-6">
            <Form.Item
              name="endereco.complemento"
              label="Complemento"
              validate={(value) =>
                validationField(value, 'endereco.complemento', 'Complemento')
              }
              hasFeedback
            >
              <Input
                name="endereco.complemento"
                disabled={!editableField('endereco.complemento')}
                placeholder="Ex.: Casa, Apto, Bloco"
                value={formik?.values?.endereco?.complemento}
                onBlur={(e) => {
                  setFieldValue('endereco.complemento', String(e.target.value).trim());
                }}
                formatWhitespace={true}
              />
            </Form.Item>
          </div>
        </div>
      </Fragment>
    );
  }
  
  export { DadosEnderecoForm };
  