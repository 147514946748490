import React, { useContext, useState } from 'react';
import moment from 'moment';

import { Link, useHistory } from 'react-router-dom';
import { Select } from 'antd';

import Tooltip from '../../components/tooltip';

import ButtonSituacao from '../../components/ButtonSituacao';
import Modal from '../../components/Modal';
import ModalConfirmation from '../../components/ModalConfirmation';

import { ReactComponent as PrintIcon } from '../../assets/images/icons/print.svg';
import { ReactComponent as Writing } from '../../assets/images/icons/writing.svg';
import { ReactComponent as Cellphone } from '../../assets/images/icons/cellphone.svg';
import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/icon-editar.svg';
import { ReactComponent as QuestionMark } from '../../assets/images/icons/question-mark.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/icons/icon-trash.svg';
import { ReactComponent as ReloadIcon } from '../../assets/images/icons/reload-proposta.svg';
import { ReactComponent as AdvanceProposalIcon } from '../../assets/images/icons/icon-avancar-proposta.svg';

import { currencyFormat } from '../../utils/numberFormatter';
import { cpfFormatter } from '../../utils/textFormatter';

import { cancelamentoProposta } from '../../services/propostaService';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { AcompanhamentoPropostaContext } from '../../contexts/AcompanhamentoPropostaContext';
import { avancarPropostaAssinatura } from '../../services/acompanhamentopropostaService';
import swal from 'sweetalert';

const { Option } = Select;

const openModalCancelarProposta = (id) => {
  ModalConfirmation(
    'Atenção!',
    `Confirmar o cancelamento da proposta ${id}?`,
    'warning',
    () => handleCancelarProposta(id),
  );
};

const handleCancelarProposta = async (id) => {
  const response = await cancelamentoProposta(id);
  if (response.success) {
    Modal('', 'Proposta cancelada com sucesso', 'success');
    window.location.reload();
  }
};

const firstUpperCase = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

function AcompanhamentoColumns(
  putModalidade,
  setModalReanaliseVisible,
  setPropostaId,
  setHistoricoReanalise,
  setMotivo,
  setClickedModalMotivo,
  setArquivos,
) {
  const history = useHistory();
  const { checarPermissao } = useContext(ControleAcessoContext); 
  const { setLoading, getHistoricoReanalise, setUpdateList, updateList } =
    useContext(AcompanhamentoPropostaContext);

  const acessoReenviarProposta = checarPermissao(
    'botao',
    'botao.acompanhamento.reenviarProposta',
    'Editar',
  );

  const acessoAvancarProposta = checarPermissao(
    'botao',
    'botao.acompanhamento.avancarAssinatura',
    'Editar',
  );

  const navegarDestino = (id) => {
    history.push(`pre-analise/${id}`);
  };

  const handleAvancarProposta = (id) => {
    swal({
      text: 'Avançar para Aguard. Assinatura?',
      icon: 'warning',
      buttons: ['Cancelar', true],
    }).then(async (response) => {
      if (response) {
        await avancarPropostaAssinatura(id);
        setUpdateList(!updateList);
      }
    });
  };

  async function handleHistorico(id) {
    const data = await getHistoricoReanalise(id);
    if (data?.data) {
      setHistoricoReanalise(data.data);
    }
  }

  const columns = [
    {
      Header: 'Cadastro',
      accessor: 'dataCadastro',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      Header: 'Alteração',
      accessor: 'dataAlteracao',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) =>
        value && (
          <div style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY HH:mm')}
          </div>
        ),
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{cpfFormatter(value)}</div>
      ),
    },
    {
      Header: 'Cliente',
      accessor: 'cliente',
      style: {
        minWidth: 250,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{value}</div>
      ),
    },
    {
      Header: 'Produto',
      accessor: 'tipoProduto',
      style: {
        width: 150,
        minWidth: 150,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    // modalidade
    {
      Header: 'Matriz',
      accessor: 'matrizNome',
      style: {
        width: 180,
        minWidth: 180,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Login',
      accessor: 'agente',
      style: {
        width: 150,
        minWidth: 150,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Nome Usuário',
      accessor: 'nomeUsuario',
      style: {
        width: 180,
        minWidth: 180,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Valor',
      accessor: 'valorProposta',
      style: {
        width: 100,
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: 'Nº Proposta',
      accessor: 'id',
      style: {
        width: 125,
        minWidth: 125,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    //cancelar
    {
      Header: '',
      accessor: 'print',
      style: {
        width: 20,
        minWidth: 20,
        textAlign: 'center',
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center', paddingRight: '5px' }}>
          {row.original.impressaoDocumentos &&
            checarPermissao(
              'botao',
              'botao.acompanhamento.impressaoDocumentos',
              'Visualizar',
            ) && (
              <>
                <Link
                  to={{
                    pathname: `/impressao-proposta/${row.original.id}`,
                  }}
                >
                  <PrintIcon width="15" height="15" />
                </Link>
              </>
            )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  if (
    checarPermissao(
      'botao',
      'botao.acompanhamento.situacaoProposta',
      'Visualizar',
    )
  ) {
    columns.splice(0, 0, {
      Header: 'Status',
      accessor: 'situacaoDescricao',
      style: {
        textAlign: 'center',
      },
      Cell: ({ row }) => {
        const SITUACAO_OFERTA_ID = 2;

        const linkDeDirecionamento = () => {
          if (row.original.situacaoId === SITUACAO_OFERTA_ID)
            return `/ofertas-produtos/${row.original.id}`;
          else if (
            row.original.situacaoDescricao === 'Pré-análise'
          ) {
            return `/pre-analise/${row.original.id}`;
          }
          return `/proposta-credito/${row.original.id}`;
        };

        const rowContent = () => {
          return (
            <ButtonSituacao
              disabled={!checarPermissao(
                'tela',
                'tela.propostacredito',
                'Visualizar',
              )}
              link={linkDeDirecionamento()}
              linkState={row.values.id}
              bgColor={row.original.situacaoCor}
              acaoPreAbertura={row.original.acaoPreAbertura}
            >
              <div className="btn-situacao">
                {row.original.situacaoDescricao !== 'Falha de Comunicação' &&
                  row.original.situacaoDescricao !== 'Pré-análise' && (
                    <EditIcon />
                  )}
                <div>{row.original.situacaoDescricao}</div>
              </div>
            </ButtonSituacao>
          );
        };

        if (
          checarPermissao(
            'botao',
            'botao.acompanhamento.tooltipMotivo',
            'Visualizar',
          ) &&
          row.original.propostaMotivoDescricao
        ) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Tooltip
                placement="bottomRight"
                arrowPointAtCenter
                color="#435361"
                title={
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '15px',
                    }}
                  >
                    {row.original.propostaMotivoDescricao}
                  </div>
                }
              >
                {rowContent()}
              </Tooltip>
            </div>
          );
        } else {
          return <div style={{ textAlign: 'center' }}>{rowContent()}</div>;
        }
      },
      disableSortBy: true,
    });

    columns.splice(1, 0, {
      Header: '',
      accessor: 'MotivoGrupo',
      style: {
        width: 40,
        maxWidth: 40,
        textAlign: 'center',
      },
      Cell: ({ value, row }) => (
        <div style={{ paddingInline: '6px' }}>
          {row.original.flagFalhaConsultaCrivo ? (
            <div style={{ textAlign: 'center', padding: '0' }}>
              <Tooltip
                placement="topRight"
                arrowPointAtCenter
                color="#435361"
                title={
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '15px',
                    }}
                  >
                    Falha na comunicação com o Motor de Crédito, clique para
                    reenviar a proposta.
                  </div>
                }
              >
                {checarPermissao(
                  'botao',
                  'botao.acompanhamento.reenviarProposta',
                  'Visualizar',
                ) && (
                  <IconExclamationCircle
                    width="20"
                    style={{ cursor: acessoReenviarProposta ? 'pointer' : 'not-allowed'}}
                    onClick={() => {
                      acessoReenviarProposta && navegarDestino(row.original.id);
                    }}
                  />
                )}
              </Tooltip>
            </div>
          ) : (
            value && (
              <div style={{ textAlign: 'center' }}>
                <Tooltip
                  placement="topRight"
                  arrowPointAtCenter
                  color="#435361"
                  title={
                    <div
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '13px',
                        lineHeight: '20px',
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        maxWidth: '300px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {value.map((motivo) => (
                          <div className="tooltip-title" key={motivo.id}>
                            {`- ${firstUpperCase(motivo?.nome)}`}
                          </div>
                        ))}

                        <ul className="tooltip-list">
                          <li>{row.original.mensagemMotivo}</li>
                        </ul>
                      </div>
                    </div>
                  }
                >
                  <QuestionMark width="20" className="question-icon" />
                </Tooltip>
              </div>
            )
          )}
            {checarPermissao(
            'botao',
            'botao.acompanhamento.avancarAssinatura',
            'Visualizar',
            ) &&
            row.original.flagAvancarEtapaAssinatura && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip
                placement="top"
                arrowPointAtCenter
                color="#435361"
                title={
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '15px',
                    }}
                  >
                    Avançar proposta
                  </div>
                }
              >
                <AdvanceProposalIcon
                  width="20"
                  style={{ cursor: acessoAvancarProposta ? 'pointer' : 'not-allowed'}}
                  onClick={() => acessoAvancarProposta && handleAvancarProposta(row.original.id)}
                />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      disableSortBy: true,
    });
  }

  columns.splice(12, 0, {
    Header: '',
    accessor: 'flagFalhaConsultaCrivo',
    style: {
      width: 20,
      minWidth: 20,
      textAlign: 'center',
    },
    Cell: ({ row }) => (
      <div>
        {checarPermissao(
          'botao',
          'botao.acompanhamento.cancelar',
          'Visualizar',
        ) && row.original.permiteCancelamento ? (
          <div style={{ textAlign: 'center', paddingRight: '5px' }}>
            <Tooltip
              placement="topRight"
              arrowPointAtCenter
              color="#435361"
              title={
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '15px',
                  }}
                >
                  Cancelar Proposta
                </div>
              }
            >
              {checarPermissao(
                'botao',
                'botao.acompanhamento.cancelar',
                'Editar',
              ) ? (
                <>
                  <TrashIcon
                    className="trash-icon"
                    width="15"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => openModalCancelarProposta(row.original.id)}
                  />
                </>
              ) : (
                <>
                  <TrashIcon className="trash-icon" width="15" />
                </>
              )}
            </Tooltip>
          </div>
        ) : (
          checarPermissao(
            'botao',
            'botao.acompanhamento.reanalise',
            'Visualizar',
          ) &&
          row.original.permiteReanalise === 1 && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip
                placement="bottomRight"
                arrowPointAtCenter
                color="#435361"
                title={
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '15px',
                    }}
                  >
                    Reanalisar Proposta
                  </div>
                }
              >
                <ReloadIcon
                  onClick={() => {
                    if (
                      checarPermissao(
                        'botao',
                        'botao.acompanhamento.reanalise',
                        'Editar',
                      )
                    ) {
                      setPropostaId(row.original.id);
                      setModalReanaliseVisible(true);
                      setMotivo('');
                      setClickedModalMotivo(false);
                      setArquivos([]);
                      handleHistorico(row.original.id);
                    } else {
                      Modal('', 'Usuário não possui permissão', 'error');
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          )
        )}
      </div>
    ),
    disableSortBy: true,
  });

  if (
    checarPermissao(
      'botao',
      'botao.acompanhamento.selecaoModalidade',
      'Visualizar',
    )
  )
    columns.splice(7, 0, {
      Header: 'Modalidade',
      accessor: 'modalidadeId',
      style: {
        width: 120,
        minWidth: 120,
        textAlign: 'center',
        cursor: 'default',
      },
      Cell: ({ value, row }) => {
        const [selected, setSelected] = useState(null);
        if (value && selected == null) {
          setSelected(value);
        }
        if (row.original.permiteTrocaModalidade) {
          return (
            <Select
              value={selected}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={
                checarPermissao(
                  'botao',
                  'botao.acompanhamento.selecaoModalidade',
                  'Editar',
                )
                  ? row.original.modalidade.length === 0
                  : true
              }
              onChange={async (selectedValue) => {
                let temp = selected;
                setLoading(true);
                try {
                  await putModalidade({
                    id: row.original.id,
                    tipoModalidade: selectedValue,
                  });
                  setSelected(selectedValue);
                  row.original.modalidadeId = selectedValue;
                  setLoading(false);
                } catch {
                  setSelected(temp);
                  row.original.modalidadeId = temp;
                  setLoading(false);
                }
              }}
            >
              {row.original.modalidade.map((modalidade) => (
                <Option key={modalidade.id} value={modalidade.id}>
                  <span className="ml-2">{modalidade.nome}</span>
                </Option>
              ))}
            </Select>
          );
        } else {
          if (value === 1) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Writing />
                <span className="ml-1">Físico</span>
              </div>
            );
          } else if (value === 2) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Cellphone color="#FF7817" />
                <span className="ml-1">Digital</span>
              </div>
            );
          } else {
            return '';
          }
        }
      },
      disableSortBy: true,
    });

  return columns;
}

export default AcompanhamentoColumns;
