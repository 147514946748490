import api from './api';
import integrationApi from './integrationApi';

export const listarPropostas = async () => {
  try {
    const { data } = await api.get('/Proposta');
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPropostaPreAnalise = async (propostaId) => {
  const { data } = await api.get(`/proposta/pre-analise/${propostaId}`);
  return data.data;
};

export const putPropostaPreAnalise = async (proposta) => {
  const { data } = await api.put(
    `/proposta/pre-analise/${proposta.id}`,
    proposta,
  );
  return data.data;
};

export const getOfertasProdutos = async (propostaId) => {
  const { data } = await api.get(`/proposta/oferta-produto/${propostaId}`);
  return data.data;
};

export const fetchPreAnaliseCpf = async (cpf) => {
  try {
    const { data } = await api.get(`/Pessoa/preanalise/${cpf}`);
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCardEstatistica = async () => {
  const { data } = await api.get('/proposta/card-estatistica');
  return data.data
}

export const listarPropostasPainel = async ({
  pagina = 1,
  ordenacao = { id: '', desc: false },
}) => {
  try {
    const { data } = await api.post('/Proposta/Estatistica', {
      pagina,
      ordenacao: ordenacao.id,
      ordenacaoAsc: !ordenacao.desc,
    });
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const gravarOperatividade = async (value) => {
  try {
    const { data } = await api.post(`/Operatividade`, value);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const gravarModalidade = async (id, value) => {
  try {
    const temp = { id: id, modalidadeId: value };
    const { data } = await api.post(`/Proposta/Modalidade`, temp);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const inserirProposta = async (proposta) => {
  try {
    const { data } = await api.post('/proposta', proposta);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const simulacaoValorSolicitado = async (produto) => {
  try {
    const { data } = await api.post('proposta/simulacao-prestacao', produto);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const calculoPrestacao = async (produto) => {
  try {
    const { data } = await api.post('/Proposta/calcular-prestacao', produto);
    return data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
};

export const simulacaoValorParcela = async (produto) => {
  try {
    const { data } = await api.post('/proposta/simulacao-solicitado', produto);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const atualizarSelecaoOferta = async (proposta) => {
  try {
    const { data } = await api.put(`/Proposta/${proposta.id}`, proposta);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const atualizarPropostaCredito = async (proposta) => {
  try {
    proposta?.historicoCia.debitosConveniada?.forEach((item) => {
      if (item.id <= 0) item.id = null;
    });
    const { data } = await api.put(
      `/proposta/entrada-mesa-de-credito/${proposta.id}`,
      proposta,
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPropostaEntradaMesa = async (propostaId) => {
  try {
    const { data } = await api.get(
      `/proposta/entrada-mesa-de-credito/${propostaId}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPropostaMesaCredito = async (propostaId) => {
  try {
    const { data } = await api.get(`/proposta/mesa-de-credito/${propostaId}`);
    if (
      data?.data?.proposta &&
      !data.data.proposta.historicoCia.debitosConveniada
    ) {
      data.data.proposta.historicoCia.debitosConveniada = [];
    }
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const atualizarMesaCredito = async (proposta) => {
  proposta?.historicoCia.debitosConveniada?.forEach((item) => {
    if (item.id <= 0) item.id = null;
  });
  const { data } = await api.put(
    `/proposta/mesa-de-credito/${proposta.id}`,
    proposta,
  );
  return data.data;
};

export const getPropostaMensagens = async (propostaId) => {
  try {
    const { data } = await api.get(
      `/proposta/central-comunicacao/${propostaId}`,
    );
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getPropostaMensagensParcial = async (propostaId, mensagemId) => {
  try {
    const { data } = await api.get(
      `/proposta/central-comunicacao/${propostaId}?id=${mensagemId}`,
    );
    return data.data;
  } catch (error) {
    return error;
  }
};

export const sendPropostaMensagem = async (mensagem) => {
  try {
    const { data } = await api.post(`/proposta/central-comunicacao`, mensagem);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const sendPropostaAnotacao = async (mensagem) => {
  try {
    const { data } = await api.post(`/anotacao`, mensagem);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getVariaveisCrivo = async (payload) => {
  try {
    const { data } = await api.post('/proposta/simulacao-crivo', payload);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const cancelamentoProposta = async (id) => {
  const { data } = await api.put(`/proposta/cancelamento-proposta/${id}`, {
    id,
  });
  return data;
};

export const reanaliseProposta = async (propostaId, motivo, anexo) => {
  const { data } = await api.put(`/proposta/reanalise/${propostaId}`, {
    propostaId,
    motivo,
    anexo,
  });
  return data;
};

export const getHistoricoReanalise = async (id) => {
  const { data } = await api.get(`/proposta/reanalise/historico/${id}`);
  return data;
};

export const getLinkAnexoHistoricoReanalise = async (link) => {
  const { data } = await api.get(link);
  return data;
};

export const getBlocklistMesaCredito = async (id) => {
  const { data } = await api.get(`/bloqueado/modal-mesa-credito/${id}`, {
    id,
  });
  return data;
};

export const getProcessamentoCrivo = async (propostaId) => {
  const { data } = await integrationApi.get(
    `/Consulta/resumo/proposta/${propostaId}`,
  );
  return data.data;
};

export const getProcessamentoCrivoCompleto = async (propostaId) => {
  const { data } = await integrationApi.get(`/Consulta/proposta/${propostaId}`);
  return data.data;
};

export const aprovacaoPromotora = async (payload) => {
  const { data } = await api.put('/all/proposta/aprovarReprovar', payload);
  return data.data;
};

export const listagemMotivosDecisao = async (decisaoId) => {
  const { data } = await api.get(`/motivo/decisao/${decisaoId}`);
  return data.data;
};

export const listagemMotivosEtapas = async (situacaoQueryString) => {
  const { data } = await api.get(`/motivo/etapa/${situacaoQueryString}`);
  return data.data;
};

export const chamadaLuzEmDia = async (payload) => {
  const { data } = await api.post(`/proposta/valida-luz-em-dia`, payload);
  return data.data;
};

export const listaAnalista = async (payload) => {
  const { data } = await api.post(`/proposta/lista-analista`, payload);
  return data.data;
};

export const reenviarSMS = async (propostaId) => {
  try {
    const { data } = await api.post('proposta/reenvio_sms', { id: propostaId });
    return data;
  } catch (error) {
    return error;
  }
};

export const buscarHistoricoAcoesProposta = async (id) => {
  const { data } = await api.get(`/proposta/salvamentoproposta/${id}`);
  return data.data;
};

export const buscarHistoricoProposta = async (propostaId, ordenacao, tela) => {
  const { data } = await api.get(
    `/proposta/historico/${propostaId}?ordenacaoAsc=${ordenacao}&tela=${tela}`,
  );
  return data.data;
};

export const postPreAnalise = async (cidadeId, propostaId) => {
  const { data } = await api.post(`/pre-analise`, {
    cidadeId, propostaId
  });
  return data.data;
};

export const putConsultarPlacaVeiculo = async (propostaId, placaVeiculo) => {
  const { data } = await api.put(`/proposta/oferta-produto-cpauto/${propostaId}`, { placaVeiculo });
  return data.data;
}

export const postSimulacaoLimiteParcela = async (payload) => {
  try {
    const { data } = await api.post('/proposta/simulacao-limite-parcela', payload);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const consultarValoresEnergia = async (id, payload) => {
  const { data } = await api.put(`/proposta/oferta-produto-consultar-crivo/${id}`, payload);
  return data.data;
}

export const postAcertPixDocOCR = async (payload) => {
  const { data } = await integrationApi.post(`/AcertPix/enviar-ocr`, payload);
  return data.data;
}

export const postConsultaDocOCR = async (payload) => {
  const { data } = await integrationApi.post('/AcertPix/consultar-ocr', payload);
  return data.data;
}

export const getReconsultaDadosConvenio = async (propostaId) => {
  const { data } = await api.get(
    `/proposta/reconsulta-dados-convenio/${propostaId}`,
  );
  return data.data;
};

export const getHistoricoCiaAtualizada = async (propostaId) => {
  const { data } = await api.get(
    `/proposta/proposta-dados-convenio/${propostaId}`,
  );
  return data.data;
};

export const getOfertasServicos = async (propostaId) => {
  const { data } = await api.get(`/servico/oferta-servico/${propostaId}`,
    {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      }
    }
  );
  return data;
};

export const postAvancaServico = async (payload) => {
  const { data } = await api.post(`/servico/cadastrar-proposta-servico`, payload);
  return data;
};

export const getContextoServico = async () => {
  const { data } = await api.get(`servico/obter-contexto`);
  return data.data
}

export const getPropostaServico = async (propostaServicoId) => {
  const { data } = await api.get(`servico/obter-proposta-servico/${propostaServicoId}`);
  return data.data
}

export const atualizaPropostaServico = async (payload) => {
  const { data } = await api.put(`/servico/atualizar-proposta-servico`, payload);
  return data;
}