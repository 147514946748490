import React, { useEffect, useState } from 'react';

import { Button, Form, Input } from 'antd';

import brand from '../../assets/images/crefazon-logo.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/crefazon-logo-alt.svg';
import { ReactComponent as IconEye } from '../../assets/images/icons/icon-eye-redefinir-senha.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/icon-checking-circle-filled.svg';

import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { validaLink, redefinirSenha,getEmailUsuarioRedefinicao } from '../../services/usuariosService';
import swal from 'sweetalert';
import { signIn } from '../../services/authService';
import Spinner from '../../components/Spinner';
import Modal from '../../components/Modal';

function RedefinirSenha() {
  const [name, setName] = useState('');
  const [qtdCaracteresSenha, setQtdCaracteresSenha] = useState(0);
  const [senhaAtualInvalida, setSenhaAtualInvalida] = useState(false);
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [senhaAtual, setSenhaAtual] = useState(null);
  const [typeSenhaAtualInput, setTypeSenhaAtualInput] = useState('password');
  const [typeSenhaInput, setTypeSenhaInput] = useState('password');
  const [typeConfirmSenhaInput, setTypeConfirmSenhaInput] = useState('password');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingEsqueceuSenha, setLoadingEsqueceuSenha] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: null,
    uppercase: null,
    lowercase: null,
    number: null,
    specialChar: null,
    noRepeats: null,
    match: null
  });
  const [isExpiracao, setIsExpiracao] = useState(false);
  const [error, setError] = useState('');

  const dicasSenha = [
    { key: 'minLength', text: `No mínimo ${qtdCaracteresSenha} caracteres` },
    { key: 'uppercase', text: 'Pelo menos uma letra maiúscula (A-Z)' },
    { key: 'lowercase', text: 'Pelo menos uma letra minúscula (a-z)' },
    { key: 'number', text: 'Pelo menos um número (0-9)' },
    { key: 'specialChar', text: 'Pelo menos um caractere especial (ex.: @, #, $, %, &, *)' },
    { key: 'noRepeats', text: 'Não conter três repetições consecutivas do mesmo caractere (ex.: 111, aaa, BBB)' },
    { key: 'match', text: 'A nova senha e a confirmação de senha devem ser iguais' },
  ];

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get('id');
  const token = query.get('token');
  const expiration = query.get('expiration');

  useEffect(() => {
    callValidaLink();
    setIsExpiracao(expiration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validarRedefinicao = async() =>{
    const data = await validaUsuario()

    if(data && (data?.email || data?.telefone)){
      history.push('/escolha-envio');
      setLoadingEsqueceuSenha(false)
    }else{
      setError(data?.mensagem);
      setLoadingEsqueceuSenha(false)
    }
  }

  const validaUsuario = async () =>{
    if(name){
      setLoadingEsqueceuSenha(true)
      const data = await getEmailUsuarioRedefinicao(name)
      localStorage.setItem('dadosRedefinicao',JSON.stringify({...data, login: name}));
      return data
    }
  }
  

  function callValidaLink() {
    validaLink(id, token).then((response) => {
      if (response?.status) {
        setName(response?.login);
        setQtdCaracteresSenha(response?.quantidadeCaracteresSenha)
      } else {
        swal(
          '',
          'Este link foi inativado. Solicite um novo link de redefinição de senha para a equipe de cadastro.',
          'warning',
          {
            className: 'discard-modal-hierarquia',
            closeOnClickOutside: false,
          },
        ).then((response) => {
          if (response) {
            history.push('/login');
          }
        });
      }
    });
  }

  // eslint-disable-next-line
  function validateSenhas(value, type) {
    let campo1 = type === 'senha' ? value : senha;
    let campo2 = type === 'confirmSenha' ? value : confirmSenha;
  
    setSenha(campo1);
    setConfirmSenha(campo2);

    if (!campo1 && !campo2) {
      setPasswordValidation({
        minLength: null,
        uppercase: null,
        lowercase: null,
        number: null,
        specialChar: null,
        noRepeats: null,
        match: null
      });
      return;
    }
  
    const newValidation = {
      minLength: campo1.length >= qtdCaracteresSenha,
      uppercase: /[A-Z]/.test(campo1),
      lowercase: /[a-z]/.test(campo1),
      number: /\d/.test(campo1),
      specialChar: /[^a-zA-Z0-9]/.test(campo1),
      noRepeats: campo1.length > 0 ? !/(.)\1\1/.test(campo1) : false,
      match: campo1 === campo2 && campo1.length > 0
    };
  
    setPasswordValidation(newValidation);
  }
  const submitForm = async() => {
    if (
      passwordValidation.minLength &&
      passwordValidation.uppercase &&
      passwordValidation.lowercase &&
      passwordValidation.number &&
      passwordValidation.specialChar &&
      passwordValidation.noRepeats &&
      passwordValidation.match
    ) {
      setLoading(true);

      const body = {
        id,
        token,
        senhaAtual,
        senha,
        confirmacaoSenha: confirmSenha,
      };

      const responseRedefinicao = await redefinirSenha(body);

      if(responseRedefinicao?.success){
        const data = await signIn({
          login: name, 
          senha: senha 
        });
        if (data?.data?.token) {
          localStorage.setItem('authToken', data.data.token);
          localStorage.setItem('login', data.data.login);
          localStorage.setItem('userId', data.data.userId);
          localStorage.setItem('nome', data.data.nome);
          localStorage.setItem('telefonia', data.data.telefonia);
          setLoading(false);
          history.push('/');
        } 
        else{
          setLoading(false);
        }
        
      } else if(responseRedefinicao?.messages[0] === "Senha atual inválida"){
        setSenhaAtualInvalida(true);
        setLoading(false);

      } else{
        Modal('', responseRedefinicao?.messages[0], 'warning');
        setLoading(false);
      }
    }
  }
  return (
    <div className="redefinir-senha-container">
      <div className="row h-100">
        <div className="col-lg-6 redefinir-senha-brand">
          <div>
            <div className="brand">
              <img src={brand} alt="logo-crefazon" width="100%" />
            </div>
            <BrandWhite className="brand-white" />
          </div>
        </div>
        <div className="col-lg-6 redefinir-senha-form">
          <div style={{ width: '400px' }}>
            <div className="redefinir-senha-title">
              <h2>Redefinição de Senha</h2>
              <div className="underline"></div>
            </div>
            <div>
              <div className='login-name'>
                <span><b>Usuário:</b> {name}</span>
              </div>
              <Form layout="vertical" autoComplete="off" onFinish={submitForm}>
                {isExpiracao &&
                  <Form.Item
                    name="senhaAtual"
                    label="Senha Atual"
                    className={`redefinir-senha-item ${!senhaAtualInvalida && 'not-error'}`}
                    validateStatus={senhaAtualInvalida ? 'error' : ''}
                    help={senhaAtualInvalida ? 'Senha atual inválida' : ''}
                  >
                    <Input
                      type={typeSenhaAtualInput}
                      name="novaSenha"
                      size="large"
                      className={`redefinir-senha-input ${senhaAtualInvalida && 'error'}`}
                      onChange={(e) => {
                        setSenhaAtual(e.target.value);
                        setSenhaAtualInvalida(false);
                      }}
                    />
                    <div
                      className="eye-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTypeSenhaAtualInput(
                          // eslint-disable-next-line
                          typeSenhaAtualInput == 'text' ? 'password' : 'text',
                        );
                      }}
                    >
                      <IconEye />
                    </div>
                  </Form.Item>
                }
                <Form.Item
                  name="novaSenha"
                  label="Nova senha"
                  className="redefinir-senha-item"
                >
                  <Input
                    type={typeSenhaInput}
                    name="novaSenha"
                    size="large"
                    className="redefinir-senha-input"
                    onChange={(e) => {
                      validateSenhas(e.target.value, 'senha');
                    }}
                  />
                  <div
                    className="eye-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setTypeSenhaInput(
                        // eslint-disable-next-line
                        typeSenhaInput == 'text' ? 'password' : 'text',
                      );
                    }}
                  >
                    <IconEye />
                  </div>
                </Form.Item>
                <Form.Item
                  name="confirmacaoSenha"
                  label="Confirmação de senha"
                  className="redefinir-senha-item"
                >
                  <Input
                    type={typeConfirmSenhaInput}
                    name="confirmacaoSenha"
                    size="large"
                    className="redefinir-senha-input"
                    onChange={(e) => {
                      validateSenhas(e.target.value, 'confirmSenha');
                    }}
                  />
                  <div
                    onClick={() => {
                      setTypeConfirmSenhaInput(
                        // eslint-disable-next-line
                        typeConfirmSenhaInput == 'text' ? 'password' : 'text',
                      );
                    }}
                    className="eye-icon"
                    style={{ cursor: 'pointer' }}
                  >
                    <IconEye />
                  </div>
                </Form.Item>

                <div className="dicas">
                  <span className="titulo-dicas">A senha precisa seguir as seguintes regras:</span>
                  {dicasSenha.map(rule => (
                    <div key={rule.key} className={`dica ${passwordValidation[rule.key] === null ? '' : passwordValidation[rule.key] ? 'success' : 'error'}`}>
                      <div className='icon-check'>
                        <IconCheck />
                      </div>
                      <span>{rule.text}</span>
                    </div>
                  ))}
                </div>

                {error && isExpiracao && <div className="login-error redefinicao">{error}</div>}

                <Button
                  htmlType="submit"
                  type="primary"
                  className="redefinir-senha-btn"
                  block
                  style={{
                    backgroundColor: '#ff7817',
                    color: '#263659',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    height: 'fit-content',
                  }}
                >
                  
                  {!loading ? (
                    'REDEFINIR'
                  ) : (
                    <Spinner color="#26417B" width={4.54} margin={2.27} />
                  )}
                </Button>
                {isExpiracao &&
                  <Button
                    type="primary"
                    className='btn-login'
                    block
                    style={{
                      backgroundColor: 'transparent',
                      border: '1px solid #ff7817',
                      borderRadius: 0,
                      color: '#ff7817',
                      fontWeight: 600,
                      height: '50px',
                      marginTop: '15px',
                    }}
                    onClick={validarRedefinicao}
                  >
                    {!loadingEsqueceuSenha ? (
                      'ESQUECEU SUA SENHA?'
                    ) : (
                      <Spinner color="#ff7817" width={4.54} margin={2.27} />
                    )}
                  </Button>
                }
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedefinirSenha;
