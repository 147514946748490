import React from 'react';
import Button from '../Button';
import { ReactComponent as EyeIcon } from '../../assets/images/icons/eye-icon.svg';

import './styles.scss';

function CardServico({
  servico,
  disabled = false,
  selecionarServico }) {

  return (
    <div
      className="service-card"
    >
      <div className="title-card">
        <div className="service-name">
          {servico.servicoNome}
        </div>
      </div>
      <div className="wrapper-button-ver-pacotes">
        <Button
          className="btn btn-ver-pacotes"
          onClick={selecionarServico}
          disabled={disabled}
        >
          <span>VER PACOTES</span>
          <EyeIcon
            className="eye-icon-crefaz-mais"
          />
        </Button>
      </div>
    </div>
  );
}

export default CardServico;
