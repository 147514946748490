import React, { useContext } from 'react';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import ConversationMessages from '../../../components/ConversationMessages';
import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { ReactComponent as IconChatInterno } from '../../../assets/images/icons/icon-chat-interno.svg';


function InternoComponent() {

  const { 
    proposta,
    anotacoes,
    sendAnotacao
   } = useContext(AnaliseMesaContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <>
      <div className='title-interna'>
        <IconChatInterno style={{ marginRight: 8 }} />
        INTERNO
      </div>
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.interno',
        'Visualizar',
      ) && (
          <>
            <ConversationMessages
              class="interno-mensages"
              title="Anotações"
              mode="screen"
              listMessages={anotacoes}
              sendMessage={sendAnotacao}
              proposta={proposta}
              origem={2}
              externo={false}
              disabled={
                !checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.interno',
                  'Criar',
                )
              }
            />
        </>
      )}
    </>
  );
}

export default InternoComponent;
