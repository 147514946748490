/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import CardFormServico from '../../../components/CardFormServico';
import { OfertasServicosContext } from '../../../contexts/OfertasServicosContext';
import CardPacote from '../../../components/CardPacotesServico/index';
import { FormikProvider, useFormik } from 'formik';
import Modal from '../../../components/Modal';
import { useHistory } from 'react-router-dom';
import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';

function OfertasServicosForm() {

  const {
    produtos
  } = useContext(OfertasProdutosContext)
  const {
    propostaId,
    servico,
    setServico,
    loading,
    setLoading,
    avancarServico,
    servicos
  } = useContext(OfertasServicosContext)

  const history = useHistory();
  const [selectedCard, setSelectedCard] = useState(null);
  const [activeRadio, setActiveRadio] = useState(null);
  const [pacoteSelecionado, setPacoteSelecionado] = useState(null);
  const [pacoteId, setPacoteId] = useState(null);

  const initialValues = {
    pacoteId: null,
    propostaId: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {

      setLoading(true);

      const payload = {
        propostaId: parseInt(propostaId),
        pacoteId: parseInt(pacoteId),
        ofertouProduto: produtos?.length > 0 ? true : false,
        ofertouServico: servicos?.length > 0 ? true : false
      };

      const response = await avancarServico(payload)
      if (response.success) {
        setLoading(false);
        Modal('', 'Proposta atualizada com sucesso!', 'success', () => {
          history.push(`/proposta-servico/${response.data.id}`, {
            id: response.data.id,
          });
        });
      } else {
        setLoading(false);
      }
    },
  });

  const isSubmitEnabled = () => {
    if (selectedCard === null) return false;
    return pacoteSelecionado?.pacoteAdicional && pacoteSelecionado?.pacoteAdicional[0]?.descricaoPacoteAdicional ? activeRadio !== null : true;
  };

  const handleCardClick = (id) => {
    setSelectedCard(id);
    setActiveRadio(null);
  };

  const handleRadioChange = (value) => {
    setActiveRadio(value);
  };

  useEffect(() => {
    //Caso a opção de adicionais esteja marcada, deverá ser olhado o id do pacote adicional
    setPacoteId(activeRadio ? pacoteSelecionado?.pacoteAdicional[0]?.id : pacoteSelecionado?.id);
  }, [activeRadio, pacoteSelecionado]);

  useEffect(() => {
    setPacoteSelecionado(servico?.pacotes.find((pacote) => pacote.id === selectedCard))
  }, [selectedCard]);

  return (
    <FormikProvider value={formik}>
      {servico && (
        <CardFormServico
          action={formik.handleSubmit}
          setServico={setServico}
          loading={loading}
          servico={servico}
          submitButton={isSubmitEnabled()}
        >
          <div className="plano-servico-list" style={{ 'display': 'flex', 'gap': '16px' }}>
            {servico.pacotes.map((pacote) => (
              <CardPacote
                key={pacote.id}
                id={pacote.id}
                pacote={pacote}
                isSelected={selectedCard === pacote.id}
                onClick={handleCardClick}
                onRadioChange={handleRadioChange}
              />
            ))}
          </div>
        </CardFormServico>
      )
      }
    </FormikProvider>
  );
}

export default OfertasServicosForm;
