import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import InputMoney from 'react-number-format';
import Input from '../../../components/SimpleInput';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import SelectSearch from '../../../components/SearchSelect';
import { currencyFormat } from '../../../utils/numberFormatter';

import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';

const { Option } = Select;


function DadosPacoteForm({ formik }) {

  const [hasAdicional, setHasAdicional] = useState(false);

  const { setFieldValue } = formik;

  const {
    validationField,
    propostaServico,
    pacotes,
    pacoteSelecionado,
    setPacoteSelecionado,
    editableField
  } = useContext(PropostaServicoContext);

  useEffect(() => {
    if (formik?.values?.operacao?.pacoteId) {
      const findPacoteSelecionado = pacotes?.find((item) => item.id === formik.values.operacao?.pacoteId);

      if (findPacoteSelecionado) {
        setPacoteSelecionado(findPacoteSelecionado)
      }
    }

  }, [formik?.values?.operacao?.pacoteId, pacotes]);

  useEffect(() => {
    if (pacoteSelecionado?.pacoteAdicional?.length > 0) {
      setHasAdicional(true)
      setFieldValue("operacao.prestacao", pacoteSelecionado?.pacoteAdicional[0]?.valor)
    } else if (pacoteSelecionado?.pacoteAdicional === null) {
      setHasAdicional(false)
      setFieldValue("operacao.prestacao", pacoteSelecionado?.valor)
    }
  }, [hasAdicional, pacoteSelecionado])

  return (

    <Fragment>
      <div className="form-row pt-4 pb-3 container-title-blocos-proposta-crefaz-mais">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados do pacote</div>
      </div>

      <div className="form-row">

        <div className={`container-nome-pacote ${hasAdicional ? 'col-md-4 col-lg-4 col-xl-4' : 'col-md-5 col-lg-5 col-xl-5'}`}
        >
          <Form.Item
            name="operacao.pacoteId"
            label="Pacote"
            validate={(value) =>
              validationField(
                value,
                'operacao.pacoteId',
                'Pacote',
              )}
            hasFeedback
          >
            <Select
              name="operacao.pacoteId"
              className="text-left"
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => {
                setFieldValue('operacao.adicional', false);
                setFieldValue('operacao.pacoteId', value)
              }}
              disabled={!editableField('operacao.PacoteId')}
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
              showSearch
              suffixIcon={<IconArrowSelect />}
            >
              {pacotes?.map((item) => (
                <Option key={item.id} value={item.id}>
                  <span className="text-left">
                    {item.nome}
                  </span>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3 item-dados-pacote">
          <Form.Item
            name="id"
            label="Nº Proposta"
            validate={(value) =>
              validationField(
                value,
                'id',
                'Nº Proposta'
              )}
          >
            <Input
              name="id"
              value={propostaServico?.id}
              disabled={true}
            />
          </Form.Item>
        </div>
        {hasAdicional && (
          <div className="col-md-2 col-lg-2 col-xl-2 item-dados-pacote">
            <Form.Item
              name="operacao.adicional"
              label="Adicional"
              validate={(value) =>
                validationField(
                  value,
                  'operacao.adicional',
                  'Adicional'
                )}
              hasFeedback
            >
              <Field name="operacao.adicional">
                {({ field }) => (
                  <SelectSearch
                    {...field}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                    name="operacao.adicional"
                    suffixIcon={<IconArrowSelect />}
                    onChange={(value) => {
                      setFieldValue(`operacao.adicional`, value)
                      setFieldValue(`operacao.prestacao`, value ? pacoteSelecionado?.pacoteAdicional[0].valor : pacoteSelecionado?.valor)
                    }}
                    optionFilterProp="children"
                    showSearch
                    disabled={!editableField('operacao.adicional')}
                  >
                    <Option value={false} key="nao-0">
                      Não
                    </Option>
                    <Option value={true} key="sim-1">
                      Sim
                    </Option>
                  </SelectSearch>
                )}
              </Field>
              <div className='info-valor-adicional'>* Valor do adicional: {pacoteSelecionado?.pacoteAdicional && pacoteSelecionado?.pacoteAdicional[0]?.descricaoPacoteAdicional?.valor ?
                currencyFormat(pacoteSelecionado?.pacoteAdicional[0]?.descricaoPacoteAdicional?.valor) : currencyFormat(0)}
              </div>
            </Form.Item>
          </div>
        )}


        <div className={`item-dados-pacote${hasAdicional ? 'col-md-3 col-lg-3 col-xl-3' : 'col-md-4 col-lg-4 col-xl-4'}`}>

          <Form.Item
            name="operacao.prestacao"
            label="Valor Mensal do Pacote"
            validate={(value) => validationField(value, 'operacao.prestacao', 'Valor Mensal do Pacote')}
          >
            <Field name="operacao.prestacao">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={true}
                  value={parseFloat(formik?.values?.operacao?.adicional ? pacoteSelecionado?.pacoteAdicional[0].valor : pacoteSelecionado?.valor)}
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </Fragment>

  )
}

export { DadosPacoteForm };
