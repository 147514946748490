import React, { Fragment, useContext, useEffect, } from "react";
import { Form, Radio } from 'formik-antd';
import { PropostaServicoContext } from '../../../contexts/PropostaServicoContext';

function MeiosPagamento({ formik }) {

  const {
    meioPagamentoSelecionado,
    listaMeiosPagamentos,
    setMeioPagamentoSelecionado
  } = useContext(PropostaServicoContext);

  useEffect(() => {
    if(listaMeiosPagamentos.length > 0){

      const meiosPagamentosAtivo = listaMeiosPagamentos.filter(item => item.ativo);

      if (meiosPagamentosAtivo.length === 1) {
        setMeioPagamentoSelecionado(meiosPagamentosAtivo[0].id)
      }
    }
  }, [listaMeiosPagamentos])

  return (
    <>
      <Fragment>
        <div className="form-row pt-4 pb-3">
          <div className="Container ContainerColor mt-1"></div>
          <div className="row pl-4 titleMsg">Meio de Pagamento</div>
        </div>

        <div className="form-row">
          <div className="col-lg-12 d-flex align-items-center">
            <div className="modalidade-contrato">
              <Form.Item
                name="operacao.meioPagamentoId"
              >
                <Radio.Group
                  name="operacao.meioPagamentoId"
                  value={meioPagamentoSelecionado}
                  onChange={(e) => {
                    setMeioPagamentoSelecionado(e.target.value);
                  }}
                  disabled={listaMeiosPagamentos.filter(item => item.ativo)?.length === 1}
                >
                  {listaMeiosPagamentos?.map((item) => {
                    if (item.ativo) {
                      return (
                        <Radio
                          key={item.id}
                          value={item.id}
                        >
                          {item?.nome}
                        </Radio>
                      )
                    }
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  )
}

export { MeiosPagamento };