import React, { createContext, useCallback, useState, useRef } from 'react';
import Modal from '../components/Modal';

import {
  listarTabelaJuros,
  buscarTabelaJuros,
  inserirTabelaJuros,
  atualizarTabelaJuros,
  calcularValorPrestacao,
  buscarContextoTabelaJuros,
  deletarTabelaJuros
} from '../services/tabelaJurosService';

const initialValues = {
  ativo: true,
  id: null,
  nome: '',
  tipo: 0,
  produtoId: '',
  carenciaMedia: 0,
  carenciaMinima: 0,
  carenciaMaxima: 0,
  tabelaCliente: false,
};

const initialTableFormData = {
  id: null,
  tabelaJurosId: 0,
  valor: 0,
  plano: 0,
  edit: false,
  oldValor: 0,
  oldPlano: 0,
  juros: 0,
  jurosFloat: 0,
  prazoInicial: 0,
  prazoFinal: 0,
};

export const TabelaJurosContext = createContext({
  data: [],
  total: 0,
  pageCount: 0,
  active: false,
  loading: false,
  formData: {},
  isEdit: false,
  isEditJuros: false,
  showTable: 0,
  showDate: false,
  showOverlay: false,
  tabelaJurosValores: [],
  tableFormData: {},
  carenciaMedia: 0,
  resetForm: () => {},
  fetchTabelaJuros: async (f) => f,
  editarTabelaJuros: async (f) => f,
  salvarTabelaJuros: async (f) => f,
  removerTabelaJuros: async (f) => f,
  trataNovosValoresTabelaPercentual: async (f) => f,
  tratarNovosValoresFixos: async (f) => f,
  setActive: (f) => f,
  setShowTable: (f) => f,
  setShowDate: (f) => f,
  setTabelaJurosValores: (f) => f,
  setTableFormData: (f) => f,
  salvarTabelaJurosValores: (f) => f,
  salvarJurosPercentuais: (f) => f,
  calcularValorParcela: (f) => f,
  setCarenciaMedia: (f) => f,
  setIsEdit: (f) => f,
});

function TabelaJurosProvider({ children }) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const search = useRef('');
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditJuros, setIsEditJuros] = useState(false);
  const [showTable, setShowTable] = useState(0);
  const [showDate, setShowDate] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [tabelaJurosValores, setTabelaJurosValores] = useState([]);
  const [tableFormData, setTableFormData] = useState(initialTableFormData);
  const [carenciaMedia, setCarenciaMedia] = useState(0);
  const [acesso, setAcesso] = useState(true);
  const [changePage, setChangePage] = useState(false);
  const [produtoId, setProdutoId] = useState(null);
  const [tipoTabelaJuros, setTipoTabelaJuros] = useState(0);
  const [showOverlayTabelaJuros, setShowOverlayTabelaJuros] = useState(false);

  const [tiposTabelaJuros, setTiposTabelasJuros] = useState([]);
  const [produtosTabelaJuros, setProdutosTabelaJuros] = useState([]);

  const [whenChangePage, setWhenChangePage] = useState({
    pageIndex: 0,
    sortBy: [{ id: '', desc: true }],
  });

  async function fetchContextoTabelaJuros() {
    const [response] = await buscarContextoTabelaJuros();
    setTiposTabelasJuros(response.tipoTabelaJuros);
    setProdutosTabelaJuros(response.produto);
  }

  const resetForm = () => {
    setShowDate(false);
    setShowTable(0);
    setCarenciaMedia(0);
    setTabelaJurosValores([]);
    setFormData(initialValues);
    setIsEdit(false);
    setIsEditJuros(false);
    setProdutoId(null);
    setTipoTabelaJuros(0);
  };

  function goToFirstPage() {
    setChangePage((prev) => !prev); // força componente a ir para primeira página;
  }

  async function searchFirstPage() {
    fetchTabelaJuros({ pageIndex: 0, sortBy: [{ id: 'id', desc: true }] });
  }

  const fetchTabelaJuros = useCallback(
    async ({ pageIndex, sortBy }) => {
      setLoading(true);
      const page = pageIndex + 1;
      const response = await listarTabelaJuros({
        pagina: page,
        quantidadePorPagina: 5,
        filter: search.current,
        ativo: active,
        ordenacao: sortBy[0],
      });

      if (response.data.itens) {
        setData(response.data.itens);
      } else {
        setData([]);
      }

      setTotal(response.data.total);
      setPageCount(response.data.totalPages);
      setLoading(false);
    },
    [active],
  );

  const editarTabelaJuros = useCallback(async (id) => {
    setShowOverlay(true);
    setIsEdit(true);
    setIsEditJuros(false);
    try {
      const response = await buscarTabelaJuros(id);
      setFormData(response.data);
      setTabelaJurosValores(response.data.tabelaJurosValores);
      setShowTable(response.data.tipo);
      setShowDate(response.data.promocional);
      setCarenciaMedia(response.data.carenciaMedia);
      setShowOverlay(false);
      setProdutoId(response.data.produtoId);
    } catch (error) {
      setShowOverlay(false);
      setIsEdit(false);
    }
  }, []);

  const removerTabelaJuros = useCallback(async (id) => {
    setShowOverlay(true);
    const response = await deletarTabelaJuros(id);

    if (response.success) {
      setShowOverlay(false);
      resetForm();
      fetchTabelaJuros({
        pageIndex: 0,
        sortBy: [{ id: 'id', desc: true }],
      });
    }
    else{
      setShowOverlay(false);
    }

    return response;
  }, []);

  const salvarTabelaJuros = useCallback(
    async (data) => {
      setShowOverlay(true);
      data.tabelaJurosValores = JSON.parse(JSON.stringify(tabelaJurosValores));
      if (data.id > 0) {
        const response = await atualizarTabelaJuros(data).catch(() =>
          setShowOverlay(false),
        );
        if (response.success) {
          resetForm();
          fetchTabelaJuros({
            pageIndex: 0,
            sortBy: [{ id: 'id', desc: true }],
          });
        }

        setShowOverlay(false);
        return response;
      } else {
        const response = await inserirTabelaJuros(data).catch(() =>
          setShowOverlay(false),
        );
        if (response.success) {
          resetForm();
          fetchTabelaJuros({
            pageIndex: 0,
            sortBy: [{ id: 'id', desc: true }],
          });
        }

        setShowOverlay(false);
        return response;
      }
    },
    [tabelaJurosValores, fetchTabelaJuros],
  );


  const trataNovosValoresTabelaPercentual = (data, tabelaJurosValores) => {
    data.plano = parseInt(data.plano);
    data.oldPlano = parseInt(data.oldPlano);

    let start = Number(data.prazoInicial || data.plano);
    let end = Number(data.prazoFinal || data.plano);

    data.juros = data.jurosFloat;

    let newArray = []

    if (tabelaJurosValores?.length === 0) {
      for (let i = start; i <= end; i++) {
        newArray.push({
          id: null,
          tabelaJurosId: 0,
          valor: 0,
          juros: data.juros,
          plano: i,
          prestacao: 0,
        });
      }
    } else {
      const planosExistentes = tabelaJurosValores.map((juros) => juros.plano);

      newArray = [...tabelaJurosValores];

      if (start === end && data?.edit) {
        if (
          planosExistentes.indexOf(start) >= 0 &&
          data.plano !== data.oldPlano
        ) {
          return Modal(
            'Erro',
            'Já existe um plano cadastrado para esse valor',
            'error',
          );
        } else {
          const indexExistePlano = planosExistentes.indexOf(data?.oldPlano);
          newArray[indexExistePlano].juros = data.juros;
          newArray[indexExistePlano].plano = start;
        }
      } else {
        for (let i = start; i <= end; i++) {
          const indexExistePlano = planosExistentes.indexOf(i);

          if (indexExistePlano >= 0) {
            newArray[indexExistePlano].juros = data.juros;
            newArray[indexExistePlano].plano = i;
          } else {
            newArray.push({
              id: null,
              tabelaJurosId: 0,
              valor: 0,
              juros: data.juros,
              plano: i,
              prestacao: 0,
            });
          }
        }
      }
    }

    newArray.sort((a, b) => {
      return a.plano - b.plano || a.valor - b.valor;
    });

    return newArray;
  }
  const salvarJurosPercentuais = (data) => {
    let newArray = [];

    newArray = trataNovosValoresTabelaPercentual(data, tabelaJurosValores)
    setTabelaJurosValores(newArray);

    setIsEditJuros(false);
    setTableFormData(initialTableFormData);
  };

  const tratarNovosValoresFixos = (data, tabelaJurosValores) => {

    data.plano = parseInt(data.plano);
    data.oldPlano = parseInt(data.oldPlano);

    data.juros = data.jurosFloat;

    let newArrayTabelaJuros = [];

    if (tabelaJurosValores?.length === 0) {
      newArrayTabelaJuros = [{ ...data }];
    } else {
      let indexExistPlanoValor = null;
      newArrayTabelaJuros = [...tabelaJurosValores];

      const indexExistPlano = tabelaJurosValores.findIndex(
        (item) => item.plano === data.plano,
      );
      const indexExistValor = tabelaJurosValores.findIndex(
        (item) => item.valor === data.valor,
      );

      if (indexExistPlano >= 0 && indexExistValor >= 0) {
        indexExistPlanoValor = tabelaJurosValores.findIndex(
          (item) => item.plano === data.plano && item.valor === data.valor,
        );
      }

      const editPlano = data.plano !== data.oldPlano;
      const editValor = data.valor !== data.oldValor;

      if (
        (editPlano || editValor) &&
        indexExistPlanoValor !== null &&
        indexExistPlanoValor >= 0
      ) {
        return Modal(
          'Erro',
          'Já existe um plano cadastrado para esse valor',
          'error',
        );
      } else {
        if (data?.edit) {
          let indexEditPlanoValor = tabelaJurosValores.findIndex(
            (item) =>
              item.plano === data.oldPlano && item.valor === data.oldValor,
          );

          newArrayTabelaJuros[indexEditPlanoValor] = {
            ...newArrayTabelaJuros[indexEditPlanoValor],
            prestacao: data.prestacao,
            plano: data.plano,
            valor: data.valor,
            juros: data.juros,
          };
        } else {
          newArrayTabelaJuros.push(data);
        }
      }
    }
    newArrayTabelaJuros.sort((a, b) => {
      return a.plano - b.plano || a.valor - b.valor;
    });
    return newArrayTabelaJuros;
  }

  const salvarTabelaJurosValores = (data) => {
    let newArrayTabelaJuros = [];

    newArrayTabelaJuros = tratarNovosValoresFixos(data, tabelaJurosValores)

    setTabelaJurosValores(newArrayTabelaJuros);
    setIsEditJuros(false);
    setTableFormData(initialTableFormData);
  };

  const calcularValorParcela = async (data) => {
    const response = await calcularValorPrestacao(data);
    if (response.success) {
      return response.data.prestacao;
    } else {
      return '';
    }
  };

  const resetTableForm = async () => {
    setTableFormData(initialTableFormData);
  };

  return (
    <TabelaJurosContext.Provider
      value={{
        resetTableForm,
        data,
        total,
        pageCount,
        search,
        active,
        loading,
        formData,
        isEdit,
        isEditJuros,
        showTable,
        showDate,
        showOverlay,
        tabelaJurosValores,
        tableFormData,
        carenciaMedia,
        acesso,
        tiposTabelaJuros,
        produtosTabelaJuros,
        whenChangePage,
        searchFirstPage,
        setWhenChangePage,
        resetForm,
        fetchTabelaJuros,
        fetchContextoTabelaJuros,
        editarTabelaJuros,
        removerTabelaJuros,
        salvarTabelaJuros,
        setActive,
        setShowTable,
        setShowDate,
        setTabelaJurosValores,
        setTableFormData,
        salvarTabelaJurosValores,
        salvarJurosPercentuais,
        calcularValorParcela,
        setCarenciaMedia,
        setIsEditJuros,
        setAcesso,
        goToFirstPage,
        changePage,
        produtoId,
        setProdutoId,
        tipoTabelaJuros,
        setTipoTabelaJuros,
        setShowOverlay,
        showOverlayTabelaJuros,
        setShowOverlayTabelaJuros,
        trataNovosValoresTabelaPercentual,
        tratarNovosValoresFixos
      }}
    >
      {children}
    </TabelaJurosContext.Provider>
  );
}

export default TabelaJurosProvider;
